import React from 'react'
import { Modal } from 'rsuite'

const ModalRsuite = (props) => {
    var bodyClass = 'modal-body'
    if(props.bodyClassPrefix) {
        bodyClass += ` ${props.bodyClassPrefix}`
    }
    if(props.footer) {
        bodyClass += ' has-footer'
    }
    return (
        <>
            <Modal open={props.open} onClose={props.onClose} key={props.modalKey || ''} size={props.size} backdrop='static' className={props.className}>
                <Modal.Header closeButton={props.closeButton} classPrefix={props.titleClassPrefix ? `modal-header ${props.titleClassPrefix}` : 'modal-header'}>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body classPrefix={bodyClass}>
                    {props.body}
                </Modal.Body>
                {
                    props.footer ?
                    <Modal.Footer>
                        {props.footer}
                    </Modal.Footer> :
                    <></>
                }
            </Modal>
        </>
    )
}

ModalRsuite.props = {
    key : '',
    modalKey : '',
    titleClassPrefix : ''
}

export default ModalRsuite