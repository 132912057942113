import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Config } from '../../config/IISMethods'
import FormController from '../../Controller/FormController'
import { setProps } from '../../redux/actions'

const SelfVerification = () => {
    const dispatch = useDispatch()

    const rightSidebarFormData= [
        {
            pagename: 'selfverification',
            formname: 'selfverification',
            useraction: 'mobilecheckin',
            apipath: 'mobilecheckin/update',
            formfields: [
                {
                    'field': 'selfphoto',
                    'text': 'Document File',
                    'type': Config.getHtmlcontorls()['image'],
                    'disabled': false,
                    'defaultvisibility': true,
                    'required': true,
                    'gridsize': Config.getGridclasses()['col-12']
                },

            ]
        },
    ]
    useEffect(() => {
        dispatch(setProps({
            rightSidebarFormData:rightSidebarFormData
        }))
    }, [])

    return <FormController pagename="selfverification" rightSidebarFormData={rightSidebarFormData}/>
}

export default SelfVerification



