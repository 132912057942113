import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import image404 from '../assets/images/404.svg'

const ErrorPage = (props) => {
    const history = useNavigate()
    useEffect(() => {
        let urlparams = new URLSearchParams(window.location.search)
        if (urlparams?.get('bookingid')?.split('-')[0] === "undefined" || urlparams?.get('bookingid')?.split('-')[1] === "undefined") {
            history('*')
        }
    }, [])
    return (
        <>
            <section className="redirect-section mt-5">
                <div className="container-md">
                    <div className="row">
                        <div className="col-12 col-md-9 col-lg-6 mx-auto p-0">
                            <div className="error-content">
                                <h1 className="error-number d-none">404</h1>
                                <p className="mini-text">Ooops!</p>
                                <img className="error-img" src={image404} />
                                <p className="error-text mb-4 mt-1">The page you requested was not found!</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ErrorPage

{/* <div className="col-12">
                                    <div className="submit-btn-sec">
                                        <button type="submit" className="btn btn-primary-dark" onClick={()=>console.log("ok")}>Go Back</button>

                                    </div>
                                </div> */}
{/* <Link to="/" className="btn btn-primary-dark">Go Back</Link> */ }