import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Config, IISMethods } from '../../config/IISMethods'
import FormController from '../../Controller/FormController'
import { setProps } from '../../redux/actions'

const YourStayInfo = () => {
    const reservationDetails = IISMethods.getLocalData("reservationDetails")
    const dispatch = useDispatch()
    const rightSidebarFormData = [
        {
            pagename: 'yourstayinfo',
            formname: 'yourstayinfo',
            useraction: 'mobilecheckin',
            apipath: 'mobilecheckin/update',
            formfields: [
                //0
                {
                    'field': 'mobilecheckinnoofadults',
                    'name': 'adult',
                    'placeholder': "0 Adult(s)",
                    'text': 'Adults',
                    'type': Config.getHtmlcontorls()['number-input'],
                    'disabled': true,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                    'dependentfields': ['options'],
                },
                //1
                {
                    'field': 'mobilecheckinnoofchildren',
                    'name': 'child',
                    'placeholder': "0 Child(ren)",
                    'text': 'Children',
                    'type': Config.getHtmlcontorls()['number-input'],
                    'disabled': true,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                    'dependentfields': ['options'],
                },
                //2
                {
                    'field': 'mobilecheckinnoofpets',
                    'name': 'pet',
                    'placeholder': "0 Pet(s)",
                    'text': 'Pets',
                    'type': Config.getHtmlcontorls()['number-input'],
                    'disabled': false,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12'],
                    'dependentfields': ['options'],
                    // 'onchangefill': ['earlycheckintimeid'] // Heli 12/07/2023 12:01
                },
                //3
                {
                    'field': 'isearlycheckin',
                    'text': 'DO YOU PLAN FOR AN EARLY CHECK-IN?',
                    'type': Config.getHtmlcontorls()['dropdown'],
                    'disabled': false,
                    'required': false,
                    'defaultvisibility': false,
                    'gridsize': Config.getGridclasses()['col-6'],
                    'masterdataarray': ['Yes', 'No'],
                    'resetfield': ['earlycheckintimeid', 'estimatedcheckintime'],
                    'cleanable': true,
                    'searchable': false,
                    'condition': [{
                        'field': 'ischeckin',
                        'onvalue': 0
                    }]
                },
                //4
                {
                    'field': 'earlycheckintimeid',
                    'text': 'EARLY CHECK-IN TIME WITH FEE',
                    'type': Config.getHtmlcontorls()['dropdown'],
                    'disabled': false,
                    'required': false,
                    'defaultvisibility': false,
                    'gridsize': Config.getGridclasses()['col-12'],
                    'apipath': 'property/timing/checkin',
                    'masterdata': 'checkintimefrom',
                    'masterdatafield': 'checkintimefrom',
                    'formdatafield': 'earlycheckintime',
                    'cleanable': true,
                    'searchable': false,
                    'condition': [{
                        'field': 'isearlycheckin',
                        'onvalue': 1
                    }, {
                        'field': 'ischeckin',
                        'onvalue': 0
                    }],
                },
                {
                    'field': 'vehiclemakemodel',
                    'text': 'MAKE',
                    'type': Config.getHtmlcontorls()['input-text'],
                    'disabled': false,
                    'defaultvisibility': true,
                    'required': false,
                    // 'apipath': 'masters/vehiclebrand',
                    // 'masterdata': 'vehiclebrand',
                    // 'masterdatafield': 'vehiclebrand',
                    // 'formdatafield': 'vehiclebrand',
                    'gridsize': Config.getGridclasses()['col-12'],
                },
                // 6
                {
                    'field': 'options',
                    'type': 'options',
                    'text': 'Vehicle Information For Parking (Optional)',
                    'disabled': false,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12']
                },
                //7
                {
                    'field': 'vehicleimage',
                    'text': 'Vehicle Image',
                    'type': Config.getHtmlcontorls()['image'],
                    'disabled': false,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12']
                },
                //8
                {
                    'field': 'estimatedcheckintime',
                    'text': 'Estimated Check-In time',
                    'type': Config.getHtmlcontorls()['timepicker'],
                    'disabled': false,
                    'defaultvisibility': false,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12'],
                    'condition': [{
                        'field': 'isearlycheckin',
                        'onvalue': 0
                    }, {
                        'field': 'ischeckin',
                        'onvalue': 0
                    },],

                },
                //9
                {
                    'field': 'noofserviceanimal',
                    'name': 'noofserviceanimal',
                    'placeholder': "0 Service Animal(s)",
                    'text': 'Service Animals',
                    'type': Config.getHtmlcontorls()['number-input'],
                    'disabled': true,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12'],
                    'dependentfields': ['options'],
                },
                //10
                {
                    'field': 'serviceanimalquestion1',
                    'text': 'Is the dog or a service animal required because of a disability ?',
                    'type': Config.getHtmlcontorls()['radio'],
                    'disabled': false,
                    'defaultvisibility': false,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-lg-12'],
                    'radiooptions': [
                        {
                            'label': 'Yes',
                            'value': 1,
                        },
                        {
                            'label': 'No',
                            'value': 0,
                        }
                    ],
                    'condition':
                        [
                            {
                                'field': 'noofserviceanimal',
                                'onvalue': 0
                            }
                        ]
                },
                //11
                {
                    'field': 'serviceanimalquestion2',
                    'text': 'What work or task has the dog been trained to perform ?',
                    'type': Config.getHtmlcontorls()['input-text'],
                    'disabled': false,
                    'defaultvisibility': false,
                    'required': true,
                    'gridsize': Config.getGridclasses()['col-lg-12'],
                    'condition': [
                        {
                            'field': 'noofserviceanimal',
                            'onvalue': 0
                        }]

                },
            ]
        }
    ]

    useEffect(() => {
        dispatch(setProps({
            reservationDetails: reservationDetails,
            rightSidebarFormData
        }))
    }, [])

    return <FormController pagename="yourstayinfo" rightSidebarFormData={rightSidebarFormData} reservationDetail={reservationDetails} />

}
export default YourStayInfo




