import React from 'react';
import ReactDOM from 'react-dom'
import {createRoot} from 'react-dom/client'
// import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './redux/reducer'
import { Config } from './config/IISMethods';
// import Head from './Head';
import './Css.js'
import './assets/css/Style.css'
// import { clarity } from 'react-microsoft-clarity';

// clarity.init('k59rff7fzp')

export const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

if (Config.getServermode() === 'prod') {
  // console.log = console.warn = console.error = () => {};
  console.log = () => { };
}



const root = createRoot(document.getElementById("root"));

const msclarity = () => {
  (function (c, l, a, r, i, t, y) {
      c[a] =
          c[a] ||
          function () {
              (c[a].q = c[a].q || []).push(arguments);
          };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", 'k59rff7fzp');
} 

root.render(
  <Provider store={store}>
    {/* <Head /> */}
    <App />
    <script type='text/javascript'>{msclarity()}</script>
  </Provider>
)

// ReactDOM.render(
// <Provider store={store}>
//  <App />
// </Provider>,
//   document.getElementById('root')
// )