import React from 'react'
import Nextarrow from '../assets/images/Nextarrow.svg'
import { Link } from 'react-router-dom'
import { IISMethods } from '../config/IISMethods'

const Header = ({ backTo, title, backtoPreviousPage, current, setPage = "", clickevent = "", bgclass ='' }) => {

    const queryString = IISMethods.getLocalData('queryString') ? IISMethods.getLocalData('queryString') : ""

    return (
        <>
            <section className="redirect-section p-0">

                {
                    (current !== "successfullycompleted") ?
                        <div className="back-btn-sec"
                            onClick={() => {

                                if (current === "yourstayinfoDemo") {
                                    setPage("home")
                                }
                                // else if (current === "paymentmethodcamera") {
                                //     clickevent()
                                // }
                                else {
                                    backtoPreviousPage(backTo, current)
                                }
                            }}
                        >
                            {/* for not back to previous page only for go to first part of current page */}
                            <>
                                {
                                    (current === "yourstayinfoDemo")
                                        ?
                                        <Link to={`/${"yourstayinfo"}${queryString}`} className="back-link" >
                                            <div className="right-arrow-icone d-inline-block mr-3">
                                                <img src={Nextarrow} />
                                            </div>
                                            <span className="d-inline-block">{title}</span>
                                        </Link>
                                        :
                                        //     (current === "paymentmethodcamera")
                                        //         ?
                                        //         <Link to={`/${"paymentmethod"}${queryString}`} className="back-link" >
                                        //             <div className="right-arrow-icone d-inline-block mr-3">
                                        //                 <img src={Nextarrow} />
                                        //             </div>
                                        //             <span className="d-inline-block">{title}</span>
                                        //         </Link>
                                        //         :
                                        <Link to={`/${backTo}${queryString}`} className="back-link" >
                                            <div className="right-arrow-icone d-inline-block mr-3">
                                                <img src={Nextarrow} />
                                            </div>
                                            <span className="d-inline-block">{title}</span>
                                        </Link>
                                }
                            </>
                        </div>
                        :
                        <div className={`back-btn-sec ${bgclass? 'btn-success' : ''}`} >
                            <span className="d-inline-block">{title}</span>
                        </div>
                }
                <div className="text-light">
                </div>
            </section>
        </>
    )
}

export default Header
