import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import FormController from '../../Controller/FormController'
import { setProps } from '../../redux/actions'

const SuccessfullyCompleted = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setProps({
            formData:{},
            rightSidebarFormData: [
                {
                    pagename: 'successfullycompleted',
                    formname: 'successfullycompleted',
                    useraction: 'mobilecheckin',
                    apipath: 'mobilecheckin/update',
                    alias: 'successfullycompleted',
                    formfields: []
                },
            ]
        }))

    }, [])
    return <FormController pagename="successfullycompleted" />
}

export default SuccessfullyCompleted