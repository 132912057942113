import React from 'react'
import { SelectPicker } from 'rsuite'
import { Config } from "../../config/IISMethods"

const SelectPickerRsuite = (props) => {

    var masterdatalength = props?.data?.length
        ? props.data.length
        : 0

    const mountRef = React.useRef(null);

    return (
        <>
            <div className='main-selectpicker' ref={mountRef}>
                <SelectPicker
                    cleanable={props.cleanable}
                    className={props.className}
                    searchable={props.searchable || masterdatalength > 8}
                    data={props.data || []}
                    id={props.id}
                    container={() => mountRef.current} //Anjali 24/7/2023
                    name={props.name}
                    value={props.value || ""}
                    onSelect={props.onSelect}
                    placement="bottom"
                    placeholder={props.placeholder}
                    onClean={props.onClean}
                    onChange={props.onChange}
                    defaultValue={props.defaultValue}
                    disabled={props.disabled}
                    renderExtraFooter={() =>
                        props.masterFormName ? (
                            <a className="rs-dropdown-footer" onClick={props.onAdd}>
                                <i className="material-icons-round">add</i>
                                {`${Config.savesidebar} ${props.masterFormName}`}
                            </a>
                        ) : (
                            <></>
                        )
                    }
                />
            </div>
        </>
    )
}

SelectPickerRsuite.props = {
    placement: "",
    placeholder: "Select",
    defaultValue: "",
    data: [],
}

export default SelectPickerRsuite