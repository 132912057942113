import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IISMethods } from '../../config/IISMethods'
import { store } from '../..'
import Loader from '../Components/Loader'


const YourStayInfoViewPhoto = (props) => {
    const getCurrentState = () => {
        return store.getState()
    }

    const storeData = useSelector(state => state)
    const [vehicleImageArray, setVehicleImageArray] = useState(IISMethods.getLocalData('reservationDetails')?.vehicleimages ? IISMethods.getLocalData('reservationDetails')?.vehicleimages : [])
    useEffect(() => {
        setVehicleImageArray(IISMethods.getLocalData('reservationDetails').vehicleimages)
    }, [storeData])

    const capturePhoto = () => {

        return <>
            {
                IISMethods.getLocalData('sampleImages').length > 0 && !vehicleImageArray.length &&//for displaying capture image button if no imge exist in vehicle photos
                <div className="col-12 mb-2 mx-auto">
                    <div className="row  ">
                        <div className="col-12 mx-auto p-0">
                            <div className=" setbtn-vehiclephoto">
                                <label className="col-12 mx-auto p-0" htmlFor='icon-button-file'>
                                    <input
                                        accept="image/*"
                                        className='input d-none '
                                        id="icon-button-file"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => {
                                            props.handleFormData("image", "vehicleimages", e.target.files[0])
                                        }}
                                    />
                                    <div className="font-weight-600 text-center">
                                        Capture Image
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="col-12 mx-auto">
                <div className="row">
                    <div className="col-12 mx-auto p-0">
                        <div className="submit-btn-sec">
                            <div to="/ratecard" className="btn submit-btn btn-icon paymentcard-submit-btn" onClick={() =>{
                                props.setPage('home')
                                props.handleSubmit()
                                }}>
                                <div className="mx-auto">
                                    <div className="d-inline-block mr-3">
                                        {<Loader size="sm" content={'Review Hotel Charges'} />}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    const handleRemoveImage = async (index) => {

        const temparr = vehicleImageArray //vehicleImageArray.filter((temp) => temp._id !== id)
        temparr.splice(index, 1)
        props.handleFormData('image', 'vehicleimages', vehicleImageArray, index, true)// remove particular image and pass to the api
    }

    return (
        <section className="stayinformation-sec section-spacing">
            {/* <div className="container"> */}
            {(IISMethods.getLocalData('sampleImages').length > 0 && !vehicleImageArray.length) &&
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="stay-information-info info-data">
                                        <h1 className="main-title text-blue">SAMPLE IMAGES</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        IISMethods.getLocalData('sampleImages').length > 0 &&
                        <>
                            <div className="col-12 my-3">
                                <div className="row ">
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 d-flex align-content-between flex-wrap">
                                                {IISMethods.getLocalData('sampleImages')?.map((vehiclePhoto, index) => {
                                                    return (
                                                        <>
                                                            <div className='sample-img' key={vehiclePhoto._id + index}>
                                                                <img src={vehiclePhoto.image} height="120px" width={"120px"} alt="Sample Image" />
                                                            </div>
                                                            {
                                                                (index !== IISMethods.getLocalData("sampleImages").length - 1) &&
                                                                <div className="col-12">
                                                                    <div className="form-or">
                                                                        <p>OR</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {/* {props.hrLine} */}
                </>}
            {
                vehicleImageArray.length > 0 &&
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="stay-information-info info-data">
                                        <h1 className="main-title text-blue"> IMAGE PREVIEW </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-2">
                        <div className="row ">
                            <div className="col-12 mx-auto">
                                <div className="row">
                                    <div className="col-12 d-flex align-content-between flex-wrap">
                                        {
                                            vehicleImageArray.map((vehiclePhoto, vehicleindex) => {
                                                console.log('vehiclePhoto', vehiclePhoto._id)
                                                return <div className='preview-img'>
                                                    <span className='close-vehicle-image' onClick={() => handleRemoveImage(vehicleindex)}>{/**vehiclePhoto._id */}
                                                        <svg id="Icons" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="m7.293 16.707a1 1 0 0 0 1.414 0l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414l-3.293-3.293 3.293-3.293a1 1 0 1 0 -1.414-1.414l-3.293 3.293-3.293-3.293a1 1 0 1 0 -1.414 1.414l3.293 3.293-3.293 3.293a1 1 0 0 0 0 1.414z" />
                                                        </svg>
                                                    </span>
                                                    <img src={vehiclePhoto.image} height="120px" width={"120px"} alt="Vehicle Image" />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {props.hrLine} */}
                </>
            }
            {capturePhoto()}
            {/* </div> */}
        </section >
    )
}

export default YourStayInfoViewPhoto

{/* <span className=' p-2' style={{ position: 'absolute', right: 0,top:0,borderRadius:'50% 50%',fontWeight:'bolder',background:"red",color:"white",cursor:'pointer' }} onClick={() => handleRemoveImage(vehiclePhoto._id)}>
<i className="material-icons-round ml-auto">close</i>
</span> */}
