import { IISMethods } from 'config/IISMethods'
import React, { useRef, useState } from 'react'
import { DatePicker } from 'rsuite'

const TimePickerRsuite = (props) => {
    const timeRef = useRef(null)
    
    const [defaultValue , setDefaultValue] = useState('')

    return (
        <>
            <DatePicker
                onOpen={()=>{
                    let defaultdate = props.disabledfromfield ? props.disabledfromfield : null
                    if (defaultdate) setDefaultValue(IISMethods.GetISOtoTZtime(props.disabledfromfield))
                    else setDefaultValue(IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString()))
                }}
                onClose={()=>{
                    setDefaultValue('')
                }}
                ref={timeRef}
                className={props.className}
                placeholder={props.placeholder}
                format={props.format}
                cleanable={props.cleanable}
                name={props.name}
                value={props.value || defaultValue}
                showMeridian id={props.id}
                // onChange={props.onChange}
                onClean={() => props.onChange('')}
                ranges={props.ranges}
                disabled={props.disabled}
                calendarDefaultDate={IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())}
                {...props.options}
                onOk={(time) => props.onChange(time ? time : '')}
                
                // defaultValue={props.defaultValue}
                // type={props.type}
                // calendarDefaultDate={new Date(systemdate)}
                // calendarDefaultDate={IISMethods.GetTimefromISOString(IISMethods.GetTZTime().toISOString())}
                // defaultValue={new Date(IISMethods.getDateFormat(propertydata.systemdate, 1, true) + ' ' + IISMethods.getTimeFormat(new Date(), 1, false, true))}
                // locale={{
                //     dateLocale: new Date(IISMethods.getDateFormat(propertydata.systemdate, 1, true) + ' ' + IISMethods.getTimeFormat(new Date(), 1, false, true))
                // }}
            />
        </>
    )

    // const mountRef = React.useRef(null);

    // return (
    //     <>
    //     <div className='main-selectpicker' ref={mountRef}>
    //         <DatePicker
    //             container={() => mountRef.current} //Anjali 17/08/2023
    //             className={props.className}
    //             placeholder={props.placeholder}
    //             format={props.format}
    //             // defaultValue={defaultValue}
    //             name={props.name}
    //             value={props.value}
    //             showMeridian id={props.id}
    //             onChange={props.onChange}
    //             type={props.type}
    //             ranges={props.ranges}
    //             placement={props.placement || 'bottom'}
    //         />
    //     </div>
    //     </>
    // )
}

export default TimePickerRsuite