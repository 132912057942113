import React, { useEffect } from 'react'
import { useState } from "react"
import { SelectPicker } from 'rsuite'
import adultimg from '../../assets/images/Adult.svg'
import childrenimg from '../../assets/images/Children.svg'
import petimg from '../../assets/images/pet.svg'
import { useSelector } from 'react-redux'
import Header from '../Header'
import { store } from '../../index'
import YourStayInfoViewPhoto from './YourStayInfoViewPhoto'
import { IISMethods, JsCall } from '../../config/IISMethods'
import SelectPickerRsuite from '../Components/SelectPickerRsuite'
import Loader from '../Components/Loader'
import TimePickerRsuite from '../Components/TimePickerRsuite'
import { connect } from 'react-redux'
import { setProps } from '../../redux/actions'

const hrLine = <div className="col-12 hr-line">
    <hr />
</div>


const selectearlychackin = [
    {
        "label": "Yes",
        "value": 1,
    },
    {
        "label": "No",
        "value": 0,
    }
]

const YourStayInfoView = (props) => {

    const mountRef = React.useRef(null);
    const serviceanimalquestion1Ref = React.useRef(null)
    const getCurrentState = () => store.getState()

    const stayDetailData = useSelector(state => state)
    const rightSidebarFormData = stayDetailData?.rightsidebarformdata ? stayDetailData?.rightsidebarformdata : props.rightsidebarformdata
    const [state, setState] = useState("")

    const [adult, setAdult] = useState()
    const [child, setChild] = useState()
    const [pet, setpet] = useState()
    const [noofserviceanimal, setnoofserviceanimal] = useState()
    const [isearlycheckin, setIsearlycheckin] = useState()
    const [checkinTime, setCheckinTime] = useState()
    const [isManualData, setIsManualData] = useState(false)
    const [page, setPage] = useState("home")

    useEffect(() => {

        setAdult(props.reservationDetail.mobilecheckinnoofadults === 0 ? 1 : props.reservationDetail.mobilecheckinnoofadults)
        setChild(props.reservationDetail.mobilecheckinnoofchildren)
        setpet(props.reservationDetail.mobilecheckinnoofpets)
        setnoofserviceanimal(props.reservationDetail.noofserviceanimal)
        setIsearlycheckin(Number(props.reservationDetail.isearlycheckin))

        if (getCurrentState().formData.vehicleinformations) {
            if (getCurrentState().formData.vehicleinformations?.length < 1 || getCurrentState().formData.vehicleinformations[getCurrentState().formData?.vehicleinformations?.length - 1]?.vehiclenumberplate === "" && getCurrentState().formData.vehicleinformations?.length - 1 < 0) {
                setIsManualData(false)
            } else {
                setIsManualData(true)
            }
        }

    }, [getCurrentState()?.formData?.vehicleinformations])

    useEffect(() => {
        let temppropertydata = IISMethods.getLocalData("welcomedata")
        setCheckinTime(temppropertydata?.property?.timing?.checkintime)// reservationDetails comment Heli 09/10/2023 10:41 property timing come from welcome data obj.
    }, [getCurrentState()?.formData?.vehicleinformations])

    const handleMemberCountPlus = (member) => {
        switch (member) {
            case "adult":
                setAdult(adult + 1)
                return props.handleFormData('number-input', 'mobilecheckinnoofadults', adult + 1)

            case "child":
                setChild(child + 1)
                return props.handleFormData('number-input', 'mobilecheckinnoofchildren', child + 1)

            case "pet":
                setpet(pet + 1)
                return props.handleFormData('number-input', 'mobilecheckinnoofpets', pet + 1)

            case "noofserviceanimal":
                setnoofserviceanimal(noofserviceanimal + 1)
                return props.handleFormData('number-input', 'noofserviceanimal', noofserviceanimal + 1)

            default:
                break;
        }
    }

    const handleMemberCountMinus = (member) => {
        switch (member) {
            case "adult":
                if (adult > 1) {
                    setAdult(adult - 1);
                    return props.handleFormData('number-input', 'mobilecheckinnoofadults', adult - 1)
                }
                break;

            case "child":
                if (child >= 1) {
                    setChild(child - 1);
                    return props.handleFormData('number-input', 'mobilecheckinnoofchildren', child - 1)
                }
                break;

            case "pet":
                if (pet >= 1) {
                    setpet(pet - 1);
                    return props.handleFormData('number-input', 'mobilecheckinnoofpets', pet - 1)
                }
                break;
            case "noofserviceanimal":
                if (noofserviceanimal >= 1) {
                    setnoofserviceanimal(noofserviceanimal - 1);
                    return props.handleFormData('number-input', 'noofserviceanimal', noofserviceanimal - 1)
                }
                break;
            default:
                break;
        }
    }

    const handleChangeVehicle = (value, field) => {
        props.setVehicleplaceholder({ ...props.vehicleplaceholder, [field]: value })
    }

    const handleSubmit = () => {

        let error = Object.assign({}, props.errors)

        if (getCurrentState().formData?.noofserviceanimal > 0 && (!getCurrentState().formData?.serviceanimalquestion1)) {
            error['serviceanimalquestion1'] = 'required'
            props.setErrors(error)
            serviceanimalquestion1Ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else if (getCurrentState().loading === 0) {
            props.handleYourStayInfo(1,)
        }
    }
    const checkValidation = (fieldobj, value, text) => {
        let key = fieldobj.field

        let error = Object.assign({}, props.errors)
        if (!value && fieldobj.required) {
            error[key] = ` ${text} is required `
        }
        else {
            if (JsCall.validateForm({ [key]: value }, getCurrentState().rightSidebarFormData, key)) {
                error[key] = `${text} is not in proper format`
            } else {
                delete error[key]

            }

            if (text) {
                setTimeout(() => {
                    let a = { ...state, [key]: value };
                    setState(a);
                }, 1);
            } else {
                delete error[key]
            }
        }
        props.setErrors(error)
        // disptach(setProps({ reservationDetails: { ...reservationDetails, [key]: value } }))
    }

    const renderHtmlControls = (formfield) => {
        try {
            let conditions = false
            if (formfield?.condition) {
                let condition = formfield.condition?.map((item) => {
                    if (item.field === 'noofserviceanimal') {
                        if (getCurrentState().formData[item.field] > 0) {
                            return true
                        }
                    }
                    else if (getCurrentState().formData[item.field] === item.onvalue) {
                        return true
                    }
                    return false
                })
                let status = condition.every(c => c === true)
                conditions = status
            }

            if (formfield && (formfield.defaultvisibility || conditions)) {

                if (formfield.type === 'input-text' || formfield.type === 'input-textarea') {
                    if (formfield.field === 'serviceanimalquestion2') {
                        return (
                            <div className={formfield.gridsize}>
                                <div className="form-group">
                                    <label className={`label-form ${props.errors[formfield.field]?'text-danger':''}`}>{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                    <input
                                        onChange={(e) => {
                                            checkValidation(formfield, e.target.value, formfield.text);
                                            props.handleFormData(formfield.type, formfield.field, e.target.value)
                                        }}
                                        id={formfield.field}
                                        type="text"
                                        value={getCurrentState().formData?.serviceanimalquestion2 }
                                        name={formfield.field}
                                        className={`form-control vehicle ${props.errors[formfield.field]?'border-danger':''}`}
                                        placeholder="Enter Answer"
                                        required={formfield.required}
                                    />
                                    {/* {
                                        props.errors[formfield.field] &&
                                        <span className='text-danger error'>{props.errors[formfield.field]}</span>
                                    } */}
                                </div>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="form-group">
                                <label className="label-form">{formfield.text}</label>
                                <input
                                    onChange={(e) => { handleChangeVehicle(e.target.value, formfield.field) }}
                                    id={formfield.field}
                                    type="text"
                                    value={props.vehicleplaceholder && props.vehicleplaceholder[formfield?.field]}
                                    name={formfield.field}
                                    className="form-control vehicle"
                                    placeholder="Enter car license plate for parking"
                                    required={formfield.required}
                                />
                            </div>
                        )
                    }
                }
                else if (formfield.type === 'number-input') {

                    var imagesrc
                    var value

                    if (formfield.name === "adult") {
                        imagesrc = adultimg;
                        value = adult

                    } else if (formfield.name === "child") {
                        imagesrc = childrenimg;
                        value = child

                    } else if (formfield.name === "pet") {
                        imagesrc = petimg;
                        value = pet
                    } else if (formfield.name === "noofserviceanimal") {
                        imagesrc = petimg;
                        value = noofserviceanimal
                    }
                    return <div className={formfield.gridsize}>
                        <div className="form-group adult-children">
                            <input id={formfield.field} type="text"
                                name={formfield.field}
                                className="form-control"
                                placeholder={formfield.placeholder}
                                value={value + " " + formfield.text}
                                readOnly
                                required />
                            <label className="input-group-text-lg input-group-text-left" htmlFor="form-adult">
                                <img src={imagesrc} alt="img" title="img" />
                            </label>
                            <label className="input-group-text-lg input-group-text-right" htmlFor="form-children">
                                <div className="plus-minus-btn">
                                    <ul className="plusminus-list m-0">
                                        <li>
                                            <div className="submit-btn-sec m-0">
                                                <a className="btn plus-minus-icon" onClick={() => { handleMemberCountMinus(formfield.name); }}>
                                                    <span>-</span>
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="submit-btn-sec m-0">
                                                <a className="btn plus-minus-icon" onClick={() => { handleMemberCountPlus(formfield.name) }}>
                                                    <span>+</span>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </label>
                        </div>
                    </div>
                }
                else if (formfield.type === 'dropdown') {
                    if (formfield.masterdataarray) {
                        return <div className={formfield.gridsize}>
                            <div className="form-group">
                                <label className="label-form">{formfield.text}</label>
                                <SelectPickerRsuite
                                    className="form-control p-0"
                                    data-size="10"
                                    id={`formfield${formfield.field}`}
                                    onChange={(e) => { props.handleFormData(formfield.type, formfield.field, e, formfield.masterdatafield,) }}
                                    name={`formfield${formfield.field}`}
                                    data={selectearlychackin}
                                    searchable={false}
                                    cleanable={false}

                                />
                            </div>
                        </div>
                    } else {
                        return (
                            <div className={formfield.gridsize}>
                                <div className="form-group">
                                    <label className="label-form">{formfield.text}</label>
                                    {
                                        formfield.field === "earlycheckintimeid"
                                            ?
                                            <SelectPickerRsuite
                                                className="form-control p-0"
                                                value={getCurrentState()?.formData?.earlycheckintimeid ? getCurrentState()?.formData?.earlycheckintimeid : ''}
                                                data-size="10"
                                                id={`formfield${formfield.field}`}
                                                onChange={(e) => { props.handleFormData(formfield.type, formfield.field, e) }}
                                                name={`formfield${formfield.field}`}
                                                placeholder="Select Early Checkin Time"
                                                data={stayDetailData.masterData[formfield.masterdata]}
                                                searchable={false}
                                                cleanable={false}
                                            />
                                            :
                                            <div className='main-selectpicker' ref={mountRef}>
                                                <SelectPicker
                                                    container={() => mountRef.current} //Anjali 17/08/2023
                                                    className="form-control p-0"
                                                    data-size="10"
                                                    id={`formfield${formfield.field}`}
                                                    onChange={(e) => { handleChangeVehicle(e, formfield.field) }}
                                                    name={`formfield${formfield.field}`}
                                                    data={stayDetailData.masterData[formfield.masterdata]}
                                                    searchable={false}
                                                    cleanable={false}
                                                    value={props.vehicleplaceholder[formfield.field]}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                }
                else if (formfield.type === 'timepicker') {

                    // let value = getCurrentState().formData[formfield.field] ? IISMethods.getDateFormat(getCurrentState().formData[formfield.field], 12, true) + ' ' + IISMethods.getTimeFormat(getCurrentState().formData[formfield.field], 12, false, true) : ''
                    let value = getCurrentState().formData[formfield.field]
                    return (
                        <div className={formfield.gridsize}>
                            <div className="form-group validate-input">
                                <label className="label-form">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                {/* <TimePickerRsuite
                                    className="form-control p-0 has-val"
                                    placeholder={`Enter ${formfield.text}`}
                                    format={IISMethods.getTimeFormatByProfile()}
                                    id={'form-' + formfield.field}
                                    name={formfield.field}
                                    // value={value ? new Date(value) : null} 
                                    // value={value ? IISMethods.GetDateTimefromISOString(value) : null} 
                                    value={value ? IISMethods.GetISOtoTZtime(value) : ''}
                                    type="text"
                                    showMeridian
                                    cleanable={formfield.cleanable}
                                    ranges={[]}
                                    onChange={(e) => {
                                        props.handleFormData(formfield.type, formfield.field, e)
                                    }}
                                /> */}
                                <TimePickerRsuite
                                    readOnly={true}
                                    placement='bottom'
                                    InputProps={{ onKeyDown: e => e.preventDefault() }}
                                    onKeyPress={e => e.preventDefault()}
                                    className="form-control p-0 has-val"
                                    placeholder={`Enter ${formfield.text}`}
                                    format={IISMethods.getTimeFormatByProfile()}
                                    id={"form-" + formfield.field}
                                    name={formfield.field}
                                    value={value ? IISMethods.GetDateTimefromISOString(value) : null}
                                    type="text" ranges={[]} showMeridian
                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                />
                            </div>
                        </div>
                    )
                } else if (formfield.type === 'radio') {
                    if (formfield.field === 'serviceanimalquestion1') {
                        return (
                            (getCurrentState().formData.noofserviceanimal > 0) &&
                            <div ref={serviceanimalquestion1Ref} className={formfield.gridsize}>

                                <div className={`form-group validate-input ${props.errors[formfield.field] ? 'box-shadow-2px-lightgray border-danger border-radius-10 bg-color-danger-input p-2 ' : ''}`}>
                                    <label className="label-form">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                    <div className="col-12 px-0">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="btn-radio-control" id={formfield.field}>
                                                    {
                                                        formfield.radiooptions && formfield.radiooptions.map((data) => {
                                                            return (
                                                                <>
                                                                    <div className="custom-control custom-radio mb-3" key={data.value}>
                                                                        <input disabled={formfield.disabled} type="radio" id={formfield.field + data.value}
                                                                            readOnly name={formfield.field + data.value} className="custom-control-input"
                                                                            checked={getCurrentState().formData[formfield.field] === data.value}
                                                                        />
                                                                        <label className="custom-control-label px-2 mb-0" htmlFor={formfield.field + data.value} onClick={() => formfield.disabled === false && props.handleFormData(formfield.type, formfield.field, data.value)}>{data.label}</label>
                                                                    </div>
                                                                </>

                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            (!getCurrentState().formData[formfield.field])
                                                ?
                                                <div className="text-danger text-12 text-capitalize font-weight-600">Is the dog or a service animal required because of a disability ? must be yes</div>
                                                :
                                                ''
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
                else return <>{formfield.field}</>
            }
        } catch { return <></> }
    }

    const homeStayInfoPage = () => {

        return <>
            <section className="stayinformation-sec section-spacing pb-4 inner-fixed-height">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="stay-information-info info-data">
                                    <h1 className="main-title text-blue">Your Stay Information</h1>
                                    <div className="desc-data">
                                        <p className="text-blue">Please provide the below details</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row'>
                            <div className="col-12 mx-auto ">
                                {/* standardcheckin-sec */}
                                <div className="">
                                    <div className="card bg-blue py-2">
                                        <div className="card-body">
                                            <div className="standardcheckin-title text-center text-blue fw-500 ">
                                                <p className='text-19'>Our Standard Check-In Time is</p>
                                            </div>
                                            <div className="time-info text-center lightblue">

                                                <p>{IISMethods.getTimeFormat(checkinTime, 1, false, true)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {getCurrentState().formData.ischeckin === 0 && hrLine}
                            <div className="col-12 mx-auto">
                                <form>
                                    <div className="row">
                                        {/* isearlycheckin  */}
                                        {
                                            getCurrentState().formData.ischeckin === 0 &&
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="label-form">{rightSidebarFormData[0]?.formfields[3]?.text}</label>
                                                    <div className='main-selectpicker' ref={mountRef}>
                                                        <SelectPicker
                                                            container={() => mountRef.current} //Anjali 17/08/2023
                                                            className="form-control p-0"
                                                            data-size="10"
                                                            id={`formfield${rightSidebarFormData[0]?.formfields[3]?.field}`}
                                                            name={`formfield${rightSidebarFormData[0]?.formfields[3]?.field}`}
                                                            data={selectearlychackin}
                                                            searchable={false}
                                                            cleanable={false}
                                                            value={isearlycheckin}
                                                            onChange={(e) => {
                                                                setIsearlycheckin(e)
                                                                props.handleFormData(rightSidebarFormData[0]?.formfields[3].type, rightSidebarFormData[0]?.formfields[3]?.field, e, rightSidebarFormData[0]?.formfields[3]?.masterdatafield,)
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        isearlycheckin === 1 &&
                                                        <div className='text-danger text-14 font-weight-500'>* Note: Early Check-In  Subject to availability.*</div>
                                                    }
                                                </div>

                                            </div>
                                        }

                                        {
                                            isearlycheckin === 1 ?
                                                renderHtmlControls(rightSidebarFormData[0]?.formfields[4])//earlycheckintimeid
                                                : renderHtmlControls(rightSidebarFormData[0]?.formfields[8])//estimatedcheckintime
                                        }

                                        {hrLine}

                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}  {/* mobilecheckinnoofadults */}
                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}{/* mobilecheckinnoofchildren */}
                                        {
                                            IISMethods.getLocalData('headerpropertyData')?.allowpet === 1 &&// || IISMethods.getLocalData('reservationDetails')?.property?.allowpet === 1) 
                                            renderHtmlControls(rightSidebarFormData[0]?.formfields[2])// mobilecheckinnoofpets
                                        }

                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[9])} {/* service animal */}
                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[10])} {/* */}

                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[11])} {/*  */}

                                        {
                                            IISMethods.getLocalData('headerpropertyData').parkingpass === 1 &&
                                            <>
                                                {hrLine}
                                                <div className="col-12 mb-4">
                                                    <span className='label-form text-18 text-light-blue'>Optional Information</span>
                                                </div>
                                                {/* manual data */}
                                                {
                                                    isManualData === false &&
                                                    <div className="col-12">
                                                        <div className="submit-btn-sec">
                                                            <div to="/ratecard" className="btn submit-btn btn-icon btn-sm-txt" onClick={() => props.handleFormData('options', 'add-vehicle', 'add-btn')}>
                                                                <div className="mx-auto">
                                                                    <span className="d-inline-block">Add Vehicle Information Manually</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    getCurrentState().formData?.vehicleinformations?.map((data, index) => {
                                                        if (Object.keys(data).length > 0) {
                                                            return <>
                                                                <div className="col-12">
                                                                    <div className="form-group information-parking">
                                                                        <label className="label-form">LICENSE PLATE NUMBER</label>
                                                                        <input
                                                                            onChange={(e) => { props.handleFormData('options', 'add-vehicle', e.target.value, '', index, 'vehiclenumberplate') }}
                                                                            key={data.field + index}
                                                                            type="text"
                                                                            value={data.vehiclenumberplate}
                                                                            className="form-control vehicle"
                                                                            placeholder="Enter car license plate for parking"
                                                                            required={true}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="form-group information-parking">
                                                                        <label className="label-form">VEHICLE MAKE AND MODEL</label>
                                                                        <input
                                                                            onChange={(e) => { props.handleFormData('options', 'add-vehicle', e.target.value, '', index, 'vehiclemakemodel') }}
                                                                            key={data.field + index}
                                                                            type="text"
                                                                            value={data.vehiclemakemodel}
                                                                            className="form-control vehicle"
                                                                            placeholder="Enter Vehicle make and model"
                                                                            required={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    })
                                                }

                                                <div className="col-12 ">
                                                    <div className="form-group form-or">
                                                        <p>OR</p>
                                                    </div>
                                                </div>

                                                {/* capture photos */}
                                                <div className="col-12">
                                                    <div className="submit-btn-sec">
                                                        <div to="/ratecard" className="set-vehicalbtn btn submit-btn btn-icon btn-sm-txt" onClick={() => { setPage("demo") }}>
                                                            <div className="mx-auto">
                                                                <span className="d-inline-block">Capture License Plate Photo</span> {/** Capture Photo Of Vehicle */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <div className="col-12">
                <div to="/ratecard" className="btn submit-btn btn-icon paymentcard-submit-btn fixed-btn mb-4" onClick={() => { handleSubmit() }}>
                    <div className="mx-auto">
                        <div className="d-inline-block mr-3">
                            <Loader size="sm" content={'Review Hotel Charges'} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
    return (
        <>
            <Header backTo="yourregistrationdetail" title="Stay Details" backtoPreviousPage={props.backtoPreviousPage} setPage={setPage} current={page === "demo" ? "yourstayinfoDemo" : "yourstayinfoHome"} />
            {
                page === "home" ?
                    homeStayInfoPage()
                    : page === "demo" ?
                        <YourStayInfoViewPhoto handleSubmit={handleSubmit} handleYourStayInfo={props.handleYourStayInfo} handleFormData={props.handleFormData} hrLine={hrLine} setPage={setPage} />
                        :
                        ""
            }

        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(YourStayInfoView)


