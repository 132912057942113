import React from 'react'
import WelcomePageView from './WelcomePageView'
import CheckinView from './CheckinView'
import StaydetailView from './StaydetailView'
import IDVerificationView from './IDVerificationView'
import SelfVerificationView from './SelfVerificationView'
import YourRegistrationDetailView from './YourRegistrationDetailView'
import YourStayInfoView from './YourStayInfoView'
import { resBody } from '../../config/IISMethods'
import RateCardView from './RateCardView'
import HotelTnCView from './HotelTnCView'
import SuccessfullyCompletedView from './SuccessfullyCompletedView'
import ErrorPage from '../ErrorPage'
import PaymentMethodController from '../../Controller/PaymentMethodController'
import IdVerificationController from '../../Controller/IdVerificationController'
import SelfVerificationController from '../../Controller/SelfVerificationController'

const MasterView = (
    {
        pagename, handleNextPage, addData,
        backtoPreviousPage, handleFormData,
        getMasterData, handleUserVerification, setFormData,
        handleStaydetailClick, rightSidebarFormData,
        handleIdverification, handleSelfVerification, listData,
        handleRegistration, errors, setErrors, handleYourStayInfo,
        reservationDetail, vehiclelist, setVehiclelist, vehicleplaceholder,
        setVehicleplaceholder, handleRateCard, handlePaymentMethod, handleHotelTnC,
        handleGrid, handlePagelimit, handleScroll, itemqty, handleFilterData, getPolicy,
        addLoaders, handleVerificationNextPage
    }
) => {



    return (
        pagename === "welcome" ?
            <WelcomePageView
                handleNextPage={handleNextPage}
                addData={addData}
            />
            : pagename === "checkin" ?
                <CheckinView
                    handleGrid={handleGrid}
                    backtoPreviousPage={backtoPreviousPage}
                    handleFormData={handleFormData}
                    getMasterData={getMasterData}
                    handleUserVerification={handleUserVerification}
                    setFormData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                /> : pagename === "staydetail" ?
                    <StaydetailView
                        backtoPreviousPage={backtoPreviousPage}
                        handleStaydetailClick={handleStaydetailClick}
                    /> : pagename === "idverification" ?
                        <IDVerificationView
                            handleNextPage={handleNextPage}
                            handleFormData={handleFormData}
                            rightSidebarFormData={rightSidebarFormData}
                            backtoPreviousPage={backtoPreviousPage}
                            handleIdverification={handleIdverification}
                            addLoaders={addLoaders}
                            handleVerificationNextPage={handleVerificationNextPage}
                        />
                        : pagename === "idverificationcamera" ?
                            <IdVerificationController
                                handleNextPage={handleNextPage}
                                handleFormData={handleFormData}
                                rightSidebarFormData={rightSidebarFormData}
                                backtoPreviousPage={backtoPreviousPage}
                                handleIdverification={handleIdverification}
                                addLoaders={addLoaders}
                                handleVerificationNextPage={handleVerificationNextPage}
                            />
                            : pagename === "selfverification" ?
                                <SelfVerificationView
                                    handleNextPage={handleNextPage}
                                    rightSidebarFormData={rightSidebarFormData}
                                    handleFormData={handleFormData}
                                    backtoPreviousPage={backtoPreviousPage}
                                    handleSelfVerification={handleSelfVerification}
                                    handleVerificationNextPage={handleVerificationNextPage}
                                    addLoaders={addLoaders}
                                />
                                : pagename === "selfverificationcamera" ?
                                    <SelfVerificationController
                                        handleNextPage={handleNextPage}
                                        rightSidebarFormData={rightSidebarFormData}
                                        handleFormData={handleFormData}
                                        backtoPreviousPage={backtoPreviousPage}
                                        handleSelfVerification={handleSelfVerification}
                                        addLoaders={addLoaders}
                                    />
                                    : pagename === "yourregistrationdetail" ?
                                        <YourRegistrationDetailView
                                            reservationDetails={resBody}
                                            listData={listData}
                                            backtoPreviousPage={backtoPreviousPage}
                                            handleFormData={handleFormData}
                                            handleRegistration={handleRegistration}
                                            errors={errors}
                                            setErrors={setErrors}
                                            rightsidebarformdata={rightSidebarFormData}
                                        />
                                        :
                                        pagename === "yourstayinfo" ?
                                            <YourStayInfoView
                                                backtoPreviousPage={backtoPreviousPage}
                                                handleYourStayInfo={handleYourStayInfo}
                                                handleFormData={handleFormData}
                                                reservationDetail={reservationDetail}
                                                vehiclelist={vehiclelist}
                                                setVehiclelist={setVehiclelist}
                                                vehicleplaceholder={vehicleplaceholder}
                                                setVehicleplaceholder={setVehicleplaceholder}
                                                rightsidebarformdata={rightSidebarFormData}
                                                errors={errors}
                                                setErrors={setErrors}

                                            /> : pagename === "ratecard" ?
                                                <RateCardView
                                                    backtoPreviousPage={backtoPreviousPage}
                                                    handleRateCard={handleRateCard}
                                                    handleYourStayInfo={handleYourStayInfo}
                                                    setFormData={setFormData}
                                                    reservationDetail={reservationDetail}
                                                    rightsidebarformdata={rightSidebarFormData}
                                                    getPolicy={getPolicy}
                                                />
                                                :
                                                pagename === "paymentmethod" ?
                                                    <PaymentMethodController
                                                        handleNextPage={handleNextPage}
                                                        backtoPreviousPage={backtoPreviousPage}
                                                        handleFormData={handleFormData}
                                                        handlePaymentMethod={handlePaymentMethod}
                                                        errors={errors}
                                                        setErrors={setErrors}
                                                        rightSidebarFormData={rightSidebarFormData}
                                                    /> :
                                                    pagename === "hoteltnc" ?
                                                        <HotelTnCView
                                                            backtoPreviousPage={backtoPreviousPage}
                                                            handleHotelTnC={handleHotelTnC}
                                                            reservationDetail={reservationDetail}
                                                        />
                                                        :
                                                        pagename === "successfullycompleted" ?
                                                            <SuccessfullyCompletedView
                                                                backtoPreviousPage={backtoPreviousPage}
                                                                handleNextPage={handleNextPage}
                                                            />
                                                            :
                                                            <ErrorPage />

    )
}

export default MasterView