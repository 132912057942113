import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Config, IISMethods } from '../../config/IISMethods'
import FormController from '../../Controller/FormController'
import { setProps } from '../../redux/actions'

const PaymentMethod = () => {
    const dispatch = useDispatch()
    // useEffect(()=>{
    //     IISMethods.setLocalData('counter',IISMethods.getLocalData('counter')+1||0)
    // },[])

   const rightSidebarFormData= [
        {
            pagename: 'paymentmethod',
            formname: 'Payment Method',
            useraction: 'mobilecheckin',
            apipath: 'mobilecheckin/update',
            alias: 'paymentmethod',
            formfields: [
                {
                    'field': 'cardnumber',
                    'text': 'Card Number',
                    'type': Config.getHtmlcontorls()['input-text'],
                    'disabled': true,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-9'],
                    // 'maxlength': 16,
                    // 'pattern': '^[0-9\s]+$',
                    'patterns': '^\d{4}( \d{4}){3}$',
                    'inputfor': 'cardnumber',
                    // 'regex': /^\d{16}$/
                    // 'regex': Config.getRegex()['cardnumber']
                    // 'regex': '^[\d\s-]{13,19}$'
                },
                {
                    'field': 'cardexpirydate',
                    'text': 'Expiry Date',
                    // 'type': Config.getHtmlcontorls()['input-text'],
                    'type': Config.getHtmlcontorls()['datepicker'],
                    'disabled': true,
                    'defaultvisibility': true,
                    'required': true,
                    'gridsize': Config.getGridclasses()['col-7'],
                    'inputfor': 'carddate' ,
                    'disablefromdate':IISMethods.GetPropertyDateWithCurrentTime()                 
                },
            ]
        },
    ]

    useEffect(() => {
        dispatch(setProps({
            formData:{},
            rightSidebarFormData:rightSidebarFormData
        }))

    }, [])
    return <FormController pagename="paymentmethod" rightSidebarFormData={rightSidebarFormData}/>
}

export default PaymentMethod