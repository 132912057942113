import { useState, useEffect } from "react"
import { Config, IISMethods, resBody, JsCall, pagelimit, setReqParams, reqHeaders, reqBody, resHeaders } from '../config/IISMethods'
import { store } from '../index'
import { setLoading, setLoadings, setProps } from '../redux/actions'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import MasterView from "../view/MobileCheckIn/MasterView"
import { connect } from 'react-redux'

let initialState = { vehiclemakemodel: "", vehiclenumberplate: "" }
const initialItemQty = 1

const FormController = (props) => {

    const [state, setState] = useState()
    const dispatch = useDispatch()
    const history = useNavigate();
    const loc = useLocation()

    //for acccess store data 
    const getCurrentState = () => store.getState()

    const [itemqty, setItemqty] = useState(initialItemQty)
    const [vehicleplaceholder, setVehicleplaceholder] = useState(null)
    const [errors, setErrors] = useState({})
    const [vehiclelist, setVehiclelist] = useState()

    //getting data from querystring
    const urlparams = new URLSearchParams(window.location.search)
    const subdomainname = urlparams?.get('bookingid')?.split('-')[0]
    const bookingid = urlparams?.get('bookingid')?.split('-')[1]
    const queryString = urlparams?.get('bookingid')?.split('-')[0] !== undefined ? `?bookingid=${subdomainname}-${bookingid}` : ""

    useEffect(() => {
        function fetchMastersJson() {
            IISMethods.setLocalData("subdomainname", subdomainname)
            IISMethods.setLocalData("bookingid", bookingid)
            IISMethods.setLocalData("queryString", queryString)

            dispatch(setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                sortData: {},
                formData: IISMethods.getCopy(getCurrentState().formData), //props.pagename === 'ratecard' ? IISMethods.getCopy(getCurrentState().formData) : {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                masterData: {},
                masterDataList: {}
            }))
        }

        if (props.pagename === "yourregistrationdetail") {
            listverifyData(props.pagename)
        }

        // if (props.pagename !== "ratecard") {
        //     setFormData()
        // }
        if (!subdomainname || !bookingid) {//|| !confirmationid
            handleNextPage("*")
        }
        fetchMastersJson()
    }, [loc.pathname])

    useEffect(() => {
        delete getCurrentState().masterData['state']
        delete getCurrentState().masterData['city']
        dispatch(setProps({ masterData: getCurrentState().masterData }))
    }, [getCurrentState().reservationDetails?.countryid])


    useEffect(() => {
        delete getCurrentState().masterData['city']
        dispatch(setProps({ masterData: getCurrentState().masterData }))
    }, [getCurrentState().reservationDetails?.stateid])

    //listverifyData
    const listverifyData = async (page) => {

        const useraction = "mobilecheckin"
        const loginInfo = IISMethods.getCookiesData("mobilecheckin_loginInfo")
        let reqheader = {
            token: loginInfo.token,
            issuer: "website",// process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: "1",// process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: "/" + page,
            subdomainname: loginInfo.subdomainname
        }

        const url = Config.weburl + "mobilecheckin/verify"
        const req = {
            _id: loginInfo.bookingid,
            lastname: IISMethods.getCookiesData('mobilecheckin_loginInfo').lastname,
            checkindate: IISMethods.getCookiesData('mobilecheckin_loginInfo').checkindate,

        }
        if (Config.encryptdata) { reqHeaders = { reqheader: IISMethods.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1) {
            if (!reqBody.reqBody) { reqBody = { reqBody: IISMethods.encryptData(reqBody) } }
        }

        await IISMethods.axiosrequest(process.env.REACT_APP_METHOD_POST, url, req, reqHeaders, addDataSuccessCallback.bind(this), addDataErrorCallback.bind(this))

        function addDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(IISMethods.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(IISMethods.decryptData(res.headers))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function addDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.headers))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }
    }


    //select picker printing 
    const printSelectPicker = (data, fieldobj) => {

        if (fieldobj.masterdata === 'state' || fieldobj.masterdata === 'country') {
            return data[fieldobj.masterdatafield]
        }
        const timefrom = IISMethods.getTimeFormat(data.checkintimefrom, 1)
        const timeto = IISMethods.getTimeFormat(data.checkinttimeto, 1)
        return (
            fieldobj.masterdata === 'icon' ? <><i className={`${data['iconclass']}`}></i><span>{` ${data[fieldobj.masterdatafield]}`}</span></>
                :
                fieldobj.masterdata === "staffregistration" ? <><figure className="user-img"><img src={data['profilepic'].trim().length ? data['profilepic'] : Config.getDefaultCandidateImageUploadUrl()} /></figure><>{data[fieldobj.masterdatafield]}</></>
                    :
                    (fieldobj.masterdata === 'state' || fieldobj.masterdata === 'country') ? <span>{data[fieldobj.masterdatafield] + (data[fieldobj.masterdatafield + 'code'] ? `  (${data[fieldobj.masterdatafield + 'code']})` : '')}</span>
                        :
                        fieldobj.field === 'earlycheckintimeid' ? <span>{`${timefrom} - ${timeto} ($${data.charge} fees)`} </span>
                            :
                            `${data[fieldobj.masterdatafield]}`
        )
    }

    const getMasterData = async (pageNo = 1, fieldobj, formData, storemasterdatabyfield) => {
        var filter = {}
        var isdepent = 0
        if (fieldobj.dependentfilter) { //dependent filter set - object set
            Object.keys(fieldobj.dependentfilter).map(key => {
                const value = formData[fieldobj.dependentfilter[key]]
                if (value) {
                    isdepent = 1
                    filter[key] = value
                }
            })
        }

        if (fieldobj.staticfilter) { //static filter object assign
            Object.assign(filter, fieldobj.staticfilter)
        }

        const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)
        if (!fieldobj.masterdatadependancy || isdepent == 1) {
            const url = Config.weburl + fieldobj.apipath
            const useraction = 'list' + fieldobj.masterdata + 'data'

            filter = {
                ...IISMethods.getCopy(fieldobj.filter),
                ...filter,
            }
            if (fieldobj.apipath === "property/timing/checkin" && props.pagename === "yourstayinfo") {

                filter.propertyid = getCurrentState().reservationDetails?.property?._id || IISMethods.getLocalData('reservationDetails')?.property?._id
                filter.roomtypeid = getCurrentState().reservationDetails?.roomtypeid || IISMethods.getLocalData('reservationDetails')?.roomtypeid // Bhautik 11-07-2023 12:35 AM
                // filter.roomtypeid = getCurrentState().reservationDetails.roomtypemasterid || IISMethods.getLocalData('reservationDetails').roomtypemasterid
            }

            if (Config.categoryid.request === getCurrentState().formData['categoryid'] && fieldobj.field === 'productid') {
                filter['isrequest'] = 1
            } else if (Config.categoryid.maintenance === getCurrentState().formData['categoryid'] && fieldobj.field === 'productid') {
                filter['ismaintainable'] = 1
            }

            if (fieldobj.staticfilter) { //static filter object assign
                filter = Object.assign(filter, fieldobj.staticfilter)
            }
            let reqBody = {
                paginationinfo: {
                    pageno: pageNo,
                    pagelimit: pagelimit[pagelimit.length - 1].value,
                    filter: filter,
                    sort: {}
                }
            }

            if (fieldobj.field === "productid") {
                reqBody = { ...reqBody, categoryid: "62fe1718cbac206928991222" }
            }

            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction, true)

            if (resBody.status === 200) {
                getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
                getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)

                if (pageNo === 1) {
                    getCurrentState().masterData[masterdatakey] = []
                    getCurrentState().masterDataList[masterdatakey] = []
                }

                resBody.data.map((data) => {
                    let role = {}
                    if (fieldobj.groupwise) {
                        role = { role: data[fieldobj.groupfield] }
                    }
                    getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data._id, ...role })
                })
                getCurrentState().masterDataList[masterdatakey] = getCurrentState().masterDataList[masterdatakey].concat(resBody.data)
                dispatch(setProps({ masterData: getCurrentState().masterData, masterDataList: getCurrentState().masterDataList }))

                if (resBody.nextpage)
                    await getMasterData(pageNo + 1, fieldobj, formData, storemasterdatabyfield)
            }
        }
    }

    //form value prepare - onblut & onclick
    const handleFormData = async (type, key, value, fieldtype, fieldkey, fieldvalue) => {

        const rightSideName = getCurrentState().rightSidebarFormData[0]?.pagename
        var rightSidebarFormData
        if (getCurrentState().modal.rightSidebar) {
            rightSidebarFormData = getCurrentState().rightSidebarFormData
        }
        if (type === 'checkbox' || type === 'switch') {
            getCurrentState().formData[key] = (value ? 1 : 0)
        }
        else if (type === 'number-input') { //heli_13/4/2023(7:22)
            getCurrentState().formData[key] = Number(value) || 0

            if (key === 'noofserviceanimal' && value <= 0) {
                getCurrentState().formData['serviceanimalquestion1'] = 0
                getCurrentState().formData['serviceanimalquestion2'] = ''
                getCurrentState().reservationDetails.serviceanimalquestion1 = 0
                getCurrentState().reservationDetails.serviceanimalquestion2 = ''
                IISMethods.setLocalData('reservationDetails', { ...getCurrentState().reservationDetails, ...getCurrentState().formData['vehicleinformations'] })
            }
        }
        else if (type === 'timepicker' || type === 'datepicker') {
            let dateValue = value
            if (!dateValue) {
                delete getCurrentState().formData[key]
            } else {
                if (rightSideName === "checkin") {
                    getCurrentState().formData[key] = IISMethods.ConvertDateObjTOISOString(IISMethods.setStartTimeOfDay(value))
                } else if (key === 'estimatedcheckintime') {
                    getCurrentState().formData[key] = value ? IISMethods.GetISOFromTMobj(value) : ''//(dateValue ? IISMethods.GetDateTime(dateValue).toISOString() : '')
                }
                else {
                    getCurrentState().formData[key] = (dateValue ? IISMethods.GetDateTime(dateValue).toISOString() : '')
                }
            }
            if (key === 'time') {
                getCurrentState().formData["time"] = IISMethods.stringToISOFormat(value)
                const hours = value.getHours()
                const minutes = value.getMinutes()
                getCurrentState().formData["hours"] = hours
                getCurrentState().formData["minutes"] = minutes
            }
            else if (key === 'DNDtime') {
                getCurrentState().formData["DNDtime"] = IISMethods.stringToISOFormat(value)
                const DNDhours = value.getHours()
                const DNDminutes = value.getMinutes()
                getCurrentState().formData["DNDhours"] = DNDhours
                getCurrentState().formData["DNDminutes"] = DNDminutes
            }
        }
        else if (type === 'image' || type === 'file' || type === 'svg-file') {

            if (key === "vehicleimages") {
                if (fieldkey) {
                    getCurrentState().formData[key] = value
                    await dispatch(setProps({ formData: getCurrentState().formData }))
                } else {
                    const images = {}
                    images.image = await IISMethods.fileToBase64(value)
                    let vehicleImage = IISMethods.getLocalData('reservationDetails').vehicleimages || []
                    if (!vehicleImage?.length || vehicleImage.length < 1) {
                        getCurrentState().formData[key] = [...vehicleImage, images]
                        await dispatch(setProps({ formData: getCurrentState().formData }))

                    } else if (value.length < 1) {
                        // getCurrentState().formData[key] = []
                    }
                    else {
                        IISMethods.notify("You can select only one image of vehicle", 2)
                    }
                }
                let req = {}
                req['_id'] = getCurrentState().formData?._id
                req[key] = getCurrentState().formData[key]
                handleYourStayInfo(0, req)

            }

            else if (key === "images") {

                let tempArrList = []

                for (const index of Object.keys(value)) {
                    if (typeof (value) == 'string') {
                        tempArrList.push(value[index])
                    }
                    else {
                        tempArrList.push(await IISMethods.fileToBase64(value[index]))
                    }
                }
                getCurrentState().formData[key] = tempArrList
            }
            else if (key === 'idphoto') {
                getCurrentState().formData[key] = value
            } else {
                getCurrentState().formData[key] = await IISMethods.fileToBase64(value)

            }
        }
        else if (type === 'daterangepicker') {
            getCurrentState().formData[key] = (value ? value : [])
        }
        else if (type === 'dropdown' || type === "groupwisedropdown") {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            let getObjFromArray = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)
            if (key === "isearlycheckin") {
                getCurrentState().formData[key] = value
                if (fieldobj.resetfield) {
                    for (let resetfield of fieldobj.resetfield) {
                        let obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, resetfield)
                        if (obj.type === 'checkbox') {
                            getCurrentState().formData[resetfield] = 0
                        } else {
                            getCurrentState().formData[resetfield] = ''
                        }
                    }
                }
            }
            else if (key === "earlycheckintimeid") {
                getCurrentState().formData[key] = value
                const tempArr = getCurrentState().masterDataList.checkintimefrom.filter(obj => obj._id === getCurrentState().formData.earlycheckintimeid)
                getCurrentState().formData['earlycheckinfees'] = tempArr[0].charge


            }
            else if (fieldobj.masterdataarray) {
                getCurrentState().formData[key] = value ? value : ''
            }

            else {
                try {
                    if (key === "countryid") {
                        delete getCurrentState().reservationDetails['state']
                        delete getCurrentState().reservationDetails['stateid']
                        delete getCurrentState().reservationDetails['city']
                        delete getCurrentState().reservationDetails['cityid']
                    }
                    else if (key === "stateid") {
                        delete getCurrentState().reservationDetails['city']
                        delete getCurrentState().reservationDetails['cityid']
                    }
                    getCurrentState().formData[fieldobj.formdatafield] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)[fieldobj.masterdatafield]
                    getCurrentState().formData[key] = value
                }
                catch {
                    delete getCurrentState().formData[fieldobj.formdatafield]
                    delete getCurrentState().formData[key]
                }
            }

            if (fieldobj.extrafield) {
                fieldobj.extrafield.map((item) => {
                    getCurrentState().formData[item] = getObjFromArray[item]
                })
            }
        }
        else if (type === 'multipleselectdropdown') {
            try {
                const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
                value = value.map((fieldid) => {
                    return {
                        [fieldobj.formdatafield + 'id']: fieldid,
                        [fieldobj.formdatafield]: IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', fieldid)[fieldobj.masterdatafield]
                    }
                })
            }
            catch {
                value = []
            }
            getCurrentState().formData[key] = value
        }
        else if (type === 'colorpicker') {
            getCurrentState().formData[key] = value.rgb
        }
        else if (type === 'options') {
            if (key === "add-vehicle") {
                if (!value) {
                    if (fieldvalue === "vehiclenumberplate") {
                        getCurrentState().formData['vehicleinformations'][fieldkey][fieldvalue] = ''
                    }
                    else if (fieldvalue === "vehiclemakemodel") {
                        getCurrentState().formData['vehicleinformations'][fieldkey][fieldvalue] = ''
                    }
                }
                else if (value === 'add-btn') {
                    const vehicleinformations = getCurrentState().formData['vehicleinformations'] || []
                    getCurrentState().formData['vehicleinformations'] = [...vehicleinformations, { vehiclemakemodel: "", vehiclenumberplate: "" }]
                }
                else {
                    if (fieldvalue === "vehiclenumberplate") {
                        getCurrentState().formData['vehicleinformations'][fieldkey][fieldvalue] = value
                    } else if (fieldvalue === "vehiclemakemodel") {
                        getCurrentState().formData['vehicleinformations'][fieldkey][fieldvalue] = value
                    }
                }
                IISMethods.setLocalData('reservationDetails', { ...getCurrentState().reservationDetails, ...getCurrentState().formData['vehicleinformations'] })
            } else if (key === "remove-vehicle") {
                var tabledata = IISMethods.getCopy(getCurrentState().formData[fieldkey])//charmi(15-02-2023)
                tabledata.splice(fieldvalue, 1)//charmi(15-02-2023)
                getCurrentState().formData[fieldkey] = tabledata//charmi(15-02-2023)               
            }
        }
        else if (type === 'table-add-button') {

            const tableobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            var hasError = false
            var tabledata = {}
            for (const tablefield of tableobj.tablefield) {
                const tempHasError = JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData, tablefield)
                hasError ||= tempHasError
                if (!hasError) {
                    tabledata[tablefield] = getCurrentState().formData[tablefield]
                    const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, tablefield)

                    if (!fieldobj.masterdataarray && fieldobj.type === 'dropdown' && fieldobj.formdatafield) {
                        tabledata[fieldobj.formdatafield] = getCurrentState().formData[fieldobj.formdatafield]
                    }
                }
            }

            let isUniqueData = true
            if (tableobj.uniquefield !== undefined) {
                isUniqueData = IISMethods.getindexfromarray(getCurrentState().formData[key], tableobj.uniquefield, getCurrentState().formData[tableobj.uniquefield]) == -1 ? true : false
            }
            hasError ||= !isUniqueData

            if (hasError) {
                if (!isUniqueData) {
                    IISMethods.notify("Duplicate Data", 2)
                }
                else
                    IISMethods.notify("Fill all required data", 2)
            }
            else {
                for (const tablefield of tableobj.tablefield) {
                    const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, tablefield)

                    if (fieldobj.type === 'switch' || fieldobj.type === 'checkbox')
                        getCurrentState().formData[tablefield] = 0
                    else if (fieldobj.type === 'radio')
                        getCurrentState().formData[tablefield] = fieldobj.radiooptions[0].value
                    else if (fieldobj.type === 'default-radio')
                        getCurrentState().formData[tablefield] = fieldobj.radiooptions[0].value
                    else if (fieldobj.type === 'multipleselectdropdown' || fieldobj.type === 'daterangepicker' || fieldobj.type === 'table') {
                        getCurrentState().formData[tablefield] = []
                    }
                    else if (fieldobj.type === 'dropdown') {
                        delete getCurrentState().formData[tablefield]
                        delete getCurrentState().formData[fieldobj.formdatafield]
                    }
                    else if (type === 'intl-flagchange') {
                        getCurrentState().formData['guestcountryid'] = value.iso2
                        getCurrentState().formData['guestcountryname'] = value.name.split(' ')[0]
                        getCurrentState().formData['guestcountrycode'] = value.dialCode
                    }
                    else {
                        getCurrentState().formData[tablefield] = fieldobj.defaultvalue || ''
                        if (document.getElementById('form-' + fieldobj.field)) {
                            document.getElementById('form-' + fieldobj.field).value = ''
                        }
                    }

                    JsCall.handleError('form-' + fieldobj.field, false)
                }
                getCurrentState().formData[key].push(tabledata)
            }
        }
        else if (type === "table-remove-button") {
            getCurrentState().formData[key] = getCurrentState().formData[key].filter((_, index) => index !== value)
        }
        else if (type === "counter") {
            if (value === "inc") {
                setItemqty(itemqty + 1)
            } else if (value === "dec" && itemqty > 1) {
                setItemqty(itemqty - 1)
            } else if (value === "submit") {
                let formdata = getCurrentState().formData[key] || []
                let finditem = formdata.find(item => item._id === getCurrentState().formData.itemid)
                if (finditem) {
                    getCurrentState().formData[key] = formdata.map(item => {
                        if (item._id === getCurrentState().formData.itemid) {
                            item.quantity = itemqty
                        }
                        return item
                    })
                } else {
                    getCurrentState().formData[key] = formdata.concat({ _id: getCurrentState().formData.itemid, productname: getCurrentState().formData.item, quantity: itemqty, comment: getCurrentState().formData.comment })

                }
                delete getCurrentState().formData.item
                delete getCurrentState().formData.itemid
                setItemqty(initialItemQty)

            } else if (value === 'removeitem') {
                getCurrentState().formData[key] = getCurrentState().formData[key].filter(item => item[fieldkey] !== fieldvalue)
            }

        }
        else if (type === "text-area") {
            if (key === 'add-suggestion') {
                if (!value) {
                    if (fieldvalue === "suggestion")
                        getCurrentState().formData['rating'][fieldkey][fieldvalue] = ''

                } else {
                    if (fieldvalue === "suggestion")
                        getCurrentState().formData['rating'][fieldkey][fieldvalue] = value
                }
            }

        }
        else if (type === "add-remove-star") {
            if (key === 'rating') {
                getCurrentState().formData[key][fieldkey][value] = fieldvalue
            }
        }
        else if (type === 'intl-flagchange') {
            getCurrentState().formData[key[0]] = value[0]
            getCurrentState().formData[key[1]] = value[1]
            getCurrentState().formData[key[2]] = value[2]
            getCurrentState().formData[key[3]] = value[3]

        }

        else if (type === 'address-suggestion') {
            if (value) {
                delete errors[key]
                setErrors(errors)
                getCurrentState().formData[key] = value
                if (fieldvalue) {

                    let fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
                    delete errors[key]
                    setErrors(errors)
                    getCurrentState().formData[key] = value
                    if (fieldobj.onfill) {
                        for (let fields of fieldobj.onfill) {
                            getCurrentState().formData[fields === 'zipcode' ? 'zipcode' : fields] = fieldvalue[fields] ? fieldvalue[fields] : ''
                        }
                    }
                }
            }
            else {
                let fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
                errors[key] = "Address field required"
                setErrors(errors)
            }
        } else if (type === 'radio') {
            getCurrentState().formData[key] = value
            if (key === 'serviceanimalquestion1' && value) {
                if (getCurrentState().formData[key]) {
                    delete errors[key]
                    setErrors(errors)
                }
            }
        }
        else {
            getCurrentState().formData[key] = value
        }

        if ((key === 'maintenproductname' || key === 'maintenproductdesc') && props.pagename === 'mainten') {

            if (getCurrentState().formData['maintenproductname'] === '') {
                getCurrentState().formData['comment'] = getCurrentState().formData['maintenproductname'] ? getCurrentState().formData['maintenproductname'].concat(getCurrentState().formData['maintenproductdesc']) : getCurrentState().formData['maintenproductdesc']
            }
            else {
                getCurrentState().formData['comment'] = getCurrentState().formData['maintenproductname'].concat(' || ', getCurrentState().formData['maintenproductdesc'])
            }
        }
        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData), reservationDetails: IISMethods.getCopy(getCurrentState().reservationDetails) })

        const obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
        if (obj.onchangefill) {
            for (const field of obj.onchangefill) {
                const obj2 = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, field)

                if (type === "address-suggestion") { // for get address from google location
                    if (fieldvalue)
                        if (obj2.type === 'dropdown' && obj2.masterdata) {

                            await getMasterData(1, obj2, getCurrentState().formData) // get masterdata for onchangefill (country/state/city/pincode)
                            let masterdata = IISMethods.getobjectfromarray(getCurrentState().masterDataList[obj2.masterdata], obj2.masterdatafield, fieldvalue[obj2.masterdatafield])

                            getCurrentState().formData[obj2.field] = masterdata ? masterdata._id : ''
                            getCurrentState().formData[obj2.masterdata] = masterdata ? masterdata[obj2.masterdatafield] : ''
                        } else if (obj2.field === 'zipcode') {
                            getCurrentState().formData[obj2.field] = fieldvalue.zipcode
                        } else if (obj2.field === 'address') {
                            getCurrentState().formData[obj2.field] = fieldvalue.address
                        }

                    await dispatch(setProps({ formData: IISMethods.getCopy(getCurrentState().formData) }))
                } else {
                    delete getCurrentState().formData[obj2.field]
                    await dispatch(setProps({ formData: IISMethods.getCopy(getCurrentState().formData) }))
                    if (obj2.masterdata) {
                        await getMasterData(1, obj2, getCurrentState().formData)
                    }
                }

                if (getCurrentState().formData[field]) {
                    delete errors[field]
                    setErrors(errors)
                }
            }
        }
    }

    //edit button formdata set - props set 
    const setFormData = async (id) => {
        var tempFormData = {}
        if (props.pagename === "yourstayinfo") {
            const tempVehicleArr = IISMethods.getLocalData('reservationDetails')?.vehicleinformations.length > 0 ? IISMethods.getLocalData('reservationDetails')?.vehicleinformations : []
            setVehiclelist(tempVehicleArr)
            const vehicleimages = IISMethods.getLocalData('reservationDetails')?.vehicleimages ? IISMethods.getLocalData('reservationDetails')?.vehicleimages : []
            tempFormData['vehicleinformations'] = tempVehicleArr

            await dispatch(setProps({
                reservationDetails: {
                    ...IISMethods.getLocalData('reservationDetails'),
                    vehicleimages: vehicleimages,
                    vehicleinformations: tempVehicleArr,
                },
            }))
        }
        if (props.pagename === "home") {
            const tempVehicleArr = IISMethods.getLocalData('reservationDetails')?.vehicleinformations?.length > 0 ? IISMethods.getLocalData('reservationDetails')?.vehicleinformations : [initialState]
            setVehiclelist(tempVehicleArr)
            const vehicleimages = IISMethods.getLocalData('reservationDetails')?.vehicleimages ? IISMethods.getLocalData('reservationDetails')?.vehicleimages : []
            tempFormData['vehicleinformations'] = tempVehicleArr

            await dispatch(setProps({ reservationDetails: { ...IISMethods.getLocalData('reservationDetails'), vehicleimages: vehicleimages, vehicleinformations: tempVehicleArr }, }))
        }

        tempFormData.color = tempFormData.prioritycolor

        if (id) {
            tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        } else if (getCurrentState().reservationDetails && props.pagename === "yourstayinfo") {
            tempFormData = Object.assign(tempFormData, getCurrentState().reservationDetails)
            tempFormData['mobilecheckinnoofadults'] = (IISMethods.getLocalData('reservationDetails').mobilecheckinnoofadults > 1) ? IISMethods.getLocalData('reservationDetails').mobilecheckinnoofadults : 1
        }
        else {
            //formdata set 
            getCurrentState().rightSidebarFormData && getCurrentState().rightSidebarFormData.map((data) => {
                return data.formfields.map((fields) => {
                    if (fields.type === 'switch' || fields.type === 'checkbox') {
                        tempFormData[fields.field] = 0
                    }
                    else if (fields.type === 'radio') {
                        if (fields.field !== 'priorityid') {
                            tempFormData[fields.field] = fields.radiooptions[0].value
                        }
                    }
                    else if (fields.type === 'multipleselectdropdown' || fields.type === 'daterangepicker' || fields.type === 'timepicker' || fields.type === 'table') {
                        if (fields.field === 'estimatedcheckintime') {
                            tempFormData['estimatedcheckintime'] = getCurrentState().reservationDetails['estimatedcheckintime'] || IISMethods.getLocalData('reservationDetails')?.estimatedcheckintime || ''

                        } else {

                            tempFormData[fields.field] = []
                        }
                    }
                    else if (fields.type === 'dropdown') {
                        if (fields.masterdataarray) {
                            if (fields.masterdata && !fields.masterdataarray) {
                                if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata])
                                    getMasterData(1, fields, getCurrentState().formData)
                            }
                            else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) {
                                var array = []
                                for (const object of fields.masterdataarray) {
                                    array.push({
                                        label: object,
                                        value: object
                                    })
                                }
                                getCurrentState().masterData[fields.masterdata] = array
                                dispatch(setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) }))
                            }

                            if (fields.field === 'isearlycheckin') {
                                tempFormData['isearlycheckin'] = getCurrentState().reservationDetails['isearlycheckin'] || IISMethods.getLocalData('reservationDetails')?.isearlycheckin || 0
                            }

                            else {
                                tempFormData[fields.field] = fields.defaultvalue
                                tempFormData[fields.formdatafield] = fields.defaultvalue
                            }
                        }
                        else if (fields.field === 'earlycheckintimeid') {

                            tempFormData['earlycheckintimeid'] = getCurrentState().reservationDetails['earlycheckintimeid'] || IISMethods.getLocalData('reservationDetails')?.earlycheckintimeid || ''

                        }
                        else {
                            tempFormData[fields.field] = ''
                            tempFormData[fields.formdatafield] = ''
                        }
                    }
                    else
                        tempFormData[fields.field] = fields.defaultvalue !== undefined ? fields.defaultvalue : ''
                })
            })
            tempFormData['guestcountryid'] = props?.reservationDetail?.guestcountryid
            tempFormData['guestcountryname'] = props?.reservationDetail?.guestcountryname
            tempFormData['guestcountrycode'] = props?.reservationDetail?.guestcountrycode
        }

        await props.setProps({ formData: tempFormData })

        //error handling
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                JsCall.handleError('form-' + fields.field, false)
            }
        }

        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                if (fields.masterdata && !fields.masterdatadependancy) {
                    await getMasterData(1, fields, tempFormData) //

                    if (fields.field === 'priorityid') {
                        let tempObj = IISMethods.getCopy(getCurrentState().formData)
                        fields.extrafield.map((item) => {
                            if (fields.defaultselected) {
                                let index = IISMethods.getindexfromarray(getCurrentState().masterData[fields.masterdata], 'value', getCurrentState().formData[fields.field])
                                let masterdataindex = tempObj._id && (index !== -1) ? index : fields.field === 'priorityid' ? getCurrentState().masterData[fields.masterdata].length - 1 : 0
                                tempObj[fields.field] = getCurrentState().masterData[fields.masterdata][masterdataindex].value
                                tempObj[fields.masterdata] = getCurrentState().masterData[fields.masterdata][masterdataindex].label
                                tempObj[item] = getCurrentState().masterDataList[fields.masterdata][masterdataindex][item]
                            }
                        })
                        await props.setProps({ formData: tempObj })
                    }
                }
            }
        }

        for (const data of getCurrentState().masterRightSidebarFormData) {
            for (const fields of data.formfields) {
                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.field]) {
                        await getMasterData(1, fields, getCurrentState().masterFormData, true)
                    }
                }
                else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.field]) {
                    var array = []
                    for (const object of fields.masterdataarray) {
                        array.push({
                            label: object,
                            value: object
                        })
                    }
                    getCurrentState().masterData[fields.field] = array
                    await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) })
                }
            }
        }
    }

    const handleGrid = (id, type, key, value, editeDataIndex) => {
        if (type === 'switch' || type === 'checkbox') {
            var object = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
            object[key] = (value ? 1 : 0)
            updateData(object, editeDataIndex)
        }
        else if (type === 'dropdown') {
            var object = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
            const fieldobj = IISMethods.getobjectfromarray(getCurrentState().fieldOrder, 'field', key)
            const masterdataobject = IISMethods.getobjectfromarray(getCurrentState().masterData[fieldobj.masterdata], 'value', value)
            object[key] = masterdataobject.label
            object[key + 'id'] = masterdataobject.value
            updateData(object, editeDataIndex)
        }
        else if (type === 'modal') {
            if (value) {
                getCurrentState().modal[key] = id
            }
            else {
                delete getCurrentState().modal[key]
            }
            props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
        }
    }


    //for pagination 
    const handlePagelimit = async (value) => {
        props.setProps({ pagelimit: value })
        await props.setProps({ pageNo: 1 })
        await props.clearData()
    }

    // New function for add start
    const addData = async (reqData, pagename, apipath = '', useraction, nextpage = "", screen = "") => {

        await dispatch(setLoading({ loading: 1 }))

        if (apipath) {
            const url = Config.weburl + apipath
            await setReqParams(url, reqData)
            await IISMethods.addData(useraction, pagename)
        }

        if (resBody.status === 200) {

            // await dispatch(setProps({ reservationDetails: resBody.data }))
            await IISMethods.setLocalData("reservationDetails", resBody.data)

            if (pagename === "checkin") {
                IISMethods.setLocalData("sampleImages", resBody.data.sampleimages)
            }

            else if (pagename === "yourstayinfo") {

                if (nextpage === 1) {
                    await handleNextPage("ratecard", 'staydetails', {})
                }
                else if (nextpage === 0) {//vehicle photo
                    await dispatch(setProps({ reservationDetails: resBody.data }))
                    await IISMethods.setLocalData("reservationDetails", resBody.data)
                }
            }
        }
        else {
            IISMethods.notify(resBody.message, 2)
        }
        await dispatch(setLoading({ loading: 0 }))
    }

    const listData = async (reqData, apipath, useraction = "mobilecheckin", pagename = "yourrgisteration",) => {
        const url = Config.weburl + "masters/" + apipath
        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, pagename)
        if (resBody.status === 200) {

            getCurrentState().masterData[apipath] = []
            getCurrentState().masterDataList[apipath] = []


            getCurrentState().masterData[apipath] = resBody?.data?.map(res => {
                return { value: res._id, label: res[apipath] }
            })
            getCurrentState().masterDataList[apipath] = resBody.data
            dispatch(setProps({
                masterData: getCurrentState().masterData,
                masterDataList: getCurrentState().masterDataList
            }))

            // let api = apipath === "country" ? "state" : apipath === "state" ? "city" : null
            let api = null
            if (api && getCurrentState().reservationDetails[api + "id"]) {
                const reqbody = {
                    "paginationinfo":
                    {
                        "pageno": 1,
                        "pagelimit": 10000,
                        "filter": { [apipath + "id"]: getCurrentState().reservationDetails[apipath + "id"] },
                        "sort": {}
                    }
                }
                await listData(reqbody, api)
            }

            for (const data of getCurrentState().rightSidebarFormData) {
                for (const fields of data.formfields) {

                    if (fields.masterdata && !fields.masterdataarray) {
                        if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        {
                            await getMasterData(1, fields, getCurrentState().formData)
                        }
                    }
                    else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) { //

                        var array = []
                        for (const object of fields.masterdataarray) {
                            array.push({
                                label: object,
                                value: object
                            })
                        }
                        getCurrentState().masterData[fields.masterdata] = array
                        dispatch(setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) })) //
                    }
                }
            }
        }
    }

    const updateData = async (reqData, editeDataIndex = undefined) => {
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + props.apipagename + '/update'
        const useraction = 'update' + props.pagename + 'data'
        await setReqParams(url, reqData)
        await IISMethods.updateData(useraction)
        if (resBody.status === 200) {
            handleGrid(false, 'modal', 'rightSidebar', 0)
            await props.setProps({ pageNo: 1 })

            let updatedDataIndex = editeDataIndex > -1 ? editeDataIndex : state.lastEditedDataIndex
            getCurrentState().data[updatedDataIndex] = { ...resBody.data }
            await props.setGridListData({ data: IISMethods.getCopy(getCurrentState().data) })
        }
        await props.setLoading({ loading: 0 })
    }

    const handleVerificationNextPage = async (pagename = '', screen = '', extramobilechckindata = {}) => {
        let url = Config.weburl + 'mobilecheckin/update'
        let useraction = 'mobilecheckin'
        let reqData = { ...extramobilechckindata }
        if (screen) {
            reqData['screen'] = screen
        }
        reqData['_id'] = bookingid

        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, pagename)
        if (resBody.status === 200) {
            await dispatch(setProps({
                formData: Object.assign(IISMethods.getCopy(reqData), IISMethods.getCopy(resBody.data)),
                reservationDetails: Object.assign(IISMethods.getCopy(getCurrentState().reservationDetails), IISMethods.getCopy(resBody.data))
            }))
            await IISMethods.setLocalData("reservationDetails", resBody.data)

            if (pagename !== '') {
                if (!IISMethods.getLocalData('bookingid') || !IISMethods.getLocalData('subdomainname') || !subdomainname || !bookingid) {
                    await history(pagename)
                    return IISMethods.setLocalData("pagename", pagename)
                } else {
                    const search = `?bookingid=${subdomainname}-${bookingid}`
                    await history({ pathname: `/${pagename}`, search }, { state: { goBack: 0 } })
                    await IISMethods.setLocalData("pagename", pagename)
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
        else {
            // Bhautik 11-07-2023 02:17 AM ~
            IISMethods.localnotify(resBody.message)
            return;
        }
    }

    //next page render
    const handleNextPage = async (pagename = '', screen, extramobilechckindata = {}) => {

        if ((['checkinnow', 'reservationconfirmation', 'idverification', 'selfverification', 'paymentverification'].includes(screen)) && (IISMethods.getLocalData('reservationDetails')?.isupdate === 1 || screen === 'signatureverification')) {// 'staydetails',,, 'signatureverification''registrationdetail', 'paymentverificationsubmit' 'documentverification', 'ratedetails', 
            const url = Config.weburl + 'mobilecheckin/update'
            let useraction = 'mobilecheckin'
            let reqData = {
                screen: screen,
                _id: bookingid
            }
            if ((pagename === 'ratecard' || pagename === 'idverificationcamera') && Object.keys(extramobilechckindata).length) {
                reqData = { ...extramobilechckindata, ...reqData }
            }
            await setReqParams(url, reqData)
            await IISMethods.addData(useraction, pagename)

            if (resBody.status === 200) {
                if (pagename === 'ratecard' && Object.keys(extramobilechckindata).length) {
                    await dispatch(setProps({
                        formData: Object.assign(IISMethods.getCopy(reqData), IISMethods.getCopy(resBody.data)),
                        reservationDetails: Object.assign(IISMethods.getCopy(getCurrentState().reservationDetails), resBody.data)
                    }))
                    await IISMethods.setLocalData("reservationDetails", resBody.data)
                }
            }
            else {
                // Bhautik 11-07-2023 02:17 AM ~
                IISMethods.localnotify(resBody.message)
                return;
            }
        }

        if (pagename !== '') {
            if (!IISMethods.getLocalData('bookingid') || !IISMethods.getLocalData('subdomainname') || !subdomainname || !bookingid) {
                await history(pagename)
                return IISMethods.setLocalData("pagename", pagename)
            } else {
                const search = `?bookingid=${subdomainname}-${bookingid}`
                await history({ pathname: `/${pagename}`, search }, { state: { goBack: 0 } })
                await IISMethods.setLocalData("pagename", pagename)
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    //previous page
    const backtoPreviousPage = (pagename = "", current = "") => {

        const search = `?bookingid=${subdomainname}-${bookingid}`
        history({ pathname: `/${pagename}`, search })
        return IISMethods.setLocalData("pagename", pagename)
    }

    //submit checkinpage
    const handleUserVerification = async (e, isChecked,) => {

        e.preventDefault()
        const { lastname, checkindate } = getCurrentState().formData
        const { pagename, apipath, useraction } = getCurrentState().rightSidebarFormData[0]

        let error = Object.assign({}, errors)

        if (!lastname) {
            error.lastname = "Last Name is required"
        } else {
            delete error.lastname
        }
        if (!checkindate) {
            error.checkindate = "Date is required"
        } else {
            delete error.checkindate
        }
        setErrors(error)
        if (Object.keys(error).length === 0) {
            if (isChecked === true) {
                getCurrentState().formData._id = IISMethods.getCookiesData("mobilecheckin_loginInfo").bookingid
                const obj = IISMethods.getCookiesData('mobilecheckin_loginInfo')
                obj.lastname = lastname
                obj.checkindate = checkindate

                IISMethods.setCookiesData("mobilecheckin_loginInfo", obj)
                await addData(getCurrentState().formData, pagename, apipath, useraction, '', 'reservationconfirmation')

                if (resBody.status === 200) {
                    await handleNextPage('idverification', 'reservationconfirmation')

                    await dispatch(setProps({ formData: IISMethods.getCopy(resBody.data) }))
                }
            } else {
                IISMethods.notify("You must agree with the terms and conditions", 2)
            }
        } else {
            IISMethods.notify(`Fill all required field${(Object.keys(error).includes('lastname') && Object.keys(error).includes('checkindate')) ? 's' : ''}.`, 2)
        }
    }

    //submit stay-in detail
    const handleStaydetailClick = () => {
        handleNextPage('idverification', 'idverification')
    }

    const validateUrl = (str) => {
        let url;

        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    //submit id-verification (2nd)
    const handleIdverification = async () => {

        const imageData = getCurrentState().formData?.idphoto
        const { pagename, apipath, useraction } = getCurrentState().rightSidebarFormData[0]
        let imageislink = validateUrl(imageData)

        if (imageData !== null) {
            var reqData = {}

            reqData = {
                _id: bookingid ? bookingid : "",
                screen: 'documentsubmit'//'documentverification'
            }
            if (!imageislink) {
                reqData['idphoto'] = imageData
            }
            getCurrentState().formData._id = bookingid
            await addData(reqData, pagename, apipath, useraction,)
            if (resBody.status === 200) {
                await handleNextPage("selfverification", 'documentsubmit')
            }

        } else {
            IISMethods.notify("Please select photo ", 2)
        }
    }

    // submit self-verification(3rd)s
    const handleSelfVerification = async () => {
        const imageData = getCurrentState().formData?.selfphoto
        const { pagename, apipath, useraction } = getCurrentState().rightSidebarFormData[0]
        let imageislink = validateUrl(imageData)

        if (imageData !== null) {
            var reqData = {}
            reqData = {
                _id: bookingid ? bookingid : "",
                screen: 'selfverificationsubmit'
            }
            if (!imageislink) {
                reqData['selfphoto'] = imageData
            }
            await addData(reqData, pagename, apipath, useraction,)
            if (resBody.status === 200) {
                await handleNextPage("yourregistrationdetail", 'selfverificationsubmit')
            }
        } else {
            IISMethods.notify("Please select photo ", 2)
        }
    }

    //submit your registration detail(4th)
    const handleRegistration = async () => {
        const { firstname, lastname, guestemail, guestphoneno, country, state, city, fulladdress, address, zipcode, guestcountryname, guestcountrycode, guestcountryid } = getCurrentState().reservationDetails
        const { pagename, apipath, useraction } = getCurrentState().rightSidebarFormData[0]
        const formdata = IISMethods.getCopy(getCurrentState().formData)

        let reqData = {
            _id: bookingid ? bookingid : "",
            firstname: formdata.firstname ? formdata.firstname : firstname,
            lastname: formdata.lastname ? formdata.lastname : lastname,
            firstname: firstname,
            lastname: lastname,
            guestemail: formdata.guestemail ? formdata.guestemail : guestemail,
            guestphoneno: formdata.guestphoneno ? formdata.guestphoneno : guestphoneno,
            guestcountrycode: formdata.guestcountrycode ? formdata.guestcountrycode : guestcountrycode,
            guestcountryname: formdata.guestcountryname ? formdata.guestcountryname : guestcountryname,
            guestcountryid: formdata.guestcountryid ? formdata.guestcountryid : guestcountryid,
            country: formdata.country ? formdata.country : country,
            state: formdata.state ? formdata.state : state,
            city: formdata.city ? formdata.city : city,
            address: formdata.address ? formdata.address : address,
            zipcode: formdata.zipcode ? formdata.zipcode : zipcode,
            screen: 'registrationdetail'
        }

        let error = Object.assign({}, errors)

        for (let fields of getCurrentState().rightSidebarFormData[0].formfields) {
            if (!reqData[fields.field] && fields.required) {
                error[fields.field] = `${fields.errortext ? fields.errortext : fields.text} is required`
            }
        }
        setErrors(error)
        if (Object.keys(error).length === 0) {
            await addData(reqData, pagename, apipath, useraction)
            if (resBody.status === 200) {
                await handleNextPage("yourstayinfo", 'registrationdetail')
            }
        }
    }

    //submit your handleYourStayInfo detail (5th)
    const handleYourStayInfo = async (nextpage, vehiclereqdata = null) => {

        const { mobilecheckinnoofadults, mobilecheckinnoofpets, noofserviceanimal, mobilecheckinnoofchildren, isearlycheckin, earlycheckintimeid, earlycheckinfees } = getCurrentState().reservationDetails
        const { pagename, apipath, useraction } = getCurrentState().rightSidebarFormData[0]
        const formdata = getCurrentState().formData

        const vehicles = getCurrentState().formData?.vehicleinformations?.filter(element => {
            let obj = Object.values(["vehiclemakemodel",]).map(field => element[field] === "")
            if (obj.every(val => !val)) {
                return true
            }
            return false
        });

        const reqData = {
            _id: bookingid ? bookingid : "",
            isearlycheckin: formdata.isearlycheckin !== undefined ? formdata.isearlycheckin : isearlycheckin,
            mobilecheckinnoofadults: formdata.mobilecheckinnoofadults?.toString() ? formdata.mobilecheckinnoofadults : mobilecheckinnoofadults === 0 ? 1 : mobilecheckinnoofadults,
            mobilecheckinnoofchildren: formdata.mobilecheckinnoofchildren?.toString() ? formdata.mobilecheckinnoofchildren : mobilecheckinnoofchildren,
            mobilecheckinnoofpets: formdata.mobilecheckinnoofpets?.toString() ? formdata.mobilecheckinnoofpets : mobilecheckinnoofpets,
            noofserviceanimal: formdata.noofserviceanimal?.toString() ? formdata.noofserviceanimal : noofserviceanimal,
            earlycheckinfees: (formdata?.ischeckin === 0 && formdata?.isearlycheckin === 1) ? formdata?.earlycheckinfees : 0,
            earlycheckintimeid: (formdata?.ischeckin === 0 && formdata?.isearlycheckin === 1) ? formdata?.earlycheckintimeid : '',
            vehicleinformations: vehicles,
            vehicleimages: formdata.vehicleimages,
            screen: 'staydetails'
        }
        reqData['vehicleinfosubmit'] = (reqData.vehicleimages.length > 0 || reqData.vehicleinformations.length > 0) ? 1 : 0// heli 2/6/2023 4:53

        if (reqData.isearlycheckin === 1) {
            reqData['earlycheckinfees'] = formdata.earlycheckintimeid !== "" ? formdata.earlycheckinfees : earlycheckinfees
        }
        else if (reqData.isearlycheckin === 0) {
            reqData['estimatedcheckintime'] = formdata.estimatedcheckintime
        }
        // if (reqData.noofserviceanimal > 0) {
            reqData['serviceanimalquestion1'] =reqData.noofserviceanimal > 0? formdata.serviceanimalquestion1:0
            reqData['serviceanimalquestion2'] =reqData.noofserviceanimal > 0? formdata.serviceanimalquestion2:''
        // }

        let error = Object.assign({}, errors)

        for (let fields of getCurrentState().rightSidebarFormData[0].formfields) {
            if (!reqData[fields.field] && fields.required) {
                if (fields.field === 'serviceanimalquestion2') {
                    if (reqData.noofserviceanimal > 0) {
                        error[fields.field] = `${fields.errortext ? fields.errortext : fields.text} is required`
                    } else {
                        delete error[fields.field]
                    }

                } else {

                    error[fields.field] = `${fields.errortext ? fields.errortext : fields.text} is required`
                }
            } else {
                delete error[fields.field]
            }
        }
        setErrors(error)
        if (vehiclereqdata) {
            addData(vehiclereqdata, pagename, apipath, useraction, nextpage)
        } else if (!(formdata?.vehicleimages?.length <= 1 || !formdata?.vehicleimages)) {
            dispatch(setProps({ formdata: { ...getCurrentState().formData, vehicleimages: IISMethods.getLocalData('reservationDetails').vehicleimages[0] } }))
            IISMethods.notify("You can select only one image of vehicle", 2)
        }
        else if (Object.keys(error).length === 0) {

            if (formdata.isearlycheckin !== undefined) {
                if (formdata.isearlycheckin === 1) {
                    if (formdata.earlycheckintimeid !== "") {
                        reqData.earlycheckintimeid = formdata.earlycheckintimeid
                        addData(reqData, pagename, apipath, useraction, nextpage)
                    }
                    else if (formdata.earlycheckintimeid === "" || earlycheckintimeid === "") {
                        IISMethods.notify("Please select early checkin time ", 2)
                    }
                } else if (formdata.isearlycheckin === 0) {
                    addData(reqData, pagename, apipath, useraction, nextpage)
                }
            }
        }
    }




    //submit rate card
    const handleRateCard = async (nextpage, termsarray) => {

        let formData = IISMethods.getCopy(getCurrentState().formData)

        if (formData.chargespolicy.length === termsarray.length) {
            if (getCurrentState().formData._id) {
                const { pagename, apipath, useraction } = getCurrentState().rightSidebarFormData[0]
                let req = {}

                // let req = IISMethods.getCopy(getCurrentState().formData) // Heli 13/01/2024 12:13 PM => only pass necessary fields (id,screen,terms)
                req['screen'] = 'ratedetails'
                req['_id'] = formData._id
                req['chargespolicy'] = formData.chargespolicy || []
                await addData(req, pagename, 'mobilecheckin/update', useraction, nextpage)
                if (resBody.status === 200) {
                    await handleNextPage("paymentmethod", 'ratedetails',)
                }
            } else {
                if (resBody.status === 200) {
                    await handleNextPage("paymentmethod")
                }
            }
        } else {
            IISMethods.notify("You must agree with the terms and conditions ", 2)
        }
    }

    // submit hotlTnC
    const handleHotelTnC = async (signature, termsarray) => {

        const imageData = signature
        const { pagename, apipath, useraction } = getCurrentState().rightSidebarFormData[0]

        if (!signature.isEmpty()) {
            let formData = IISMethods.getCopy(getCurrentState().formData)
            if (termsarray?.length <= 0 || (termsarray?.length > 0 && (formData.hotelpolicy?.length === termsarray?.length))) {
                let reqData = {}
                reqData = {
                    _id: bookingid ? bookingid : "",
                    signature: imageData.getTrimmedCanvas().toDataURL('image/png'),
                    isupdate: 0,
                    hotelpolicy: formData.hotelpolicy || [],
                    screen: 'signatureverification'
                }
                await addData(reqData, pagename, apipath, useraction,)
                if (resBody.status === 200) {
                    await dispatch(setProps({ formData: IISMethods.getCopy(resBody.data) }))
                    await handleNextPage("successfullycompleted", 'signatureverification')
                }
            }
            else {
                IISMethods.notify("You must agree with the terms and conditions ", 2)
            }
        }
        else {
            IISMethods.notify("Please add your signature ", 2)
        }
    }

    //show/hide button loader
    const addLoaders = async (key, value) => {

        if (value === 1) getCurrentState().loadings[key] = value
        else delete getCurrentState().loadings[key]
        await props.setLoadings({ loadings: IISMethods.getCopy(getCurrentState().loadings) })
    }

    // jatin 20-feb-2023
    const handleFilterData = async (type, key, value) => {
        if (type === 'checkbox') {
            getCurrentState().filterData[key] = (value ? 1 : 0)
        }
        else if (type === 'number-input') {
            getCurrentState().filterData[key] = parseInt(value)
        }
        else if (type === 'timepicker' || type === 'datepicker') {
            getCurrentState().filterData[key] = value ? value : ''
        }
        else if (type === 'dropdown') {
            getCurrentState().filterData[key] = value
        }
        else if (type === 'multipleselectdropdown') {
            getCurrentState().filterData[key] = value
            if (value.length === 0) {
                let obj = IISMethods.getobjectfromarray(getCurrentState().fieldOrder, 'formdatafield', key)
                if (obj.onchangefill) {
                    for (const field of obj.onchangefill) {
                        let fieldobj = IISMethods.getobjectfromarray(getCurrentState().fieldOrder, 'field', field)
                        getCurrentState().filterData[fieldobj.formdatafield] = []
                    }
                }
            }
        }
        else if (type === 'checkpicker') {
            getCurrentState().filterData[key] = value
        }
        else if (type === 'daterangepicker') {
            getCurrentState().filterData[key] = value
        }
        else {
            getCurrentState().filterData[key] = value
        }
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData) })
    }

    return (
        <MasterView
            pagename={props.pagename}
            handleNextPage={handleNextPage}
            addData={addData}
            handlePagelimit={handlePagelimit}
            backtoPreviousPage={backtoPreviousPage}
            handleFormData={handleFormData}
            getMasterData={getMasterData}
            handleUserVerification={handleUserVerification}
            setFormData={setFormData}
            handleStaydetailClick={handleStaydetailClick}
            rightSidebarFormData={props.rightSidebarFormData}
            handleIdverification={handleIdverification}
            handleSelfVerification={handleSelfVerification}
            listData={listData}
            handleRegistration={handleRegistration}
            errors={errors}
            setErrors={setErrors}
            handleYourStayInfo={handleYourStayInfo}
            reservationDetail={props.reservationDetails}
            vehiclelist={vehiclelist}
            setVehiclelist={setVehiclelist}
            vehicleplaceholder={vehicleplaceholder}
            setVehicleplaceholder={setVehicleplaceholder}
            handleRateCard={handleRateCard}
            handleHotelTnC={handleHotelTnC}
            handleGrid={handleGrid}
            handleFilterData={handleFilterData}
            itemqty={itemqty}
            handleVerificationNextPage={handleVerificationNextPage}
            addLoaders={addLoaders}
        />
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload)),
    setLoadings: (payload) => dispatch(setLoadings(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(FormController)
