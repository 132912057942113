import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IISMethods, } from '../../config/IISMethods'
import TimePickerRsuite from '../Components/TimePickerRsuite'
import DatePickerRsuite from '../Components/DatePickerRsuite'
import { store } from '../../index'
import { setProps } from '../../redux/actions'
import Loader from '../Components/Loader'

const CheckIn = (props) => {

    const dispatch = useDispatch()
    const getCurrentState = () => store.getState()
    const checkinData = useSelector(state => state)
    const userData = IISMethods.getLocalData("welcomedata")

    const [isChecked, setIsChecked] = useState(false)
    const [agelimit, setAgelimit] = useState()
    const [terms, setTerms] = useState("")
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {

        setAgelimit(IISMethods.getLocalData('headerpropertyData')?.agelimit ? IISMethods.getLocalData('headerpropertyData')?.agelimit : 18)
        setTerms(
            IISMethods.getLocalData('headerpropertyData')?.mobilecheckindescription === '<p><br></p>'
                ?
                ''
                :
                IISMethods.getLocalData('headerpropertyData')?.mobilecheckindescription
                    ?
                    IISMethods.getLocalData('headerpropertyData')?.mobilecheckindescription
                    :
                    ''
        )
    }, [userData])

    const checkValidation = (key, value, storevalue) => {
        getCurrentState().formData[key] = value.trim()
        dispatch(setProps({ formData: getCurrentState().formData }))
    }

    const renderHtmlControls = (formfield) => {

        if (formfield && (formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue)) {
            if (formfield.type === 'input-text') {

                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className="label-form-control">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <input
                                disabled={formfield.disabled}
                                type="text"
                                defaultValue={getCurrentState().formData[formfield.field]}
                                className="form-control "
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                autoComplete="off"
                                placeholder={formfield.text}
                                onBlur={({ target }) => {
                                    checkValidation(formfield.field, target.value, true);
                                }}
                            />
                        </div>
                    </div>
                )
            }

            else if (formfield.type === 'timepicker') {
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className="label-form-control">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <TimePickerRsuite
                                readOnly={true}
                                InputProps={{ onKeyDown: e => e.preventDefault() }}
                                onKeyPress={e => e.preventDefault()}
                                className="form-control p-0 has-val" placeholder={`Enter ${formfield.text}`} format="HH:mm aa" id={"form-" + formfield.field}
                                name={formfield.field} value={IISMethods.stringToDate(checkinData.formData[formfield.field])} type="text" ranges={[]} showMeridian
                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                            />
                        </div>
                    </div>
                )
            }
            else if (formfield.type === 'datepicker') {
                let value = getCurrentState().formData[formfield.field] ? getCurrentState().formData[formfield.field] : ''

                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input" readOnly  >
                            <label className="label-form-control">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <DatePickerRsuite
                                className="form-control p-0 has-val"
                                placeholder={`Select ${formfield.text}`}
                                format={IISMethods.getDateFormatByProfile()}
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                value={value ? IISMethods.GetDT(IISMethods.GetISOdate(value)) : ''}
                                ranges={[]}
                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                disabled={checkinData.stepper === 1 ? formfield.disabled : false}
                                onClean={() => props.handleFormData(formfield.type, formfield.field, "")}
                            />
                        </div>
                    </div>
                )
            }
            else // for plain text
            {
                return <>{formfield.field}</>
            }
        }
    }

    var rightSidebarFormData
    var formData
    var userdetails
    var propertydata

    propertydata = useMemo(() => {
        return checkinData.reservationDetails?.property
    }, [checkinData.reservationDetails])  //IISMethods.getLocalData('headerpropertyData') //checkinData.reservationDetails.property
    try {
        rightSidebarFormData = getCurrentState().rightSidebarFormData
        formData = IISMethods.getCopy(checkinData.formData)
        userdetails = checkinData.reservationDetails
    }
    catch {
        userdetails = {}
        propertydata = {}
    }


    return (
        <>
            <section className="readyto-check-sec section-spacing pb-0">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className={`col-12 text-center`}>
                                {
                                    propertydata?.logo
                                        ?
                                        <div className='dayssuites-logo '>
                                            <img src={propertydata.logo} title={propertydata?.propertyname ? propertydata?.propertyname : "Not Found"} alt={'Property'} />
                                        </div>
                                        :
                                        <div className='mb-3'></div>
                                }
                            </div>
                            <div className="col-9 mx-auto text-center mb-3">
                                <div className="text-14 fw-500">
                                    <div className="text-gray"> {propertydata?.location?.address ? propertydata?.location?.address + ', ' : ""}

                                        {propertydata?.location?.city ? propertydata?.location?.city + ', ' : ""} {propertydata?.location?.state ? propertydata?.location?.state : ""} {propertydata?.location?.zipcode ? ` - ${propertydata?.location?.zipcode}.` : "-"}</div>
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <p className="welcome-text text-center mt-2 d-flex flex-column">
                                    <span className='welcome-bold-text text-dark' style={{ lineHeight: 1 }}>Let's Get You Checked-In </span>
                                    <span className='text-12'>Secured & Faster</span>
                                </p>
                            </div>
                            <div className="col-12 text-center mt-2">
                                <div className="chackonline-info info-data mb-2">
                                    <div className="readyto-check-desc">
                                        <p className="text-gray text-14">To authenticate your reservation, please provide the following details:</p>
                                    </div>
                                </div>
                            </div>
                            {
                                userdetails?.pmsconfirmationnumber &&
                                <div className="col-12 text-center">
                                    <div className="row">
                                        <div className="col-12 mx-auto">
                                            <div className="reservation-sec">
                                                <ul className="reservation-list">
                                                    <li><p className="font-weight-400 text-16">Reservation Id :  </p></li>
                                                    <li><p className="font-weight-600 text-16">&nbsp; {userdetails?.pmsconfirmationnumber}</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-12 ">
                                <div className="checkin-app-form">
                                    <div className="row">

                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}

                                        <div className="col-12 mb-3">
                                            <label className="checkbox checkbox-outline-primary mr-2 mt-1">
                                                <input type="checkbox" id="agree-checkbox" name="checkbox" onChange={() => { setIsChecked(!isChecked) }} />
                                                <span style={{ color: 'black' }}>I am {agelimit} years of age or older and have read and agreed to hotel's </span>
                                                <a
                                                    className='link-checkin'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        terms !== '' && props.handleGrid(true, 'modal', 'termsandcondition', 1)
                                                        setIsShow(!isShow)
                                                    }}
                                                >Terms & conditions
                                                </a>
                                                <span>.</span>
                                                <span className="checkmark-manual"  ></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary-dark mb-2"
                            onClick={(e) => {
                                (getCurrentState().loading === 0) &&
                                    props.handleUserVerification(e, isChecked, formData.lastname, formData.checkindate)
                            }}
                        >
                            <Loader size="sm" content={'Check-In Now'} />
                        </button>
                    </div>
                </div>
            </section>
            {
                isShow &&
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 fix-height-scroll-checkinview">
                            <div className="right-sidebar-content mt-0">
                                <span dangerouslySetInnerHTML={{ __html: terms ? terms : '' }} />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* <ModalRsuite
                className={'terms-condition'}
                size={"sm"}
                open={getCurrentState().modal.termsandcondition}
                onClose={() => {
                    props.handleGrid(false, 'modal', 'termsandcondition', 0)
                }}
                title={<div className="formnamelbl-checkin m-3 font-weight-600 text-20 ">Terms and Conditions</div>}
                body={
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 fix-height-scroll-checkinview">
                                <div className="right-sidebar-content mt-0">
                                    <span dangerouslySetInnerHTML={{ __html: terms ? terms : '' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                }           
            /> */}
        </>
    )
}

export default CheckIn