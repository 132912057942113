import React, { useEffect, useState, useLayoutEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Checkin from './view/MobileCheckIn/Checkin'
import Staydetail from './view/MobileCheckIn/Staydetail'
import IDVerification from './view/MobileCheckIn/IDVerification'
import SelfVerification from './view/MobileCheckIn/SelfVerification'
import YourRegistrationDetail from './view/MobileCheckIn/YourRegistrationDetail'
import YourStayInfo from './view/MobileCheckIn/YourStayInfo'
import RateCard from './view/MobileCheckIn/RateCard'
import PaymentMethod from './view/MobileCheckIn/PaymentMethod'
import HotelTnC from './view/MobileCheckIn/HotelTnC'
import SuccessfullyCompleted from './view/MobileCheckIn/SuccessfullyCompleted'
import { ToastContainer } from 'react-toastify'
import { Config, IISMethods, resBody, setReqParams } from './config/IISMethods'
import WelcomePage from './view/MobileCheckIn/WelcomePage'
import ErrorPage from './view/ErrorPage.js'
import { useDispatch } from 'react-redux'
import { setProps } from './redux/actions'
import Cookies from 'universal-cookie'
import { connect } from "react-redux"
import { store } from "./index"
import IDVerificationCamera from './view/MobileCheckIn/IDVerificationCamera'
import ModalRsuite from './view/Components/ModalRsuite'
import SelfVerificationCamera from './view/MobileCheckIn/SelfVerificationCamera'
// import MSClarity from './view/Components/MSClarity.js'
// import { clarity } from 'clarity-js'

import 'react-toastify/dist/ReactToastify.css' // for applaying style to toaster
// import './App.css'
import "./assets/css/bootstrap.min.css";
import "./assets/css/Root.Style.css";
import "./assets/css/Style.css";
import "./assets/css/Responsive.css";
// import { clarity } from 'react-microsoft-clarity';
// clarity.init(id);
// clarity.identify('USER_ID', { userProperty: 'value' });
// clarity.consent();
// clarity.setTag('key', 'value');
// clarity.upgrade('upgradeReason');
let loadingRing = () => {
    return <svg width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g className="btn-action-loader">
            <rect x="11" y="1" width="2" height="5" opacity=".14" fill="#072f54" />
            <rect x="11" y="1" width="2" height="5" transform="rotate(30 12 12)" opacity=".29" fill="#072f54" />
            <rect x="11" y="1" width="2" height="5" transform="rotate(60 12 12)" opacity=".43" fill="#072f54" />
            <rect x="11" y="1" width="2" height="5" transform="rotate(90 12 12)" opacity=".57" fill="#072f54" />
            <rect x="11" y="1" width="2" height="5" transform="rotate(120 12 12)" opacity=".71" fill="#072f54" />
            <rect x="11" y="1" width="2" height="5" transform="rotate(150 12 12)" opacity=".86" fill="#072f54" />
            <rect x="11" y="1" width="2" height="5" transform="rotate(180 12 12)" fill="#072f54" />
        </g>
    </svg>
}
function App() {

    const cookies = new Cookies();
    const dispatch = useDispatch()
    const path = window.location.pathname
    const [pageredirect, setPageredirect] = useState(0)

    let urlparams = new URLSearchParams(window.location.search)
    let subdomainname = urlparams?.get('bookingid')?.split('-')[0]
    let bookingid = urlparams?.get('bookingid')?.split('-')[1]
    let querystring = `?bookingid=${subdomainname}-${bookingid}`

    const msclarity = () => {
        (function (c, l, a, r, i, t, y) {
            c[a] =
                c[a] ||
                function () {
                    (c[a].q = c[a].q || []).push(arguments);
                };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "k7rz8yntww");

    }

    // useEffect(() => {
    //     const handleLoad = () => {
    //         // Perform actions after the component has fully loaded
    //         // console.log(' ===> ', 'loaded');
    //         // msclarity()
    //         // Get all the link elements in the head
    //         const linkElements = document.head.querySelectorAll('link');
    //         // Get the last link element
    //         const lastLinkElement = linkElements[linkElements.length - 1];

    //         const linkElement = document.createElement('link');
    //         linkElement.rel = lastLinkElement.rel;
    //         linkElement.href = lastLinkElement.href;

    //         // Find the last title element in the head
    //         const lastTitleElement = document.head.querySelector('title');

    //         // Insert the link element after the last title element
    //         if (lastTitleElement) {
    //             lastTitleElement.insertAdjacentElement('afterend', linkElement);
    //         } else {
    //         // If there is no title element, append the link element to the head
    //             document.head.appendChild(linkElement);
    //         }
    //         lastLinkElement.remove()            
    //     };
    //     window.addEventListener('load', handleLoad);
    //     return () => {
    //         window.removeEventListener('load', handleLoad);
    //     };
    // }, [])
    // clarity.consent()    

    // clarity.start({
    //     projectId: "k7rz8yntww",
    //     upload: 'https://m.clarity.ms/collect',
    //     track: true,
    //     content: true,
    // });

    // return () => {
    //     clarity.stop()
    // }
    // <MSClarity />
    // (function (c, l, a, r, i, t, y) {
    //     c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }; 
    //     t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
    //     y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    // })(window, document, "clarity", "script", "k59rff7fzp"); 
    // if (window.clarity) window.clarity('track', 'clarity')

    useEffect(() => {
        (async function () {

            let guestInfo = IISMethods.getCookiesData("mobilecheckin_loginInfo")
            if (!guestInfo || !guestInfo.querystring || (guestInfo.querystring !== querystring)) {
                await getAccessToken()
            } else if (path === "/") {
                await cookies.remove('mobilecheckin_loginInfo');
                await getAccessToken()
            } else if (path === '/hoteltnc') {
                setTimeout(() => {
                    getReservationDetails()
                }, 500)
            } else {

                await getReservationDetails()
            }
        })()
        return () => {
            localStorage.clear()
        }
    }, [])

    const getAccessToken = async () => {
        const url = Config.weburl + 'getaccesstoken'
        const reqData = {}
        let headers = {
            key: Config.APP_KEY,
            issuer: "website",
            'user-agent': window.navigator.userAgent
        }
        if (Config.encryptdata) { headers = { reqheader: IISMethods.encryptData(headers) } }

        await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback);

        var resp
        function successCallback(response) {
            if (Config.encryptdata) {
                resp = response || {}
                resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(response.data)))
                resp.headers = JSON.parse(JSON.parse(IISMethods.decryptData(response.headers.resheader)))
            }
            else { resp = response }
        }

        function errorCallback(error) {
            if (Config.encryptdata) {
                resp = error
                resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.data)))
                resp.headers = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.headers)))
            }
            else { resp = error }
        }

        if (resp.status === 200) {
            let obj = Object.assign({}, {})
            if (subdomainname && bookingid) {
                obj.subdomainname = subdomainname
                obj.bookingid = bookingid
                obj.querystring = querystring
                obj.token = resp.headers.token
                obj.unqkey = resp.data.data.unqkey
                obj.uid = resp.data.data.uid
                obj.step = 0
                IISMethods.setCookiesData("mobilecheckin_loginInfo", obj)
                await getUserData()
            }
        }
        else {
            localStorage.clear()
            cookies.remove('mobilecheckin_loginInfo');
        }
    }

    const getUserData = async () => {

        const reqData = { reservationid: bookingid }
        const url = Config.weburl + "mobilecheckin"

        await setReqParams(url, reqData)
        await IISMethods.addData("mobilecheckin", "welcome")

        if (resBody.status === 200) {
            const resdata=IISMethods.getCopy(resBody.data)

            let mobilecheckinlink = resdata?.mobilecheckinlink

            await dispatch(setProps({ reservationDetails: resdata }))
            await IISMethods.setLocalData("welcomedata", resdata)
            await IISMethods.setLocalData("reservationDetails", resdata) //JENIL 6/7/2023 9:18 PM 

            let cookiedata = IISMethods.getCookiesData('mobilecheckin_loginInfo')
            if (cookiedata) {
                cookiedata.companyid = resdata?.property?.companyid
            }

            IISMethods.setCookiesData('mobilecheckin_loginInfo', cookiedata)
            IISMethods.setLocalData('headerpropertyData', { ...resdata?.property })

            if (resdata?.ischeckin === 1 && resdata?.isupdate === 0) {
                setPageredirect(1)
                setTimeout(() => {
                    window.location.replace(mobilecheckinlink)
                }, 4000)
            }
        } else {
            localStorage.clear()
            cookies.remove('mobilecheckin_loginInfo');
        }
    }

    // api call for reservation and guset experience,homepage
    const getReservationDetails = async () => {


        const reqData = {
            paginationinfo: {
                pageno: 1,
                pagelimit: 20,
                filter: { _id: bookingid },
                sort: {}
            }
        }
        if (bookingid !== null) {
            const url = Config.weburl + "reservation"
            await setReqParams(url, reqData)
            await IISMethods.addData("reservation", "home")
        }

        if (resBody.status === 200) {
            //charmi(28-06-2023)(for redirection to guest experience)

            let mobilecheckinlink = resBody.data[0].mobilecheckinlink
            if (resBody.data[0].ischeckin === 1 && resBody.data[0].isupdate === 0) {
                setPageredirect(1)
                setTimeout(() => {
                    window.location.replace(mobilecheckinlink)
                }, 4000)
            }

            IISMethods.setLocalData("reservationDetails", resBody.data[0]) //JENIL 6/7/2023 9:18 PM 
            await dispatch(setProps({
                formData: resBody.data[0],
                reservationDetails: resBody.data[0]
            }))
        } else {
            localStorage.clear()
            cookies.remove('mobilecheckin_loginInfo');
        }
    }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Protected pageredirect={pageredirect}><Checkin /></Protected>} />
                    <Route exact path="/idverification" element={<Protected pageredirect={pageredirect}><IDVerification /></Protected>} />
                    <Route exact path="/selfverification" element={<Protected pageredirect={pageredirect}><SelfVerification /></Protected>} />
                    <Route exact path="/yourregistrationdetail" element={<Protected pageredirect={pageredirect}><YourRegistrationDetail /></Protected>} />
                    <Route exact path="/yourstayinfo" element={<Protected pageredirect={pageredirect}><YourStayInfo /></Protected>} />
                    <Route exact path="/ratecard" element={<Protected pageredirect={pageredirect}><RateCard /></Protected>} />
                    <Route exact path="/paymentmethod" element={<Protected pageredirect={pageredirect}><PaymentMethod /></Protected>} />
                    <Route exact path="/hoteltnc" element={<Protected pageredirect={pageredirect}><HotelTnC /></Protected>} />
                    <Route exact path="/successfullycompleted" element={<SuccessfullyCompleted />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </BrowserRouter>

            <ModalRsuite
                size={'xs'}
                open={pageredirect === 1}
                closeButton={false}
                body={
                    <>
                        <div className='d-flex flex-column align-items-center'>
                            <span className='text-center text-14 mb-1 font-weight-600 text-dark'>You Are Already Checked-In.</span>
                            <span className='text-center text-14 mb-3 font-weight-600 text-dark'>Redirecting to Guest Experience</span>
                            <span className='text-center'>{loadingRing()}</span>
                        </div>
                    </>
                }
            />

            <ToastContainer
                position='bottom-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state,
});

export default connect(mapStateToProps)(App);

const Protected = ({ children, pageredirect }) => {

    const getCurrentState = () => store.getState()

    let urlparams = new URLSearchParams(window.location.search)
    let subdomainname = urlparams?.get('bookingid')?.split('-')[0]
    let bookingid = urlparams?.get('bookingid')?.split('-')[1]
    let querystring = `?bookingid=${subdomainname}-${bookingid}`

    return getCurrentState().reservationDetails?.isupdate === 0 && !pageredirect ?
        <Navigate to={"/successfullycompleted" + querystring} />
        : children
}