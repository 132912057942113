import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, resBody, setReqParams } from '../config/IISMethods'
import { setLoading, setLoadings, setProps } from '../redux/actions'
import PaymentMethodView from '../view/MobileCheckIn/PaymentMethodView'
import html2canvas from 'html2canvas'
import axios from 'axios'

let initialCardState = { cardnumber: '', expdate: '', cardholdername: '', cvv: '' }

const PaymentMethodController = (props) => {
    const { backtoPreviousPage, errors, setErrors, rightSidebarFormData, handleNextPage } = props

    const getCurrentState = () => store.getState()

    const dispatch = useDispatch()
    let videoscannercanvas = useRef(null)
    const originalPhoto = React.useRef();
    const originalImgRef = useRef(null)
    const urlparams = new URLSearchParams(window.location.search)
    const bookingid = urlparams?.get('bookingid')?.split('-')[1]

    const [imgURL, setImgURL] = useState({});
    const [maskPos, setMaskPos] = useState({})
    const [flag, setFlag] = useState(0) //flag for display 2nd page
    const [pxObj, setPxObj] = useState({})
    const [blurCardImage, setBlurCardImage] = useState("")
    const [scancard, setScancard] = useState('')
    const [finalCardNum, setFinalCardNum] = useState("")
    const [flagDisplyCamera, setflagDisplyCamera] = useState(0)//0--->demo image,1-->live video-camera,2-->clicked snap
    const [reScanCard, setReScanCard] = useState(false)

    useEffect(() => {
        setErrors({})
    }, [])

    useEffect(() => {
        if (finalCardNum && blurCardImage && scancard) {//&& pxObj.yone - pxObj.xone > 0 condition for blur portion 
            handlenextPageEvent()
        }
    }, [blurCardImage])

    const handleScancardCapture = async (e) => {
        if (e) {
            setPxObj({ xzero: 0, yzero: 0, xone: 0, yone: 0 })
            var srcimg = e
            setBlurCardImage("")
            setScancard(srcimg)
        }
    }

    //form value prepare - onblut & onclick
    const handleFormData = async (type, key, value, fieldtype, fieldkey, fieldvalue) => {
        var rightSidebarFormData
        if (getCurrentState().modal.rightSidebar) {
            rightSidebarFormData = getCurrentState().rightSidebarFormData
        }

        else if (type === 'image' || type === 'file' || type === 'svg-file') {
            if (key === 'cardimage') {
                getCurrentState().formData[key] = value
            }
            else {
                getCurrentState().formData[key] = await IISMethods.fileToBase64(value)
            }
        }
        else if (type === 'datepicker') {
            if (value) {
                value.setDate(1)
            }
            getCurrentState().formData[key] = value ? IISMethods.ConvertDateObjTOISOString(IISMethods.setStartTimeOfDay(value)) : ''
        }
        else {
            getCurrentState().formData[key] = value
        }
        await dispatch(setProps({ formData: IISMethods.getCopy(getCurrentState().formData), reservationDetails: IISMethods.getCopy(getCurrentState().reservationDetails) }))

    }

    const handlenextPageEvent = () => {
        if (finalCardNum && blurCardImage && scancard) {
            return setflagDisplyCamera(0)
        } else {
            IISMethods.notify("Card not scanned properly.", 2)
            setReScanCard(true)
        }
    }

    //submit payment method page
    const handlePaymentMethod = async () => {
        const { apipath, useraction } = getCurrentState().rightSidebarFormData[0]
        if (getCurrentState().formData.cardnumber.length !== 19) {
            IISMethods.localnotify('Please Enter Valid Card Number.')
        }
        else {
            let reqData = {}
            let cardnumber = getCurrentState().formData?.cardnumber || ''
            let cardnumberbunch = cardnumber.split(' ')
            let cardblurnumber = cardnumberbunch.map((_, index) => index !== (cardnumberbunch.length - 1) ? '****' : _).join(' ')
            reqData = {
                _id: bookingid ? bookingid : "",
                cardnumber: cardnumber,
                cardblurnumber: cardblurnumber,
                cardholdername: getCurrentState().formData?.cardholdername ? getCurrentState().formData.cardholdername : "",
                // screen: pagename
            }
            if (getCurrentState().formData?.cardexpirydate) {
                reqData['cardexpirydate'] = getCurrentState().formData?.cardexpirydate ? getCurrentState().formData.cardexpirydate : ""
            }     
            let error = Object.assign({}, errors)
            if (!reqData.cardnumber || reqData.cardnumber.split(' ').join('').length !== 16) { 
                error.cardnumber = "Card number cannot get properly"
             }else  if(!getCurrentState().formData.cardexpirydate){
                IISMethods.localnotify('Expiry Date is required')
            } else{

                setErrors(error)
                if (Object.keys(error).length === 0) {
                    addData(reqData, 'paymentverificationsubmit', apipath, useraction)
                }
            }   
        }
    }

    // New function for add start
    const addData = async (reqData, pagename, apipath, useraction, nextpage = "") => {
      
        await dispatch(setLoading({ loading: 1 }))
        reqData['screen'] = reqData?.screen ? reqData?.screen : 'paymentverificationsubmit'
        const url = Config.weburl + apipath
        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, pagename)

        if (resBody.status === 200) {
            if (pagename === "paymentverificationsubmit") {
                dispatch(setProps({
                    reservationDetails: resBody.data
                }))
                IISMethods.setLocalData("reservationDetails", resBody.data)
                handleNextPage("hoteltnc", 'paymentverificationsubmit')
            }
            await dispatch(setLoading({ loading: 0 }))
        }
        else {
            IISMethods.notify(resBody.message, 2)
            await dispatch(setLoading({ loading: 0 }))
        }
    }

    //show/hide button loader

    const addLoaders = async (key, value) => {
        if (value === 1) getCurrentState().loadings[key] = value
        else delete getCurrentState().loadings[key]
        await props.setLoadings({ loadings: IISMethods.getCopy(getCurrentState().loadings) })
    }

    // ======================================================================
    const handleUploadImage = async (event) => {
        let file = event.target.files[0]
        await handleFileUpload(file)
        // let dataUrl = await IISMethods.resizeImageFile(file, { maxHW: 600 }) //await imgToBase64(file)
        // handleScanImage(dataUrl)
    }

    const handleScanImage = (imgurl) => {
        let img = new Image()
        img.onload = async function () {
            let imgWidth = img.width
            let imgHeight = img.height
            setImgURL({ url: imgurl, blurUrl: '' })
            await handleScanCard({ url: imgurl, width: imgWidth, height: imgHeight })
        }
        img.src = imgurl
    }

    const handleScanCard = async (imgData) => {
        if (!imgData.url) return;
        await addLoaders('imagescan', 1)

        let ciURL = imgData.url
        try {
            let response = await axios.post(
                `https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_VISION_API_KEY}`,
                { requests: [{ image: { content: ciURL.split('base64,')[1] }, features: [{ type: "TEXT_DETECTION" }] }] }
            );

            let fullTextAnnotation = response.data.responses[0].fullTextAnnotation
            let textAnnotations = response.data.responses[0].textAnnotations

            let scantext = response.data.responses[0].fullTextAnnotation?.text;
            let carddata = getCardDetails(scantext)

            if (carddata.cardnumber) {
                // setCardDatails(carddata)    
                getCardNumberPos(fullTextAnnotation, textAnnotations, carddata, imgData)
            }
            else {
                IISMethods.notify("Card not scanned properly", 2)
                setReScanCard(true)
                getCurrentState().rightSidebarFormData[0].formfields[0].disabled = false
                await props.setProps({ rightSidebarFormData: getCurrentState().rightSidebarFormData })
            }
            await addLoaders('imagescan', 0)
        }
        catch (error) {
            IISMethods.notify("Card not scanned properly", 2)
            setReScanCard(true)
            getCurrentState().rightSidebarFormData[0].formfields[0].disabled = false
            await props.setProps({ rightSidebarFormData: getCurrentState().rightSidebarFormData })
            console.error("Error processing image:", error);
            await addLoaders('imagescan', 0)
        }
    }

    const getCardNumberPos = (fullTextAnnotation, textAnnotations, carddata, imgData) => {
        let cardnumber = carddata.cardnumber || ''
        let expdate = carddata.expdate || ''
        let cardholdername = carddata.cardholdername || ''

        let imgId = document.getElementById('creditcardimg')

        let iw = imgId.clientWidth
        let ih = imgId.clientHeight

        let { height, width } = fullTextAnnotation.pages[0]

        let cardnumbers = cardnumber.split(' ')
        let cardPosA = textAnnotations.filter(cn => cn.description === cardnumbers[0])
        let cardPosB = textAnnotations.filter(cn => cn.description === cardnumbers[cardnumbers.length - 2]) // IISMethods.getobjectfromarray(textAnnotations, 'description', cardnumbers[cardnumbers.length - 2])

        let { x, y } = cardPosA[0].boundingPoly.vertices[0]
        let xone = cardPosB[cardPosB.length - 1].boundingPoly.vertices[2].x
        let yone = cardPosB[cardPosB.length - 1].boundingPoly.vertices[2].y

        let maskdata = {
            xzero: ((iw * x) / width).toFixed(2) - 5 + "px",//xzero
            yzero: ((ih * y) / height).toFixed(2) - 2 + "px",//yzero
            xone: ((iw * xone / width) - ((iw * x) / width) + 16).toFixed(2) + "px",//width
            yone: ((ih * yone / height) - ((ih * y) / height) + 8).toFixed(2) + "px",//height
        }
        setMaskPos(maskdata)
        setTimeout(async () => await handleHTMLtoCanvas({ el: originalImgRef.current, url: imgData.url, cardnumber: cardnumbers.join(' '), expdate, cardholdername }), 1)
    }

    const getCardDetails = (scantext) => {
        let cardRegEx = /(\d[ -]*?){13,19}/g;
        let expdateRegEx = /\b(0[1-9]|1[0-2])\/(?:[0-9]{4}|[0-9]{2})\b/g
        let numberRegEx = /[0-9]/

        // for card number
        let cardnumber = scantext.match(cardRegEx);
        let cardnumberindex = scantext.indexOf(cardnumber)
        let textlist = scantext.slice(cardnumberindex).split('\n')

        // for card holder name
        let cardholder = textlist.reverse().filter(text => text.split(' ').length >= 2 && !numberRegEx.test(text))

        // for card expiry date
        let cardexpdate = textlist.filter(text => expdateRegEx.test(text))

        let cardobj = initialCardState
        if (cardnumber.length && cardexpdate.length > 0) {
            cardobj['cardnumber'] = cardnumber[0]
            cardobj['expdate'] = cardexpdate[0].match(expdateRegEx)
            if (cardholder.length > 0) cardobj['cardholdername'] = cardholder[0]
        }

        return cardobj
    };

    // base64 to file
    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }



    const handleHTMLtoCanvas = async (data) => {
        let { pagename, apipath, useraction } = getCurrentState().rightSidebarFormData[0]
        let { el, cardnumber, expdate, cardholdername } = data
        let header = { useCORS: true, quality: 1, allowTaint: true }
        try {
            let canvas = await html2canvas(el, header)
            let img = { token: canvas.toDataURL("image/jpeg", 1.0) }
            let base64tofile = dataURLtoFile(img.token)
            let dataURL = await IISMethods.resizeImageFile(base64tofile)
            setImgURL({ ...imgURL, blurUrl: dataURL })
            await handleFormData('image', 'cardimage', dataURL)
            await handleFormData('input-text', 'cardnumber', cardnumber)
            if (expdate?.length) await handleFormData('input-text', 'cardexpirydate', expdate[0])
            await handleFormData('input-text', 'cardholdername', cardholdername)
            IISMethods.localnotify("Your card has been successfully scanned", 1)
            let reqData = { screen: 'paymentverification', _id: bookingid }
            await addData(reqData, pagename, apipath, useraction)
            await addLoaders('imagescan', 0)
        }
        catch (err) {
            console.log('error: ', err)
        }
    }

    const handleFileUpload = async (files) => {
        let { pagename, apipath  } = getCurrentState().rightSidebarFormData[0]
        await addLoaders('imagescan', 1)
        let url = Config.weburl + 'mobilecheckin/getpaymentcarddata'

        let useraction = 'update' + 'data'

        let formData = new FormData()
        formData.append('paymentcard', files)
        await setReqParams(url, formData)
        await IISMethods.updateData(useraction, true, true)
         
        if (resBody.status === 200) {
            if (resBody.data.cardnumber) {
                getCurrentState().rightSidebarFormData[0].formfields[1].disabled = false
                getCurrentState().formData.cardnumber = resBody.data.cardnumber || ''
                getCurrentState().formData.cardexpirydate = resBody.data.expirationdate || ''//date.reverse().join('/')
                getCurrentState().formData.cardholdername = resBody.data.cardholdername || ''
                await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
            }           
        }       
        await addLoaders('imagescan', 0)
        let reqData = { screen: 'paymentverification', _id: bookingid }
        await addData(reqData, pagename, apipath, useraction)

    }
    // ======================================================================

    return (
        <PaymentMethodView
            flag={flag}
            setFlag={setFlag}
            backtoPreviousPage={backtoPreviousPage}
            handleFormData={handleFormData}
            handlePaymentMethod={handlePaymentMethod}
            errors={errors}
            setErrors={setErrors}
            rightSidebarFormData={rightSidebarFormData}
            setScancard={setScancard}
            scancard={scancard}
            handleScancardCapture={handleScancardCapture}
            pxObj={pxObj}
            setPxObj={setPxObj}
            blurCardImage={blurCardImage}
            setBlurCardImage={setBlurCardImage}
            finalCardNum={finalCardNum}
            originalPhoto={originalPhoto}
            videoscannercanvas={videoscannercanvas}
            setflagDisplyCamera={setflagDisplyCamera}
            flagDisplyCamera={flagDisplyCamera}
            handleNextPage={handleNextPage}
            handleScanImage={handleScanImage}
            addLoaders={addLoaders}
            handleUploadImage={handleUploadImage}
            originalImgRef={originalImgRef}
            imgURL={imgURL}
            maskPos={maskPos}
            reScanCard={reScanCard}

            handleFileUpload={handleFileUpload}
        />
    )
}

const mapStateToProps = state => ({
    // ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload)),
    setLoadings: (payload) => dispatch(setLoadings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodController)

// export default connect()(PaymentMethodController)

// const mapStateToProps = state => ({
//     ...state
// })

// const mapDispatchToProps = dispatch => ({
//     setProps: (payload) => dispatch(setProps(payload))
// })


// export default connect(mapStateToProps, mapDispatchToProps)(FormController)


// const getPolicy = async () => {
//     const url = Config.weburl + 'property/policy'
//     const useraction = 'list' + 'property/policy'
//     let filter = {
//          policytypeid: '63b4249d67d1901d9dbdfbd4',
//          propertyid: getCurrentState().reservationDetails?.propertyid || IISMethods.getLocalData('reservationDetails')?.propertyid
//          };

//     Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
//         if (typeof (value) === 'number') {
//             if (value !== 0)
//                 filter[key] = value
//         }
//         else
//             filter[key] = value
//     })
//     const reqBody = {
//         paginationinfo: {
//             pageno: getCurrentState().pageNo,
//             pagelimit: getCurrentState().pagelimit,
//             filter: filter,
//             sort: getCurrentState().sortData
//         }
//     }
//     await setReqParams(url, reqBody)
//     await IISMethods.listData(useraction)
//     if (resBody.status === 200) {
//         if (resBody.data.length) {
//             IISMethods.setLocalData('reservationDetails', { ...getCurrentState().reservationDetails, description: resBody.data[0].decription })

//             getCurrentState().reservationDetails['description'] = resBody.data.length ? resBody.data[0].decription : []//heli_22_02_2023 6:28 P.M.(bcoz if no data exist that occurs error)
//         }

//     }
// }

// const downloadTxtFile = (line, l1, l2, l3) => {
//     const element = document.createElement("a");
//     const file = new Blob([`${line}`, `${JSON.stringify(textfromApi)}`],
//         { type: 'text/plain;charset=utf-8' });
//     element.href = URL.createObjectURL(file);
//     element.download = "./demo.txt";
//     document.body.appendChild(element);
//     element.click();
// }