import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { store } from '../..'
import { Config, IISMethods, resBody, setReqParams } from '../../config/IISMethods'
import { setProps } from '../../redux/actions'
import Loader from '../Components/Loader'
import Header from '../Header'

const RateCardView = (props) => {

    const getCurrentState = () => store.getState()

    const urlparams = new URLSearchParams(window.location.search) //getting data from querystring
    const bookingid = urlparams?.get('bookingid')?.split('-')[1]

    const [policyfordeposit, setPolicyfordeposit] = useState([])
    const [displaySubmitBtn, setDisplaySubmitBtn] = useState(false)

    useEffect(() => {
        (async function () {
            await props.setProps({ formData: {} })
            await getlist()
        })()

    }, [])

    const getlist = async () => {

        let tempformData = IISMethods.getCopy(getCurrentState().formData)
        let formData = (tempformData?._id && tempformData.firstname) ? tempformData : IISMethods.getLocalData('reservationDetails')
        const { mobilecheckinnoofadults, mobilecheckinnoofpets, serviceanimalquestion1, serviceanimalquestion2, noofserviceanimal, mobilecheckinnoofchildren, isearlycheckin, earlycheckintimeid, earlycheckinfees, estimatedcheckintime } = formData//(formData?._id && formData.firstname) ? formData : IISMethods.getLocalData('reservationDetails')

        const reqData = {
            _id: bookingid ? bookingid : "",
            isearlycheckin: isearlycheckin,
            mobilecheckinnoofadults: mobilecheckinnoofadults,
            mobilecheckinnoofchildren: mobilecheckinnoofchildren,
            mobilecheckinnoofpets: mobilecheckinnoofpets,
            noofserviceanimal: noofserviceanimal,

        }
        if (reqData.noofserviceanimal > 0) {
            reqData['serviceanimalquestion1'] = serviceanimalquestion1
            reqData['serviceanimalquestion2'] = serviceanimalquestion2

        }
        if (reqData.isearlycheckin === 1) {
            reqData['earlycheckintimeid'] = earlycheckintimeid
            reqData['earlycheckinfees'] = earlycheckinfees
        } else if (reqData.isearlycheckin === 0) {
            reqData['estimatedcheckintime'] = estimatedcheckintime
        }

        await props.setProps({ formData: IISMethods.getCopy(formData) })
        await getPolicy()
    }

    const getPolicy = async () => {

        const url = Config.weburl + 'property/policy'
        const useraction = 'list' + 'property/policy'

        let tempArr = []
        let filter = {
            formobilecheckin: 1,
            fortermsandcondition: 2,
            propertyid: getCurrentState().reservationDetails?.propertyid || IISMethods.getLocalData('reservationDetails')?.propertyid
        };

        Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0)
                    filter[key] = value
            }
            else
                filter[key] = value
        })
        const reqBody = {
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: getCurrentState().pagelimit,
                filter: filter,
                sort: getCurrentState().sortData
            }
        }
        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            if (resBody.data.length) {
                tempArr = resBody.data.map(obj => {
                    obj['tempMustAcceptTerms'] = 0
                    return obj
                })
                setPolicyfordeposit(tempArr)
            }
            setDisplaySubmitBtn(true)
        }
    }

    const handleDisplay = (label, text, divclass = "", spanclass = "", spanvalueclass = "") => {
        return <div className={`row ${divclass}`}>
            <div className="col-7 col-xs-7 col-sm-7 text-left"><span className={`r-hotelcharges-left text-blue ${spanclass}`}>{label}</span></div>
            <div className="col-5 col-xs-5 col-sm-5 text-right"> <span className={`r-hotelcharges-right text-blue ${spanvalueclass ? spanvalueclass : spanclass}`}>{text ? text : 0} </span></div>
        </div>
    }

    // BR 21-07-2023 05:55 PM ~
    const handleFormData = async (type, key, value) => {
        let formData = IISMethods.getCopy(getCurrentState().formData)
        if (type === 'multipleselectdropdown') {
            let cp = formData[key] || []
            let chargespolicy = IISMethods.getindexfromarray(cp, 'policyid', value.policyid)
            if (chargespolicy !== -1) getCurrentState().formData[key] = cp.filter(d => d.policyid !== value.policyid)
            else getCurrentState().formData[key] = cp.concat([value])
        }
        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
    }

    try {
        return (
            <>
                <Header backTo="yourstayinfo" title="Rate Details" backtoPreviousPage={props.backtoPreviousPage} />
                <section className="hotelcharges-sec section-spacing pb-4 inner-fixed-height">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="reviewhotel-info info-data">
                                <h1 className="main-title text-blue">Please review Hotel Charges</h1>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="review-hotelcharges">
                                                <div className="row">
                                                    {/*start 13/6/2023 5:15 manage data in array */}
                                                    {/* Bhautik 11-07-2023 01:48 AM ~ */}
                                                    {
                                                        getCurrentState().reservationDetails?.extendstaydatedetails?.length > 0 &&
                                                        getCurrentState().reservationDetails?.extendstaydatedetails?.map((obj, i) => {
                                                            return <div className='col-12 p-0'>
                                                                <div className="p-2 border border-radius-5 mb-3 ">

                                                                    {handleDisplay("Check-In Date", `${IISMethods.getDateFormat(obj?.checkindate, 12)}`, '', 'fw-700', 'fw-500')}
                                                                    {handleDisplay("Check-Out Date", `${IISMethods.getDateFormat(obj?.checkoutdate, 12)}`, '', 'fw-700', 'fw-500')}
                                                                    {
                                                                        (
                                                                            (obj?.isprepay === 2) &&
                                                                            (obj?.averagenightlyrate > 0 || obj?.totalroomrate > 0 || obj?.applicabletaxes > 0 || obj?.additionalchargesrate > 0 ||
                                                                                obj?.extraadultsfees > 0 || obj?.extraadultsfeestaxes > 0 ||
                                                                                obj?.extrachildrenfees > 0 || obj?.extrachildrenfeestaxes > 0 ||
                                                                                obj?.extrapetsfees > 0 || obj?.extrapetsfeestaxes > 0)
                                                                        ) &&
                                                                        <div className="row">
                                                                            <div className="col-12 mx-auto hr-line mt-3">
                                                                                <hr />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        obj?.isprepay === 2 &&
                                                                        <>
                                                                            {obj?.averagenightlyrate > 0 && handleDisplay("Average Nightly Rate / Night ", `$${obj?.averagenightlyrate}`)}{/** / Per Night */}
                                                                            {obj?.totalroomrate > 0 && handleDisplay("Total Room Rate", `$${obj?.totalroomrate} `)}
                                                                            {obj?.applicabletaxes > 0 && handleDisplay("Applicable Taxes", `$${obj?.applicabletaxes}`)}
                                                                            {obj?.additionalchargesrate > 0 && handleDisplay("Additional Charges", `$${obj?.additionalchargesrate}`)}

                                                                        </>
                                                                    }
                                                                    {(obj?.applicablefees > 0 && i === 0) && handleDisplay("Appliable Fees", `$${obj?.applicablefees}`, "")}
                                                                    {
                                                                        obj?.isprepay === 2 &&
                                                                        obj?.total > 0 && handleDisplay("Total", `$${obj?.total}`, "mb-2")
                                                                    }
                                                                    {
                                                                        (obj?.applicablefees > 0 || obj?.averagenightlyrate > 0 || obj?.totalroomrate > 0 || obj?.applicabletaxes > 0 || obj?.additionalchargesrate > 0 || obj?.total > 0) &&
                                                                        <div className="row">
                                                                            <div className="col-12 mx-auto hr-line mt-3">
                                                                                <hr />
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        <>
                                                                            {(obj?.earlycheckinfees > 0) && handleDisplay("Early Check-In", `$${obj?.earlycheckinfees}`)}
                                                                            {(obj?.extraadultsfees > 0) && handleDisplay("Extra Adults Charge", `$${obj?.extraadultsfees}`)}
                                                                            {(obj?.extraadultsfeestaxes > 0) && handleDisplay("Extra Adults Charge Taxes", `$${obj?.extraadultsfeestaxes}`)}
                                                                            {(obj?.extrachildrenfees > 0) && handleDisplay("Extra Children Charge", `$${obj?.extrachildrenfees}`)}
                                                                            {(obj?.extrachildrenfeestaxes > 0) && handleDisplay("Extra Children Charge Taxes", `$${obj?.extrachildrenfeestaxes}`)}
                                                                            {(obj?.extrapetsfees > 0) && handleDisplay("Pets Charge", `$${obj?.extrapetsfees}`)}
                                                                            {(obj?.extrapetsfeestaxes > 0) && handleDisplay("Pets Charge Taxes", `$${obj?.extrapetsfeestaxes}`)}
                                                                        </>
                                                                    }
                                                                    {
                                                                        (
                                                                            obj?.extraadultsfees > 0 || obj?.extraadultsfeestaxes > 0 ||
                                                                            obj?.extrachildrenfeestaxes > 0 || obj?.extrachildrenfees > 0 ||
                                                                            obj?.extrapetsfeestaxes > 0 || obj?.extrapetsfees > 0 ||
                                                                            obj?.earlycheckinfees > 0
                                                                        ) &&
                                                                        <div className="row">
                                                                            <div className="col-12 mx-auto hr-line mt-3">
                                                                                <hr />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {obj?.estimatedtotal > 0 && handleDisplay("Estimated Total", `$${obj?.estimatedtotal}`, "", "fw-700")}
                                                                    {obj?.carddeposit > 0 && handleDisplay("Refundable Security Deposit", `$${obj?.carddeposit}`, "", " fw-700")}
                                                                </div>


                                                            </div>

                                                        })
                                                    }
                                                    {/* End  13/6/2023 5:15 manage data in array */}


                                                </div>
                                            </div>
                                        </div>
                                            <div className="stay-apply text-center text-gray text-12">
                                                <p>*Additional charges during your stay may apply</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-blue fw-500 ">
                                            <div className="hotelcharges-desc desc-data mb-0">
                                                <div className="row">
                                                    {
                                                        policyfordeposit?.length > 0 &&
                                                        policyfordeposit.map((desc, i) => {
                                                            let formData = IISMethods.getCopy(getCurrentState().formData)
                                                            let cp = formData?.chargespolicy || []
                                                            let chargespolicy = IISMethods.getindexfromarray(cp, 'policyid', desc._id)
                                                            return (
                                                                <div className={`col-12 mb-2 `} >
                                                                    <label className={`checkbox checkbox-outline-primary hotel-stayterm mb-0`}>
                                                                        <input className={desc.mustaccept === 1 ? '' : 'd-none'} type="checkbox" id={desc._id} name={desc._id} checked={chargespolicy !== -1} onChange={() => desc.mustaccept === 1 && handleFormData('multipleselectdropdown', 'chargespolicy', { policyid: desc._id, policydescription: desc.decription })} />
                                                                        <span dangerouslySetInnerHTML={{ __html: desc.decription ? decodeURI(desc.decription) : '' }} />
                                                                        <span className={desc.mustaccept === 1 ? 'checkmark' : 'd-none'}></span>
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                {
                    displaySubmitBtn &&
                    <div className="row" >
                        <div className="col-12">
                            <div className="btn submit-btn btn-icon fixed-btn mb-4" onClick={() => { getCurrentState().loading === 0 && props.handleRateCard('paymentmethod', policyfordeposit.filter(obj => obj.mustaccept === 1)) }}>
                                <div className="mx-auto">
                                    <span className="d-inline-block mr-3"> {<Loader size="sm" content={'Agree & Continue'} />} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
    catch (e) {
        console.log('error', e)
        return <></>
    }
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RateCardView)