import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setProps } from '../redux/actions'
import IDVerificationCameraView from '../view/MobileCheckIn/IDVerificationCameraView'
import { IISMethods } from '../config/IISMethods'
import { store } from '../index'

const IdVerificationController = (props) => {
	 
	const getCurrentState = () => store.getState()
	
	const handleUploadImage = async (event) => {
		console.log('---')

		await props.addLoaders('imagescan', 1)
		let file = event.target.files[0]
		let dataUrl = await IISMethods.resizeImageFile(file) //await imgToBase64(file)
		await props.handleVerificationNextPage("idverificationcamera", null, { 'idphoto': dataUrl });//"documentverification"
		await props.addLoaders('imagescan', 0)
	}

	return (
		<IDVerificationCameraView
			idverificationimageURL={getCurrentState().reservationDetails?.idphoto}
			handleNextPage={props.handleNextPage}
			handleFormData={props.handleFormData}
			rightSidebarFormData={props.rightSidebarFormData}
			backtoPreviousPage={props.backtoPreviousPage}
			handleIdverification={props.handleIdverification}
			handleUploadImage={handleUploadImage}
			handleVerificationNextPage={props.handleVerificationNextPage}
		/>
	)
}


const mapStateToProps = state => ({
	...state
})

const mapDispatchToProps = dispatch => ({
	setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(IdVerificationController)

// const handleUploadImage = async (event) => {// comment Heli 15/12/2023 03:05
	//     await props.addLoaders('imagescan', 1)
	//     let file = event.target.files[0]
	//     let dataUrl = await IISMethods.resizeImageFile(file)
	// 	await handleFormData('image', 'idphoto', dataUrl)
	// 	await props.addLoaders('imagescan', 0)
	// }