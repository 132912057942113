import React from 'react'
import { store } from '../../index';
import Header from '../Header';
import { SVG } from '../../config/IISMethods';
import Loader from '../Components/Loader';

const SelfVerificationCameraView = (props) => {
    const urlparams = new URLSearchParams(window.location.search);
    const subdomainname = urlparams?.get("bookingid")?.split("-")[0];
    const bookingid = urlparams?.get("bookingid")?.split("-")[1];
    const search = `?bookingid=${subdomainname}-${bookingid}`;

    const getCurrentState = () => store.getState();  

    return (
        <>
            <Header
                backTo="selfverification"
                title="Self Verification"
                backtoPreviousPage={props.backtoPreviousPage}
                current={"selfverificationcamera"}
            />
            <section className="retake-sec section-spacing">
                <div className="text-center section-spacing-img">
                    <img src={getCurrentState().formData.selfphoto || props.selfverificationimageURL} />
                </div>
                <div className="col-12 mx-auto">
                    <div className="row">
                        <div className="mr-auto text-left">
                            <div className="sacn-link set-reteckbtn">
                                {/* {renderHtmlControls(rightSidebarFormData[0]?.formfields[0], "Retake")} */}
                                <input className='d-none' id={'selfverificationcamera'} type='file' capture="environment" accept="image/*" onChange={(e) => e.target.files.length > 0 && props.handleUploadImage(e)}/>
                                {getCurrentState().loadings.imagescan === 1
                                    ? <label><span className="retake-btn">{SVG.loadingRing({ color: '#757575' })}</span></label>
                                    : <label htmlFor={'selfverificationcamera'}><span className="retake-btn ">Retake</span></label>
                                }
                            </div>
                        </div>
                        <div className="ml-auto text-right">
                            <div className="sacn-link">
                                <div
                                    className="submit-btn d-flex justify-content-end setbtn-submit submit-btn-loding"
                                    onClick={(e) => {
                                        getCurrentState().loading === 0 &&
                                            props.handleSelfVerification(e);
                                    }}
                                >
                                    <Loader size="sm" content={"Submit"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SelfVerificationCameraView