export default class SVG {
    constructor() { }

    loadingRing = (style = {}, onClick) => {
        return <svg width={style.width || 24} height={style.height || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g className="btn-action-loader">
                <rect x="11" y="1" width="2" height="5" opacity=".14" fill={style.color || "#ffffff"} />
                <rect x="11" y="1" width="2" height="5" transform="rotate(30 12 12)" opacity=".29" fill={style.color || "#ffffff"} />
                <rect x="11" y="1" width="2" height="5" transform="rotate(60 12 12)" opacity=".43" fill={style.color || "#ffffff"} />
                <rect x="11" y="1" width="2" height="5" transform="rotate(90 12 12)" opacity=".57" fill={style.color || "#ffffff"} />
                <rect x="11" y="1" width="2" height="5" transform="rotate(120 12 12)" opacity=".71" fill={style.color || "#ffffff"} />
                <rect x="11" y="1" width="2" height="5" transform="rotate(150 12 12)" opacity=".86" fill={style.color || "#ffffff"} />
                <rect x="11" y="1" width="2" height="5" transform="rotate(180 12 12)" fill={style.color || "#ffffff"} />
            </g>
        </svg>
    }    
}