import React from 'react'
import selfiepng from 'assets/images/Selfie.png'
import { useNavigate } from 'react-router-dom'
import Header from '../Header'
import { IISMethods, SVG } from '../../config/IISMethods'
import { store } from '../../index'
import Loader from 'view/Components/Loader'

const SelfVerificationView = (props) => {

    const getCurrentState = () => store.getState()

    const history = useNavigate()
    const urlparams = new URLSearchParams(window.location.search)
    const subdomainname = urlparams?.get('bookingid')?.split('-')[0]
    const bookingid = urlparams?.get('bookingid')?.split('-')[1]

    const rightSidebarFormData = getCurrentState().rightSidebarFormData ? getCurrentState().rightSidebarFormData : props.rightSidebarFormData

    const handleNextPage = async (pagename = '') => {
        if (!IISMethods.getLocalData('bookingid') || !IISMethods.getLocalData('subdomainname') || !subdomainname || !bookingid) {
            await history(pagename)
            return IISMethods.setLocalData("pagename", pagename)
        }
        else {
            await props.handleVerificationNextPage("selfverificationcamera", "selfverification");
            await IISMethods.setLocalData("pagename", pagename)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    // Bhautik 17-07-2023 11:03 AM ~ for upload image
    const handleUploadImage = async (event) => {
        await props.addLoaders('imagescan', 1)
        let file = event.target.files[0]
        let dataUrl = await IISMethods.resizeImageFile(file) //await imgToBase64(file)
        await props.handleVerificationNextPage("", "selfverification", { 'selfphoto': dataUrl });
        await props.addLoaders('imagescan', 0)
    }
    const handleOpenUpload = () => {
        if (document.getElementById('selfverification')) document.getElementById('selfverification').click()
    }
    const renderHtmlControls = (formfield, btnname) => {
        try {
            if (formfield.type === "image") {
                return <>
                    <input className='d-none' id={'selfverification'} type='file' capture="user" accept="image/*" onChange={(e) => e.target.files.length > 0 && handleUploadImage(e)} />
                    {
                        getCurrentState().loadings?.imagescan === 1
                            ?
                            <label className={"btn submit-btn btn-icon mb-0"}><div className="mx-auto">{SVG.loadingRing()}</div></label>
                            :
                            getCurrentState().reservationDetails.selfphoto
                                ?
                                <label className={"btn submit-btn btn-icon mb-0"} onClick={() => handleNextPage('selfverificationcamera')}>
                                    <div className="mx-auto">
                                        <span className={"d-inline-block mr-3"}>{btnname}</span>
                                    </div>
                                </label>
                                :
                                <label htmlFor={'selfverification'} className={"btn submit-btn btn-icon mb-0"}>
                                    <div className="mx-auto">
                                        <span className={"d-inline-block mr-3"}>{btnname}</span>
                                    </div>
                                </label>
                    }
                </>
            }
        }
        catch { }
    }

    return (
        <>
            <Header backTo="idverification" title="Self Verification" backtoPreviousPage={props.backtoPreviousPage} current="selfverification" />
            <section className="self-verification-sec section-spacing pt-0" id='scanphoto'>
                <div className="row px-2">
                    <div className='bg-blue w-100 self-verification-inner'>
                        <div className="col-12">
                            <div className="photoyourself-desc desc-data text-center text-gray">
                                <p>
                                    <span className="text-blue fw-700 text-20">
                                        {
                                            (getCurrentState().formData?.selfphoto || getCurrentState().reservationDetails?.selfphoto)
                                                ?
                                                'Please Verify Your Selfie'
                                                :
                                                'Submit Your Selfie'

                                        }
                                    </span>
                                </p>{/**Share a photo of yourself. */}
                            </div>
                        </div>
                        <div className="col-12 pt-3">
                            {
                                (getCurrentState().formData?.selfphoto || getCurrentState().reservationDetails?.selfphoto)
                                    ?
                                    <div className="row set-data-id">
                                        <div className="text-center section-spacing-img">
                                            <img src={getCurrentState().formData?.selfphoto || getCurrentState().reservationDetails?.selfphoto} style={{ height: 180 }} />
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-6 col-xs-5 col-sm-4 col-md-3 col-lg-4 mx-auto p-0" onClick={handleOpenUpload}>
                                            <div className="select-document self-verification-info">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="selfverification-img">
                                                            <img src={selfiepng} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-11 mx-auto set-picuter">
                                <div className="photoyourself-desc desc-data text-left text-gray mt-2">
                                    <p>Please take a clear selfie of yourself that doesn't include :</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pb-3">
                        <div className="row">
                            <div className="col-10 m-auto">
                                <div className="selfverification-info text-gray">
                                    <ul className="selfverification-list">
                                        <li><p>Sunglasses, hats or other facial obstructions</p></li>
                                        <li><p>Other people (e.g. a family photo)</p></li>
                                        <li><p>Pictures of a picture</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {renderHtmlControls(rightSidebarFormData[0]?.formfields[0], "Take A Selfie Of Yourself")} */}
                    <input className='d-none' id={'selfverification'} type='file' capture="environment" accept="image/*" onChange={(e) => e.target.files.length > 0 && handleUploadImage(e)} />
                    <div className={`${(getCurrentState().formData.selfphoto || getCurrentState().reservationDetails?.selfphoto) ? 'col-6 px-1' : 'col-12'} mb-4`}>
                        <a className={`d-flex justify-content-center text-center btn-primary-light-paymentmethod  btn submit-btn ${getCurrentState().loadings?.imagescan === 1 && 'disabled'}`}
                            onClick={() => getCurrentState().loadings?.imagescan !== 1 && handleOpenUpload()}
                        >
                            {
                                getCurrentState().loadings?.imagescan === 1
                                    ?
                                    <span className='my-auto'>{SVG.loadingRing()}</span>
                                    :
                                    <i className="my-auto material-icons-round text-20">photo_camera</i>
                            }
                            <span className='my-auto ml-2 text-center'>{`${(getCurrentState().formData?.selfphoto || getCurrentState().reservationDetails?.selfphoto) ? 'Retake' : 'Take A Selfie Of Yourself'} `}</span>
                        </a>
                    </div>

                    {
                        (getCurrentState().formData?.selfphoto || getCurrentState().reservationDetails?.selfphoto) &&
                        <div className="col-6 px-1 mb-4">
                            <div className={`row m-auto d-flex justify-content-center`} style={{ position: 'relative' }}>
                                <div className={`text-center btn submit-btn paymentcard-submit-btn`}
                                    onClick={() => { getCurrentState().loading === 0 && props.handleSelfVerification() }}   >
                                    <Loader size="sm" content={'Submit'} />
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </section >

        </>
    )
}
export default SelfVerificationView



// const documentfile = selfVerificationData?.selfphoto
// const [photo, setPhoto] = useState(documentfile)
// const [flag, setFlag] = useState(0) //flag for display 2nd page

// const [tempphoto, setTempphoto] = useState('')

// var _URL = window.URL || window.webkitURL;


// useEffect(() => {
//     handleOpenCam('', flag)
//     // console.log('loc?.state?.goBack', loc?.state?.goBack)
// }, [flag, loc?.state?.goBack])

// useEffect(() => {
//     setFlag(0)
// }, [getCurrentState().formData?.selfphoto])

// useEffect(() => {
//     if (getCurrentState().formData?.selfphoto) {
//         setPhoto(getCurrentState().formData.selfphoto)
//     }
//     else if (IISMethods.getLocalData("reservationDetails").selfphoto !== "" && IISMethods.getLocalData("reservationDetails").selfphoto !== null) {
//         setPhoto(IISMethods.getLocalData("reservationDetails").selfphoto)
//     }
// }, [IISMethods.getLocalData("reservationDetails"), getCurrentState().formData.selfphoto])

// const handlePhotoCapture = async(target) => {
//     setFlag(1)

//     if (target.files) {
//         if (target.files.length !== 0) {
//             var width
//             var height
//             let img = new Image();
//             const file = target.files[0]
//             const newUrl = URL.createObjectURL(file)
//             img.onload = function () {                    
//                 width = (img.width)/2
//                 height = (img.height)/2
//                 console.log('first', img.width,img.height)
//                 var fileInput = false;
//                 if (target.files[0]) {
//                     fileInput = true;
//                 } 

//                 if (fileInput) {
//                     try {
//                         console.log('width', width)
//                         setTempphoto(target.files[0])
//                         Resizer.imageFileResizer(
//                             target.files[0],
//                             width,
//                             height,
//                             "JPEG", 60, 0,
//                             (uri) => {
//                                 const base64Image = uri
//                                 const yourBase64String = base64Image.substring(base64Image.indexOf(',') + 1);
//                                 const kb = ((yourBase64String.length * 6) / 8) / 1000;
//                                 console.log('kb', kb)
//                                 // setnewImage(uri);
//                                 // console.log('uri', uri)
//                                 _URL.revokeObjectURL(uri);
//                                 setPhoto(uri)
//                                 props.handleFormData('images', 'selfphoto', uri)

//                                 return uri
//                             },
//                             "base64"
//                         );


//                     } catch (err) {
//                         console.log(err);
//                     }
//                 }
//             };
//             img.src = newUrl;

//             // console.log('width convert', img.width,height)
//             setPhoto(newUrl)
//         }
//     }
// }

// const handleFirstPage = () => {
//     history({ pathname: `/selfverification`, search }, { state: { goBack: 1 } })
//     var ele = document.getElementById('scanphoto')
//     ele.classList.remove('d-none')
//     ele = document.getElementById('showphoto')
//     ele.classList.add('d-none')
//     return null
// }
// const handleSecondPage = () => { 
//     history({ pathname: `/selfverification`, search }, { state: { goBack: 2 } })
//     var ele = document.getElementById('scanphoto')
//     ele.classList.add('d-none')
//     ele = document.getElementById('showphoto')
//     ele.classList.remove('d-none')
//     return null
// }

// const handleOpenCam = (btn, btnflag) => {
//     if (photo && (btn === "Take A Photo Of Yourself" || btnflag === 1 || loc?.state?.goBack === 2)) {
//         handleSecondPage()
//     } else if (photo && (btn === "Take A Photo Of Yourself" || btnflag === 0 || loc?.state?.goBack === 1)) {
//         handleFirstPage()
//     }
//     else if (!photo) {
//         handleFirstPage()
//     }
// }

{/* page no-10 strat */ }
{/* <section className="selfphoto-retakesubmit section-spacing d-none" id="showphoto">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="scan-img text-center">
                                {photo &&
                                    <img src={photo}
                                        title={selfVerificationData?.property?.propertyname}
                                        alt={selfVerificationData?.property?.propertyname}
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-4 mr-auto text-left">
                                    <div className="sacn-link set-reteckbtn">
                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[0], "Retake")}
                                    </div>
                                </div>
                                <div className="col-4 ml-auto text-right">
                                    <div className="sacn-link">
                                        <div className="submit-btn d-flex justify-content-end setbtn-submit" onClick={() => { setFlag(1); getCurrentState().loading === 0 && props.handleSelfVerification() }}>
                                            {<Loader size="sm" content={'Submit'} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}