import React, { useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { store } from '../..'
import { Config, IISMethods, resBody, setReqParams } from '../../config/IISMethods'
import Loader from '../Components/Loader'
import DigitalSignature from './DigitalSignature'
import Header from '../Header'
import SignaturePad from 'react-signature-canvas'
import { setProps } from '../../redux/actions'
import ReservaionPDF from 'view/Components/ReservaionPDF'
import { useReactToPrint } from 'react-to-print'
import html2pdf from 'html2pdf.js';

const HotelTnCView = (props) => {
    const getCurrentState = () => store.getState()

    const storeData = useSelector(state => state)
    const [hotelTnCData, setHotelTnCData] = useState({})
    const [displaySubmitBtn, setDisplaySubmitBtn] = useState(false)
    const [policytermsandconditions, setPolicytermsandconditions] = useState([])

    useEffect(() => {
        (async function () {

            await getPolicy()
        })()
    }, [])

    const policyarray = useMemo(() => {
        return policytermsandconditions
    }, [policytermsandconditions])
    // console.log('getCurrentState().reservationDetail', getCurrentState().reservationDetails)

    useEffect(() => {

        if (Object.assign(props.reservationDetail).length > 0) {
            setHotelTnCData(props.reservationDetail)
        } else {
            setHotelTnCData(IISMethods.getLocalData('reservationDetails'))
        }

    }, [storeData])

    let sigPad = {}
    const clear = () => { sigPad.clear() }

    const { estimatedgrandtotal, carddeposit, lastname, firstname, cardnumber, checkoutdate, checkindate, estimatedtotal } = hotelTnCData// comment  change field paymentcardnumber to cardnumber Heli 09/10/2023 12:02

    const handleDisplay = (label, text, spanclass = "") => {
        return <div className="row">
            <div className="col-7 col-xs-7 col-sm-7 mb-1 text-left p-0"><span className={`text-blue stay-term-box-data ${spanclass}`}>{label}</span></div>
            <div className="col-5 col-xs-5 col-sm-5 mb-1 text-right p-0"> <span className={`text-blue stay-term-box-data ${spanclass}`}>{text ? text : "-"}</span></div>
        </div>
    }

    const getPolicy = async () => {
        console.log("...");

        const url = Config.weburl + 'property/policy'
        const useraction = 'list' + 'property/policy'
        let tempArr = []

        let filter = {
            formobilecheckin: 1,
            fortermsandcondition: 1,
            propertyid: getCurrentState().reservationDetails?.propertyid || IISMethods.getLocalData('reservationDetails')?.propertyid
        };

        Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0)
                    filter[key] = value
            }
            else
                filter[key] = value
        })
        const reqBody = {
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: getCurrentState().pagelimit,
                filter: filter,
                sort: getCurrentState().sortData
            }
        }
        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            let data = IISMethods.getCopy(resBody.data)
            if (data.length) {

                tempArr = data.map(obj => {
                    obj['tempMustAcceptTerms'] = 0
                    return obj
                })
            }
            setPolicytermsandconditions(tempArr)

            setDisplaySubmitBtn(true)
            // getCurrentState().formData.hotelpolicy = tempArr
            // await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
        }
    }

    // BR 21-07-2023 05:55 PM ~
    const handleFormData = async (type, key, value) => {
        let formData = IISMethods.getCopy(getCurrentState().formData)
        if (type === 'multipleselectdropdown') {
            let cp = formData[key] || []
            let hotelpolicy = IISMethods.getindexfromarray(cp, 'policyid', value.policyid)
            if (hotelpolicy !== -1) getCurrentState().formData[key] = cp.filter(d => d.policyid !== value.policyid)
            else getCurrentState().formData[key] = cp.concat([value])
        }
        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
    }

    const styles = {
        sigContainer: {
            width: " 100%",
            height: " 100%",
        },
        signpad: {
            width: "100%",
            height: "205px",
            border: "1px solid rgba(211,211,211,0.7)",
            marginTop: "8px",
            borderRadius: '5px'
        }
    }

    // for generate & save pdf | BR ~ 2024-01-20 ~ 11:27 AM
    const handlePrint = useReactToPrint({
        content: () => document.getElementById(`pdfgenerate`),//reportTemplateRef.current,
        onBeforePrint: () => {
            // getCurrentState().loadings['pdfselection'] = getCurrentState().?._id
            // return props.setLoadings({ loadings: getCurrentState().loadings })
        },
        onAfterPrint: () => {
            // delete getCurrentState().loadings['pdfselection']
            // return props.setLoadings({ loadings: getCurrentState().loadings })
        },
        // documentTitle: `${"Reservation_Detail_"}${IISMethods.getDateFormat(IISMethods.GetPropertyDateWithCurrentTime(), 12) + '_' + IISMethods.getTimeFormat(IISMethods.GetPropertyDateWithCurrentTime(), 12)}`
        documentTitle: `${"Reservation_Detail_"}`,
        print: async (printIframe) => {
          // Do whatever you want here, including asynchronous work
        //   await generateAndSavePDF(printIframe);
            // console.log('printIframe', printIframe)
            handleHTMLToFile(printIframe.contentDocument.documentElement)
        },

    })

    const handleHTMLToFile = (element) => {
        html2pdf(element, {
            margin: 10,
            filename: "example.pdf",
            image: { type: "none" }, // Exclude images from the PDF
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        }).then((pdf) => {
            // You can use the generated PDF object as needed
            console.log("Generated PDF:", pdf);
        });
    };

    return (
        <>
            <Header backTo="paymentmethod" title="Terms & Conditions" backtoPreviousPage={props.backtoPreviousPage} current="hoteltnc" />
            <section className="termsstay-sec section-spacing pb-4 inner-fixed-height">
                <div className="row">
                   
                    <div className="col-12 mb-2">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="info-data">
                                    <h1 className="main-title text-blue">Hotel Terms and Conditions</h1>
                                    <p className="termsconditions-desc text-gray">Please read and agree to the following.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-12">
                        <div className="row">
                            <div className="col-12 hr-line ">
                                <hr />
                            </div>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 text-blue fw-500 ">
                                <div className="hotelcharges-desc desc-data">
                                    {
                                        policyarray?.length > 0 &&
                                        policyarray.map((desc, i) => {
                                            let formData = IISMethods.getCopy(getCurrentState().formData)
                                            let cp = formData?.hotelpolicy || []
                                            let hotelpolicy = IISMethods.getindexfromarray(cp, 'policyid', desc._id)
                                            return (
                                                <div className={`col-12 mb-2 p-0`}>
                                                    <label className={`checkbox checkbox-outline-primary hotel-stayterm`}>
                                                        <input className={desc.mustaccept === 1 ? '' : 'd-none'} type="checkbox" id={desc._id} name={desc._id} checked={hotelpolicy !== -1} onClick={() => desc.mustaccept === 1 && handleFormData('multipleselectdropdown', 'hotelpolicy', { policyid: desc._id, policydescription: desc.decription })} />
                                                        <span dangerouslySetInnerHTML={{ __html: desc.decription ? desc.decription : '' }} />
                                                        <span className={desc.mustaccept === 1 ? 'checkmark' : 'd-none'}></span>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 electronic-signature-pad">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="electronicsign-area">
                                            <div className="electronicsign-title text-blue">
                                                <p className="mb-0">Electronic Signature Here</p>
                                            </div>
                                            <div className="electronicsign-desc">
                                                <p>Signature must match the one on your ID.</p>
                                            </div>
                                            <div style={styles.sigContainer} id='canvas' >
                                                <SignaturePad
                                                    canvasProps={{ style: styles.signpad }}
                                                    ref={(ref) => { sigPad = ref }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="submit-btn-sec set-clearbtn" onClick={() => clear()}>
                            <a className="btn submit-btn btn-sm-txt">Clear</a>
                        </div>
                    </div>
                </div>
            </section>
            {
                displaySubmitBtn &&
                <div className="col-12">
                    <button className="btn submit-btn btn-icon fixed-btn mb-4"
                        onClick={async () => {
                            // handlePrint()
                             (getCurrentState().loading === 0) && await props.handleHotelTnC(sigPad || '', policyarray.filter(obj => obj.mustaccept === 1))
                        }}//await trim();.getTrimmedCanvas().toDataURL('image/png')
                    >
                        <div className="mx-auto">
                            <div className="d-inline-block mr-3"><Loader size="sm" content={'Submit'} /></div>
                        </div>
                    </button>
                </div>
            }
            <div className="d-none">
                <ReservaionPDF
                    // handleCreditCardImage={props.handleCreditCardImage}
                    // handleFormData={props.handleFormData}
                    // setGeneratepdf={props.setFinalPrintData}
                    // handleGrid={props.handleGrid}
                    // handlePrint={props.handlePrint} 
                    persondetails={{
                        image: getCurrentState().formData.idphoto || '',
                        data: [
                            { 'key': 'No.', value: getCurrentState().formData.licenseorpassportnumber },
                            { 'key': 'Name', value: getCurrentState().formData.firstname + ' ' + getCurrentState().formData.lastname },
                            { 'key': 'Address', value: getCurrentState().formData.address },
                            { 'key': 'City', value: getCurrentState().formData.city },
                            { 'key': 'State', value: getCurrentState().formData.state },
                            { 'key': 'Country', value: getCurrentState().formData.country }
                        ]
                    }}
                />
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(HotelTnCView)

// <div className="col-12">
// <div className="card">
//     <div className="card-body">
//         <div className="review-hotelcharges fw-600">
//             <div className="col-12 p-0">

//                 {handleDisplay("Name", `${firstname} ${lastname}`, "text-capitalize")}  {/* heli_13/4/2023(7:22)*/}
//                 {handleDisplay("Check-In Date", IISMethods.getDateFormat(checkindate, 12))}
//                 {handleDisplay("Check-Out Date", IISMethods.getDateFormat(checkoutdate, 12))}
//                 {handleDisplay("Estimated Total", "$" + estimatedtotal, "fw-700")}
//                 {handleDisplay("Refundable Security Deposit", "$" + carddeposit, "fw-700")}
//             </div>
//         </div>
//     </div>
   
// </div>
// </div>