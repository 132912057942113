import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { store } from "../..";
import { IISMethods, SVG } from "../../config/IISMethods";
import Loader from "../Components/Loader";
import Header from "../Header";
import WebCam from 'react-webcam';


const IDVerificationCameraView = (props) => {

    const getCurrentState = () => store.getState();

    return (
        <>
            <Header
                backTo="idverification"
                title="ID Verification"
                backtoPreviousPage={props.backtoPreviousPage}
                current={"idverificationcamera"}
            />
            <section className="retake-sec section-spacing">
                <div className="text-center section-spacing-img">
                    <img src={getCurrentState().formData?.idphoto || props.idverificationimageURL} />
                </div>
                <div className="col-12 mx-auto">
                    <div className="row">
                        <div className="col-4 mr-auto text-left">
                            <div className="sacn-link set-reteckbtn">
                                <input className='d-none' id={'idverificationcamera'} type='file' capture="environment" accept="image/*" onChange={(e) => { e.target.files.length > 0 && props.handleUploadImage(e) }} />
                                {
                                    getCurrentState().loadings.imagescan === 1
                                        ? <label><span className="retake-btn">{SVG.loadingRing({ color: '#757575' })}</span></label>
                                        : <label htmlFor={'idverificationcamera'}><span className="retake-btn ">Retake</span></label>
                                }
                            </div>
                        </div>
                        <div className="col-4 ml-auto text-right">
                            <div className="sacn-link">
                                <div
                                    className="submit-btn d-flex justify-content-end setbtn-submit"
                                    onClick={(e) => {
                                        getCurrentState().loading === 0 &&
                                            props.handleIdverification(e);
                                    }}
                                >
                                    <Loader size="sm" content={"Submit"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default IDVerificationCameraView;
