import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Config, IISMethods } from '../../config/IISMethods'
import FormController from '../../Controller/FormController'
import { setProps } from '../../redux/actions'

const IDVerification = () => {
    const dispatch = useDispatch()
    const reservationDetails = IISMethods.getLocalData("reservationDetails")
    const  rightSidebarFormData= [
        {
            pagename: 'idverification',
            formname: 'idverification',
            useraction: 'mobilecheckin',
            apipath: 'mobilecheckin/update',
            alias: 'idverification',
            formfields: [
                {
                    'field': 'idphoto',
                    'text': 'Document File',
                    'type': Config.getHtmlcontorls()['image'],
                    'disabled': false,
                    'defaultvisibility': true,
                    'required': true,
                    'gridsize': Config.getGridclasses()['col-12']
                },

            ]
        },
    ]

    useEffect(() => {
        dispatch(setProps({
            reservationDetails: reservationDetails,
            rightSidebarFormData:rightSidebarFormData
        }))

    }, [])

    return <FormController pagename="idverification" rightSidebarFormData={rightSidebarFormData}/>
}

export default IDVerification


