import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Config, IISMethods } from '../../config/IISMethods'
import FormController from '../../Controller/FormController'
import { setProps } from '../../redux/actions'

const YourRegistrationDetail = (props) => {
    const reservationDetails = IISMethods.getLocalData("reservationDetails")
    const dispatch = useDispatch()
    const rightSidebarFormData = [
        {
            pagename: 'yourregistrationdetail',
            formname: 'yourregistrationdetail',
            useraction: 'mobilecheckin',
            apipath: 'mobilecheckin/update',
            formfields: [
                //0
                {
                    field: 'firstname',
                    text: 'First Name',
                    type: Config.getHtmlcontorls()['input-text'],
                    disabled: true,
                    defaultvisibility: true,
                    required: true,
                    gridsize: Config.getGridclasses()['col-12']
                },
                //1
                {
                    field: 'lastname',
                    text: 'Last Name',
                    type: Config.getHtmlcontorls()['input-text'],
                    disabled: true,
                    defaultvisibility: true,
                    required: true,
                    gridsize: Config.getGridclasses()['col-12']
                },
                //2
                {
                    field: 'guestemail',
                    text: 'Email Address',
                    errortext: 'Email',
                    type: Config.getHtmlcontorls()['input-text'],
                    disabled: false,
                    defaultvisibility: true,
                    required: false,
                    regex: Config.getRegex()['email'],
                    gridsize: Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-6'],
                },
                {
                    field: 'guestphoneno',
                    text: 'Guest Phone No.',
                    errortext: 'Phone No.',
                    type: Config.getHtmlcontorls()['intltelinput'],
                    disabled: false,
                    defaultvisibility: true,
                    required: false,
                    gridsize: Config.getGridclasses()['col-lg-4'] + ' ' + Config.getGridclasses()['col-md-6'],
                    regex: Config.getRegex()['phone']
                },
                {
                    field: 'address',
                    text: 'Address',
                    type: Config.getHtmlcontorls()['input-text'], // Config.getHtmlcontorls()['input-text'],
                    disabled: false,
                    defaultvisibility: true,
                    required: true,
                    onfill: ['country', 'state', 'city', 'zipcode'],
                    onchangefill: ['country', 'state', 'city', 'zipcode'],
                    gridsize: Config.getGridclasses()['col-sm-12']
                },
                // 05
                {
                    field: 'country',
                    required: false,
                    text: 'Country',
                    type: Config.getHtmlcontorls()['input-text'],
                    disabled: false,
                    defaultvisibility: true,
                    gridsize: Config.getGridclasses()['col-sm-12']
                },
                // 06
                {
                    field: 'state',
                    required: true,
                    text: 'State',
                    type: Config.getHtmlcontorls()['input-text'],
                    disabled: false,
                    defaultvisibility: true,
                    gridsize: Config.getGridclasses()['col-sm-12']
                },
                // 07
                {
                    field: 'city',
                    text: 'City',
                    required: true,
                    type: Config.getHtmlcontorls()['input-text'],
                    disabled: false,
                    defaultvisibility: true,
                    gridsize: Config.getGridclasses()['col-sm-12']
                },
                //05
                // {
                //  field: 'countryid',
                //  text: 'Country',
                //  type: Config.getHtmlcontorls()['dropdown'],
                //  disabled: false,
                //  defaultvisibility: true,
                //  required: true,
                //  gridsize: Config.getGridclasses()['col-12'],
                //  apipath: 'masters/country',
                //  masterdata: 'country',
                //  masterdatafield: 'country',
                //  formdatafield: 'country',
                //  cleanable: true,
                //  searchable: true,
                //  onchangefill: ['stateid'],
                //  onchangedata: ['countryid'],
                //     // 'inpagemasterdata': true,
                //     // 'staticfilter' : {'isactive':1},
                // },
                // {
                //  field: 'stateid',
                //  text: 'State',
                //  type: Config.getHtmlcontorls()['dropdown'],
                //  disabled: false,
                //  defaultvisibility: true,
                //  required: true,
                //  gridsize: Config.getGridclasses()['col-12'],
                //  apipath: 'masters/state',
                //  masterdata: 'state',
                //  masterdatafield: 'state',
                //  formdatafield: 'state',
                //  cleanable: true,
                //  searchable: true,
                //  onchangedata: ['stateid'],
                //  onchangefill: ['cityid'],
                //  dependentfilter: { 'countryid': 'countryid' },
                //  masterdatadependancy: true,
                //     // 'inpagemasterdata': true,
                //     // 'staticfilter' : {'isactive':1},
                // },
                // {
                //  field: 'cityid',
                //  text: 'City',
                //  type: Config.getHtmlcontorls()['dropdown'],
                //  disabled: false,
                //  defaultvisibility: true,
                //  required: true,
                //  gridsize: Config.getGridclasses()['col-lg-6'] + ' ' + Config.getGridclasses()['col-sm-6'],
                //  apipath: 'masters/city',
                //  masterdata: 'city',
                //  masterdatafield: 'city',
                //  formdatafield: 'city',
                //  cleanable: true,
                //     // 'onchangedata': ['cityid'],
                //  searchable: true,
                //  dependentfilter: { 'stateid': 'stateid' },
                //  masterdatadependancy: true
                // },

                //8
                {
                    field: 'zipcode',
                    text: 'Zipcode',
                    type: Config.getHtmlcontorls()['number-input'],
                    disabled: false,
                    defaultvisibility: true,
                    required: true,
                    gridsize: Config.getGridclasses()['col-lg-4'] + ' ' + Config.getGridclasses()['col-md-6'],
                },
                //9
                {
                    field: 'guestphoneno',
                    text: 'Guest Phone No.',
                    type: Config.getHtmlcontorls()['intltelinput'],
                    disabled: false,
                    defaultvisibility: true,
                    required: false,
                    gridsize: Config.getGridclasses()['col-lg-4'] + ' ' + Config.getGridclasses()['col-md-6'],
                    regex: Config.getRegex()['phone']
                }

            ]
        },
    ]

    useEffect(() => {
        dispatch(setProps({
            reservationDetails: reservationDetails,
            rightSidebarFormData
        })
        )
    }, [])


    return <FormController pagename="yourregistrationdetail" rightSidebarFormData={rightSidebarFormData} reservationDetail={reservationDetails} />
}

export default YourRegistrationDetail

