import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Header from '../Header'
import { IISMethods } from '../../config/IISMethods'
import { store } from 'index'

const SuccessfullyCompletedView = (props) => {

    const getCurrentState = () => store.getState()

    useEffect(() => {
        return () => {
            props.handleNextPage('/')
            return removeAllKeyFromLocalStorage()
        }
    }, [])

    const removeAllKeyFromLocalStorage = () => {
        IISMethods.clearLocalData('pagename')
        IISMethods.clearLocalData('reservationDetails')
        IISMethods.clearLocalData('sampleImages')
    }

    const propertyData = useSelector(state => state?.reservationDetails?.property) || IISMethods.getLocalData('headerpropertyData')
    const user = useSelector(state => state?.reservationDetails)

    const handleDisplay = (label, text, spanclass = "") => {
        return (text && text !== '') && <div className="row">
            <div className="col-7 col-xs-7 col-sm-7 mb-1 text-left p-0"><span className={`text-blue stay-term-box-data ${spanclass}`}>{label}</span></div>
            <div className="col-5 col-xs-5 col-sm-5 mb-1 text-right p-0"> <span className={`text-blue stay-term-box-data ${spanclass}`}>{text ? text : "-"}</span></div>
        </div>
    }

    return (
        <div className='successfull-body'>
            <div className='position-relative'>
                
                <div className="col-12 mx-auto">                    
                        <div className=" dayssuites-logo document-image text-white top-13" style={{ top:6,fontWeight:100}}>
                            <i class="material-icons-round top-13"  style={{ top:6,fontWeight:100}}>
                                task_alt
                            </i>
                        </div>
                </div>
                <Header backTo="hoteltnc" bgclass="btn-success" title="Check-In Completed" backtoPreviousPage={props.backtoPreviousPage} current="successfullycompleted" />
            </div>
            <section className="stay-details-sec section-spacing pb-4 successfull-section">
                <div className="row">

                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="review-hotelcharges fw-600">
                                    <div className="col-12 p-0">
                                        {handleDisplay("Reservation ID", getCurrentState().reservationDetails?.pmsconfirmationnumber)}
                                        {handleDisplay("Name", `${getCurrentState().reservationDetails?.firstname} ${getCurrentState().reservationDetails?.lastname}`, "text-capitalize")}  {/* heli_13/4/2023(7:22)*/}
                                        {handleDisplay("Room No.", getCurrentState().reservationDetails?.roomno)}
                                        {handleDisplay("Check-In Date", IISMethods.getDateFormat(getCurrentState().reservationDetails?.checkindate, 12))}
                                        {handleDisplay("Check-Out Date", IISMethods.getDateFormat(getCurrentState().reservationDetails?.checkoutdate, 12))}
                                        {handleDisplay("Estimated Total", "$" + getCurrentState().reservationDetails?.estimatedtotal, "fw-700")}
                                        {handleDisplay("Refundable Security Deposit", "$" + getCurrentState().reservationDetails?.carddeposit, "fw-700")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 mx-auto">
                                <div className="successfulcheckin-desc text-center text-blue fw-600">
                                    <p className='text-18 text-green mb-1'>{`Thank You, ${user?.firstname}`}</p>
                                    <div>
                                        <span className='text-14 d-block'>You have successfully completed</span>
                                        <span className='text-14 d-block'>precheck-in formalities.</span>
                                        <span className='text-14 d-block'>We look forward to  your arrival.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className='hotel-content'>
                            <p className="text-blue fw-600 mb-1 text-16">Hotel Address : </p>
                            <div className="text-14 fw-500">
                                <div className="text-gray"> {propertyData?.location?.address ? propertyData?.location?.address + ', ' : ""}</div>
                                <div className="text-gray"> {propertyData?.location?.city ? propertyData?.location?.city + ', ' : ""} {propertyData?.location?.state ? propertyData?.location?.state + ', ' : ""} {propertyData?.location?.zipcode ? propertyData?.location?.zipcode : "-"}</div>
                                <div className="d-flex mt-2 redirect-btn">
                                    <a target='_blank' className='btn-redirect-map text-decoration-none' href={`http://maps.google.com/?q=${propertyData?.location?.address}`}>
                                        <i class="material-icons-round">location_on</i>
                                    </a>
                                    <a className='btn-redirect-phone text-decoration-none' href={'tel:' + `${propertyData?.contactno}`}>
                                        <i class="material-icons-round">local_phone</i>
                                    </a>
                                    <a className='btn-redirect-phone text-decoration-none' href={'sms:' + `${propertyData?.contactno}`}>
                                        <i class="material-icons-round">markunread</i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SuccessfullyCompletedView