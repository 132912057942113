import React, { useEffect, useState } from 'react'
import 'react-intl-tel-input/dist/main.css';
import IntlTelInput from 'react-intl-tel-input'
import { Config, IISMethods, JsCall, } from '../../config/IISMethods'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import SelectPickerRsuite from '../Components/SelectPickerRsuite'
import { setProps } from '../../redux/actions';
import Header from '../Header';
import Loader from '../Components/Loader';
import { store } from '../..';
import AddressAutoComplete from '../Components/AddressAutoComplete';
import Nextarrow from '../../assets/images/Nextarrow.svg'

// import GoogleAutoComplete from './Components/AddressAutoComplete';

const YourRegistrationDetailView = (props) => {
    const loc = useLocation()
    const getCurrentState = () => {
        return store.getState()
    }
    const disptach = useDispatch()
    const registrationData = useSelector(state => state)
    const [state, setState] = useState("")


    var reservationDetails = registrationData?.reservationDetails ? registrationData?.reservationDetails : IISMethods.getLocalData("reservationDetails")
    var rightSidebarFormData = props.rightsidebarformdata
    var preContact = registrationData.reservationDetails[rightSidebarFormData[0]?.formfields[3]?.field] ? registrationData.reservationDetails[rightSidebarFormData[0]?.formfields[3]?.field] : ""

    const checkValidation = (fieldobj, value, text) => {
        let key = fieldobj.field

        let error = Object.assign({}, props.errors)
        if (!value && fieldobj.required) {
            error[key] = ` ${text} is required `
        }
        else {
            if (JsCall.validateForm({ [key]: value }, registrationData.rightSidebarFormData, key)) {
                error[key] = `${text} is not in proper format`
            } else {
                delete error[key]

            }

            if (text) {
                setTimeout(() => {
                    let a = { ...state, [key]: value };
                    setState(a);
                }, 1);
            } else {
                delete error[key]
            }
        }
        props.setErrors(error)
        disptach(setProps({ reservationDetails: { ...reservationDetails, [key]: value } }))
    }

    const renderHtmlControls = (formfield) => {

        if (formfield && (formfield.defaultvisibility || registrationData?.formData[formfield.condition?.field] === formfield.condition?.onvalue)) {
            if (formfield.type === 'input-text') {
                return (
                    <div className="form-group">
                        {['firstname', 'lastname'].includes(formfield.field) && <label className="label-form-control">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>}

                        <input
                            onChange={(e) => {
                                checkValidation(formfield, e.target.value, formfield.text);
                                props.handleFormData(formfield.type, formfield.field, e.target.value)
                            }}
                            id={"form-" + formfield.field}
                            type="text"
                            disabled={formfield.disabled}
                            name={formfield.field}
                            className="form-control"
                            placeholder={"Enter " + formfield.text}
                            value={getCurrentState().formData[formfield.field] || registrationData?.reservationDetails[formfield.field]}
                            required={formfield.required} />
                        {
                            props.errors[formfield.field] &&
                            <span className='text-danger error'>{props.errors[formfield.field]}</span>
                        }
                    </div>
                )
            }
            else if (formfield.type === 'address-suggestion') // for address-suggestions
            {
                return (
                    <div className="form-group textarea-input">
                        <AddressAutoComplete
                            placeholder={`Select ${formfield.text}`}
                            className="form-control"
                            onChangeLocation={(text, location) => {
                                props.handleFormData(formfield.type, formfield.field, text, formfield.type, formfield.field, location)
                            }}
                            fieldkey={formfield.field}
                            defaultvalue={getCurrentState().formData[formfield.field] ? IISMethods.getCopy(getCurrentState().formData)[formfield.field] : registrationData?.reservationDetails[formfield.field] ? registrationData?.reservationDetails[formfield.field] : ""}
                            formfield={formfield}
                            placement={'auto'}
                        />
                        {
                            props.errors[formfield.field] &&
                            <span className='text-danger error'>{props.errors[formfield.field]}</span>
                        }
                    </div>
                )
            }

            else if (formfield.type === 'input-textarea') {
                return (
                    <div className="form-group">
                        <textarea
                            onBlur={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                            onChange={({ target }) => checkValidation(formfield, target.value, formfield.text)}
                            id={"form-" + formfield.field}
                            type="text"
                            disabled={formfield.disabled}
                            name={formfield.field}
                            rows='3'
                            className="form-control"
                            placeholder={"Enter " + formfield.text}
                            defaultValue={registrationData?.reservationDetails[formfield.field]}
                            required={true} />
                        {
                            props.errors[formfield.field] &&
                            <span className='text-danger error'>{props.errors[formfield.field]}</span>
                        }
                    </div>
                )
            }
            else if (formfield.type === 'number-input') {
                return (
                    <div className="form-group">
                        <input
                            type="number"// heli_13/4/2023(7:22)
                            pattern="[0-9]*"
                            min="0"
                            className="form-control p-0"
                            id={"form-" + formfield.field}
                            name={formfield.field}
                            onKeyPress={(e) => {
                                if (!(e.which >= 48 && e.which <= 57)) {
                                    e.preventDefault()
                                }
                            }}
                            disabled={formfield.disabled}
                            value={getCurrentState().formData[formfield.field] ? getCurrentState().formData[formfield.field] : registrationData?.reservationDetails[formfield.field]}
                            onChange={({ target }) => { checkValidation(formfield, target.value, formfield.text); props.handleFormData(formfield.type, formfield.field, target.value) }}
                            placeholder={"Enter " + formfield.text}
                        />
                        {
                            formfield.field === "zipcode" && props.errors[formfield.field] &&
                            <span className='text-danger error'>{props.errors[formfield.field]}</span>
                        }


                    </div>
                )
            }
            // else if (formfield.type === 'dropdown') {
            //     const masterdatakey = formfield.storemasterdatabyfield || props.inPageMasterData ? formfield.field : formfield.masterdata
            //     var inpagemasterdata = formfield.inpagemasterdata
            //     const masterRightSidebarFormData = inpagemasterdata ? formfield.masterdata : []
            //     const masterRights = inpagemasterdata ? IISMethods.getPageRights(masterRightSidebarFormData[0]?.alias) : {}
            //     inpagemasterdata = (masterRights.alladdright || masterRights.selfaddright)
            //     return (
            //         <div className={formfield.gridsize} key={formfield+"-formfield"}>
            //             <div className="form-group validate-input">
            //                 {/* <label className="label-form-control">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label> */}
            //                 <SelectPickerRsuite
            //                     cleanable={formfield.cleanable}
            //                     // searchable={formfield.searchable}
            //                     placeholder={`Select ${formfield.text}`}
            //                     className="form-control p-0"
            //                     data={getCurrentState().masterData[masterdatakey]}
            //                     id={ formfield.field}
            //                     name={formfield.field}
            //                     onChange={(e) => {
            //                         checkValidation(formfield, e, formfield.text); 
            //                         props.handleFormData(formfield.type, formfield.field, e)}}
            //                     // value={formData[formfield.field]}
            //                 value={registrationData?.reservationDetails[formfield.field] ? registrationData?.reservationDetails[formfield.field] : ""}

            //                     // defaultValue={getCurrentState().formData[formfield.field]}
            //                     masterFormName={!props.inPageMasterData && inpagemasterdata ? masterRightSidebarFormData[0]?.formname : ''}
            //                     //  comment by chetan - 29-07
            //                     onAdd={async () => {
            //                         await props.setProps({
            //                             masterRightSidebarFormData: masterRightSidebarFormData,
            //                             fieldobjectformasterdata: formfield
            //                         })
            //                         await props.setMasterFormData()
            //                     }}
            //                 />
            //             </div>
            //         </div>
            //     )
            // }
            else if (formfield.type === "dropdown") {
                const masterdatakey = formfield.storemasterdatabyfield || props.inPageMasterData ? formfield.field : formfield.masterdata

                return (
                    <div className="form-group">
                        <SelectPickerRsuite
                            cleanable={true}
                            searchable={formfield.searchable}
                            placeholder={`Select ${formfield.text}`}
                            className="form-control p-0"
                            data={registrationData.masterData[masterdatakey]}
                            id={formfield.field}
                            name={formfield.field}
                            onChange={(e) => {
                                checkValidation(formfield, e, formfield.text);
                                props.handleFormData(formfield.type, formfield.field, e)
                            }}
                            value={getCurrentState().formData[formfield.field] ? getCurrentState().formData[formfield.field] : registrationData?.reservationDetails[formfield.field] ? registrationData?.reservationDetails[formfield.field] : ""}
                        />
                        {props.errors[formfield.field] && <span className='text-danger error'>{props.errors[formfield.field]}</span>}

                    </div>
                )
            } else if (formfield.type === "intltelinput") {
                return (
                    <>
                        <IntlTelInput
                            fieldId={'form-' + formfield.field}
                            id={'form-' + formfield.field}
                            name={formfield.field}
                            disabled={formfield.disabled}
                            formatOnInit={false}
                            preferredCountries={[registrationData?.reservationDetails?.guestcountryid ? registrationData?.reservationDetails?.guestcountryid : registrationData?.formData.guestcountryid ? registrationData?.formData.guestcountryid : IISMethods.getLocalData('reservationDetails').guestcountryid ? IISMethods.getLocalData('reservationDetails').guestcountryid : Config.countryid]}
                            inputClassName="form-control mt-1"
                            placeholder={`Enter Contact No.`}
                            defaultValue={preContact}
                            separateDialCode={true}
                            onSelectFlag={(num, country) => {
                                props.handleFormData('intl-flagchange', ["guestcountrycode", "guestcountryid", "guestcountryname", formfield.field], [country.dialCode, country.iso2, country.name, num])
                            }}
                            onPhoneNumberChange={(status, value, countryData, number, id) => {
                                let error = Object.assign({}, props.errors)
                                if (!value) {
                                    if (formfield.field === "guestphoneno") {
                                        error[formfield.field] = `Mobile number is required`
                                    }
                                    else {
                                        error[formfield.field] = ` ${formfield.text} is required `
                                    }
                                } else if (!value.match(new RegExp(formfield.regex))) {
                                    error[formfield.field] = `Enter valid Mobile number`
                                }
                                else {
                                    delete error[formfield.field]
                                }
                                let numberRegex = /^\d+$/;
                                if (numberRegex.test(value) || !value) {
                                    props.setErrors(error)
                                    props.handleFormData(formfield.type, formfield.field, value)
                                }

                            }}
                        />
                        {props.errors[formfield.field] && <span className='text-danger error'>{props.errors[formfield.field]}</span>}
                    </>
                )
            }
            else return <>{formfield.field}</>
        }
    }

    const add_update_label = `Verify & Update`

    return (
        <>
            <Header backTo="selfverification" title="Registration Details" backtoPreviousPage={props.backtoPreviousPage} current="yourregistrationdetail" />
            <section className="detailsaboutyou-sec section-spacing pb-4 inner-fixed-height">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="detailsabout-info info-data">
                                    <h1 className="main-title text-blue">Details About You</h1>
                                    <div className="detailsabout-desc">
                                        <p className="text-blue text-capitalize">{`${add_update_label} missing details about yourself`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 detailaboutyou-form pb-0">
                        <div className="row">
                            <div className="col-12 p-0">
                                <form>
                                    <div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    {/* <label className="label-form">Please Confirm If We Got Your Name Correctly</label> */}
                                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                                </div>
                                                <div className="col-6">
                                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 mx-auto hr-line mt-2">
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>

                                        {/* contact number */}
                                        <div className="col-12 detailaboutyou-form pb-0">
                                            <div className="row">
                                                <div className="col-12 mx-auto">
                                                    <div className="form-group">
                                                        <label className="label-form text-capitalize">{`Please ${add_update_label} Your Contact Information`}</label>
                                                        {renderHtmlControls(rightSidebarFormData[0]?.formfields[3])}
                                                    </div>
                                                </div>
                                                <div className="col-12 mx-auto">
                                                    {/* email */}
                                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[2])}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 mx-auto hr-line mt-2">
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 detailaboutyou-form pb-0">
                                            <div className="row">
                                                <div className="col-12 mx-auto">

                                                    {/* address */}
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12 mx-auto p-0">
                                                                <label className="label-form text-capitalize">{`Please ${add_update_label} Your Address`}</label>
                                                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[4])}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mx-auto">
                                                        <div className="row">

                                                            <div className="col-6 text-left pl-0">
                                                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[7])}
                                                            </div>
                                                            <div className="col-6 text-right pr-0">
                                                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[6])}
                                                            </div>
                                                            <div className="col-6 text-left pl-0">
                                                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[8])}
                                                            </div>
                                                            <div className="col-6 text-right pr-0">
                                                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[5])}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="col-12">
                <div className="btn submit-btn btn-icon fixed-btn mb-4" onClick={() => { getCurrentState().loading === 0 && props.handleRegistration() }}>
                    <div className="mx-auto">
                        <div className="d-inline-block d-flex gap-14 mr-3">
                            <Loader size="sm" content={'Next'} />{/**Stay Information */}
                            <i class="material-icons-round">
                                east
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default YourRegistrationDetailView

