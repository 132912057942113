import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setProps } from '../../redux/actions'
import FormController from '../../Controller/FormController'
import { Config, } from '../../config/IISMethods'

const Checkin = () => {
    // const checkinData = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setProps({
            formData:{},
            rightSidebarFormData: [
                {
                    pagename: 'checkin',
                    formname: 'Check In',
                    useraction: 'mobilecheckin',
                    apipath: 'mobilecheckin/verify',
                    alias: 'checkin',
                    formfields: [
                        {
                            'field': 'lastname',
                            'text': 'Enter Your Last Name',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12']
                        },
                        {
                            'field': 'checkindate',
                            'text': 'Check-in Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12']
                        },
                    ]
                },
            ]
        }))        
    }, [])

    return <FormController pagename="checkin" backpage={false}/>
}
export default Checkin


