import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IISMethods } from '../../config/IISMethods'
import FormController from '../../Controller/FormController'
import { setProps } from '../../redux/actions'

const HotelTnC = () => {
    const dispatch = useDispatch()
    const reservationDetails = IISMethods.getLocalData("reservationDetails")

    useEffect(() => {
        dispatch(setProps({
            formData:{},
            rightSidebarFormData: [
                {
                    pagename: 'hoteltnc',
                    formname: 'hoteltnc',
                    useraction: 'mobilecheckin',
                    apipath: 'mobilecheckin/update',
                    alias: 'hoteltnc',
                    formfields: []
                },
            ]
        }))

    }, [])
    return <FormController pagename="hoteltnc" reservationDetail={reservationDetails} />
}

export default HotelTnC