import React, { useEffect, useState } from 'react'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { usePlacesWidget } from 'react-google-autocomplete'
import { AutoComplete } from 'rsuite';
import { GoogleApiWrapper } from 'google-maps-react';

const API_KEY = 'AIzaSyDnROQJW4hFTvFdFbfGrIUr7ux6thEV_44'

const GoogleAutoComplete = (props) => {
	const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = useGoogle({ apiKey: API_KEY, });
	const { ref } = usePlacesWidget({
		apiKey: API_KEY,
		// onPlaceSelected: (place) =>
		// {
		// 	console.log('place ====>', place);
		// },
		options: { types: ["(regions)"], componentRestrictions: { country: "india" }, },
	});

	const [value, setValue] = useState();

	const setAddress = (place, placevalue) => {
		let zipcode = ""
		let country
		let state
		let city
		let address
		let cityIndex
		let fulladdress = place.formatted_address

		place.address_components.map((obj, index) => {
			if (obj.types.includes('postal_code')) {
				zipcode = obj.long_name
			}
			else if (obj.types.includes('country')) {
				country = obj.long_name
			} else if (obj.types.includes('administrative_area_level_1')) {
				state = obj.long_name
			} else if (obj.types.includes('locality') || obj.types.includes('administrative_area_level_3')) {
				city = obj.long_name
			}
		})
		address = place.formatted_address
		cityIndex = address.indexOf(city)
		address = address.slice(0, cityIndex).trim().replace(/,\s*$/, "")
		let location = { fulladdress, country, state, city,  zipcode }
		// props.handleFormData(props.formfield.type, props.formfield.field, placevalue, '', ["country", "state", "city", "zipcode"], [country, state, city, zipcode])
		props.onChangeLocation(placevalue, location)
	}

	const onSelect = async (placeId, placeValue) => {
		const placesService = await new props.google.maps.places.PlacesService(document.createElement("div"));
		let place;
		placesService.getDetails({ placeId: placeId }, results => {
			place = results;
			if (!place.geometry) {
				return;
			}
			setAddress(place, placeValue)
		});
	}

	return (
		<AutoComplete
			ref={ref}
			// className="font-weight-600"
			menuAutoWidth={500}
			placement={props.placement || 'auto'}
			// defaultValue={props.defaultvalue}
			id={'form-' + props.fieldkey}
			placeholder={props.placeholder || 'Search Location'}
			data={isPlacePredictionsLoading ? ['Loading'] : placePredictions.map(item => item.description)}
			value={value !== undefined ? value : props.defaultvalue}
			onSelect={(val) => {
				const tempArr = placePredictions.filter(item => item.description === val)
				onSelect(tempArr[0].place_id, val)
			}}
			onChange={(place) => {
				// console.log('place', place)
				getPlacePredictions({ input: place })
				setValue(place);
				props.onChangeLocation(place,)

			}}
		/>
	)
}

export default GoogleApiWrapper({ apiKey: API_KEY })(GoogleAutoComplete);
// export default GoogleAutoComplete({
//   apiKey: API_KEY
// })(GoogleAutoComplete);
// export default GoogleAutoComplete