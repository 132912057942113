import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Header from '../Header'
import { isBefore } from 'date-fns'
import { store } from '../../index'
import Loader from '../Components/Loader'
import './demostyle.css'
import DatePickerRsuite from 'view/Components/DatePickerRsuite'
import { IISMethods } from 'config/IISMethods'

let loadingRing = (style = {}, onClick = {}) => {
    return <svg width={style?.width || 24} height={style?.height || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g className="btn-action-loader">
            <rect x="11" y="1" width="2" height="5" opacity=".14" fill={style?.color || "#ffffff"} />
            <rect x="11" y="1" width="2" height="5" transform="rotate(30 12 12)" opacity=".29" fill={style?.color || "#ffffff"} />
            <rect x="11" y="1" width="2" height="5" transform="rotate(60 12 12)" opacity=".43" fill={style?.color || "#ffffff"} />
            <rect x="11" y="1" width="2" height="5" transform="rotate(90 12 12)" opacity=".57" fill={style?.color || "#ffffff"} />
            <rect x="11" y="1" width="2" height="5" transform="rotate(120 12 12)" opacity=".71" fill={style?.color || "#ffffff"} />
            <rect x="11" y="1" width="2" height="5" transform="rotate(150 12 12)" opacity=".86" fill={style?.color || "#ffffff"} />
            <rect x="11" y="1" width="2" height="5" transform="rotate(180 12 12)" fill={style?.color || "#ffffff"} />
        </g>
    </svg>
}

const PaymentMethodView = (props) => {

    const getCurrentState = () => store.getState()

    useEffect(() => { props.setflagDisplyCamera(0); }, [])

    const rightSidebarFormData = useSelector(state => state.rightSidebarFormData)

    const renderHtmlControls = (formfield) => {
        if (formfield && (formfield.defaultvisibility)) {
            if (formfield.type === 'input-text') {
                let value = getCurrentState().formData[formfield.field] || ''
                if (formfield.field === 'cardnumber' && value) {
                    let cardnumber = value.slice(-4)
                    value = (formfield.disabled) ? `**** **** **** ${cardnumber}` : getCurrentState().formData[formfield.field]
                }
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group">
                            <label className="label-form">{formfield.text}</label>
                            <input onChange={(e) => {
                                if (formfield.inputfor === 'cardnumber') {
                                    let val = e.target.value.replace(/\D/g, '').trim();
                                    val = val.replace(/(\d{4})/g, '$1 ');
                                    if (16 >= val.replaceAll(' ', '').length) props.handleFormData(formfield.type, formfield.field, val.trim())
                                }                                
                            }}
                                id={"form-" + formfield.field}
                                type="text"
                                disabled={formfield.disabled}
                                name={formfield.field}
                                className="form-control"
                                placeholder={"Enter " + formfield.text}
                                value={value}
                                required={true}
                            />
                            {
                                props.errors[formfield.field] &&
                                <span className='text-danger error'>{props.errors[formfield.field]}</span>
                            }
                        </div>
                    </div>
                )
            }
            else if (formfield.type === 'datepicker') {
                let value = getCurrentState().formData[formfield.field] || ''
                if (value) {
                    value = IISMethods.GetDT(IISMethods.GetISOdate(value))
                }

                let options = {
                    disabledDate: (date) => {
                        let currentDate = new Date()
                        currentDate.setDate(1)
                        currentDate.setMonth(currentDate.getMonth() + 1)
                        return isBefore(date, currentDate)
                    }
                }

                return <div className={formfield.gridsize}>
                    <div className="form-group">
                        <label className="label-form">{formfield.text}</label>
                        <DatePickerRsuite
                            className="form-control p-0"
                            placeholder={`Enter ${formfield.text}`}
                            format="MM/yy"
                            id={'form-' + formfield.field}
                            name={formfield.field}
                            value={value}
                            type="text"
                            ranges={[]}
                            disabled={formfield.disabled}
                            onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                            {...options}
                        />
                    </div>
                </div>
            }
        }
    }

    const clickevent = () => {
        props.setflagDisplyCamera(0)
    }

    const handleOpenUpload = () => {
        if (document.getElementById('paymentcard'))
            document.getElementById('paymentcard').click()
    }

    return (
        <>
            <Header backTo={`${props.flagDisplyCamera === 1 ? 'paymentmethod' : 'ratecard'}`} title="Add Payment Method" backtoPreviousPage={props.backtoPreviousPage} current={`${props.flagDisplyCamera === 1 ? 'paymentmethodcamera' : 'paymentmethod'}`} clickevent={clickevent} />

            <section className={`retake-sec1 section-spacing pt-0`} id="card-section">
                <div className="row">
                    <div className="col-12 mt-4 overflow-hidden">
                        <div className="row border border-radius-5" id="card-container">
                            <div style={{ opacity: 0 }}>
                                <div ref={props.originalImgRef} className='position-absolute'>
                                    <img
                                        id="creditcardimg"
                                        src={props.imgURL.url}
                                        style={{ position: 'relative', }}
                                    />
                                    <div style={{
                                        width: `${props.maskPos.xone}`,
                                        height: `${props.maskPos.yone}`,
                                        position: 'absolute',
                                        background: 'black',
                                        top: `${props.maskPos.yzero}`, left: `${props.maskPos.xzero}`
                                    }}></div>
                                </div>
                            </div>
                            <div className="col-12 text-19 font-weight-600 text-light-blue my-3">
                                Add Card Details
                            </div>
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}

                            <div className="col-12">
                                <div className="scancard-desc desc-data text-blue fw-600">
                                    <p>We use your card to verify your identity, prevent fraud, and submit card information to the hotel for authorization charges and other incidentals</p>
                                </div>
                            </div>
                            <input className='d-none' id={'paymentcard'} type='file' capture="environment" accept="image/*" onChange={props.handleUploadImage} />

                            <div className={`${getCurrentState().formData.cardnumber?.length === 19 ? 'col-7 px-1' : 'col-12'} mb-4`}>
                                <div className={`row m-auto d-flex justify-content-center`} style={{ position: 'relative' }}>
                                    <a className={`d-flex justify-content-center text-center btn-primary-light-paymentmethod  btn submit-btn ${getCurrentState().loadings?.imagescan === 1 && 'disabled'}`}
                                        onClick={() => getCurrentState().loadings?.imagescan !== 1 && handleOpenUpload()}
                                    >
                                        
                                        {
                                            getCurrentState().loadings?.imagescan === 1
                                                ?
                                                <span className='my-auto'>{loadingRing()}</span>
                                                :
                                                <i className="my-auto material-icons-round text-20">photo_camera</i>
                                        }
                                        <span className='my-auto ml-2 text-center'>{`${(props.reScanCard || getCurrentState().formData.cardnumber?.length === 19) ? 'Rescan' : 'Scan'} your card`}</span>
                                    </a>
                                </div>
                            </div>

                            {
                                getCurrentState().formData.cardnumber?.length === 19 &&
                                <div className="col-5 px-1 mb-4">
                                    <div className={`row m-auto d-flex justify-content-center`} style={{ position: 'relative' }}>
                                        <div className={`text-center btn submit-btn paymentcard-submit-btn`}
                                            onClick={() => { getCurrentState().loading === 0 && props.handlePaymentMethod() }}   >
                                            <Loader size="sm" content={'Submit'} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PaymentMethodView