import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IISMethods } from '../../config/IISMethods'
import FormController from '../../Controller/FormController'
import { setProps } from '../../redux/actions'

const RateCard = () => {
    const reservationDetails = IISMethods.getLocalData("reservationDetails")
    const dispatch = useDispatch()
    const rightSidebarFormData = [
        {
            pagename: 'ratecard',
            formname: 'Rating Card',
            useraction: 'mobilecheckin',
            apipath: 'mobilecheckin/verify',
            alias: 'ratecard',
            formfields: []
        },
    ]
    useEffect(() => {
        dispatch(setProps({
            reservationDetails: reservationDetails,
            rightSidebarFormData
        }))
    }, [])

    return <FormController pagename="ratecard" rightSidebarFormData={rightSidebarFormData} reservationDetail={reservationDetails} />
}

export default RateCard