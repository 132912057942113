import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setProps } from '../redux/actions'
// import { IISMethods } from '../config/IISMethods'
import { store } from '../index'
import SelfVerificationCameraView from '../view/MobileCheckIn/SelfVerificationCameraView'
import { IISMethods } from '../config/IISMethods'

const SelfVerificationController = (props) => {
    const urlparams = new URLSearchParams(window.location.search);
    const subdomainname = urlparams?.get("bookingid")?.split("-")[0];
    const bookingid = urlparams?.get("bookingid")?.split("-")[1];
    const search = `?bookingid=${subdomainname}-${bookingid}`;

	const getCurrentState = () => store.getState()
    
	const handleFormData = async (type, key, value) => {
		if (type === 'image') {
			getCurrentState().formData[key] = value
		}
		await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
	}

	const handleUploadImage = async (event) => {
        await props.addLoaders('imagescan', 1)
        let file = event.target.files[0]
        let dataUrl = await IISMethods.resizeImageFile(file)
		await handleFormData('image', 'selfphoto', dataUrl)
		await props.addLoaders('imagescan', 0)
	}

    return (
        <SelfVerificationCameraView
            selfverificationimageURL={getCurrentState().reservationDetails.selfphoto}			
            handleNextPage={props.handleNextPage}
            rightSidebarFormData={props.rightSidebarFormData}
            handleFormData={props.handleFormData}
            backtoPreviousPage={props.backtoPreviousPage}
            handleSelfVerification={props.handleSelfVerification}
			handleUploadImage={handleUploadImage}
            addLoaders={props.addLoaders}
        />
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(SelfVerificationController)