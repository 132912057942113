import React, { useEffect, useMemo, useRef } from 'react'
import { IISMethods } from 'config/IISMethods';
import Cards from 'react-credit-cards';
import 'react-credit-cards/src/styles.scss';

const CreditCardView = (props) => { 
    const cardRef = useRef(null)
    
    // const [cardType, setCardType] = useState(null)

    useEffect(() => {
        handleHtmlUpdate()
    }, [props.number])


    const handleHtmlUpdate = () => {
        let documnets = document.querySelector('.rccs__number')
        if (documnets.innerText) {
            let dtext = documnets.innerText.split(' ')
            dtext = dtext.map((_, index) => index !== (dtext.length - 1) ? '****' : _)
            documnets.innerHTML = dtext.join(' ')
        }
    }

    const cardInfo = useMemo(() => {
        let number = ''
        let expdate = ''
        let holdername = ''
        if (props.number && props.expdate && props.holdername) {
            number = props.number.replaceAll(' ', '')
            if (props.secure) number = '************' + number.slice(-4)
            if (props.expdate) { expdate = IISMethods.getMYFormat(props.expdate) }
            holdername = props.holdername
        }
        return {
            number,
            expdate,
            holdername,
            cvv: ''
        }
    }, [props.number, props.expdate, props.holdername])

    return (        
        <Cards
            ref={cardRef}
            number={cardInfo.number}
            expiry={cardInfo.expdate}
            name={cardInfo.holdername}
            cvc={cardInfo.cvv}
            { ...props.options }
        />
    )
}

export default CreditCardView