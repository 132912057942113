import _Config from './Config'
import _SVG from './SVG'
import _JsCall from '../view/JsCall'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Buffer } from 'buffer'
import { toast } from 'react-toastify'
import imageCompression from 'browser-image-compression'

import * as CryptoJS from 'crypto-js'

window.Buffer = Buffer

export var Config = new _Config()
export var JsCall = new _JsCall()
// Bhautik 10-04-2023 10:41 AM
export var SVG = new _SVG()

export var url
export var reqHeaders
export var reqBody
export var resHeaders
export var resBody
export const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const pagelimit = [
    {
        "label": 20,
        "value": 20,
    },
    {
        "label": 50,
        "value": 50,
    },
    {
        "label": 100,
        "value": 100,
    },
    {
        "label": 500,
        "value": 500,
    },
    {
        "label": 1000,
        "value": 1000,
    }
]

export async function setReqParams(URL, reqbody) {
    url = URL
    reqBody = reqbody
}

//Image Types charmi(16-02-2023)
// export const allowdedW9File = [".png", ".jpg", ".jpeg", ".pdf"]
export const allowdedImage = [".png", ".jpg", ".jpeg", ".gif"]
export const allowdedSVG = [".svg"]
export const allowdedAudio = [".mp3", ".flac", '.alac', '.pcm', '.wav', '.wma', '.aac', '.aiff']
export const allowdedVideo = [".mov", ".wmv", '.avi', '.mp4', '.avchd', '.flv', '.f4v', '.swf', '.mkv', '.webm']
export const allowdedDocs = [".doc", ".docx", ".pdf", ".xlsx", ".pptx"]
export const allowdedAll = [".doc", ".svg", ".docx", ".pdf", ".xlsx", ".pptx", ".png", ".jpg", ".gif", ".mp3", ".flac", '.pcm', '.alac', '.aiff', '.m4a', '.mp4', '.wav', '.wma', '.aac', '.mov', '.wmv', '.avi', '.avchd', '.flv', '.f4v', '.swf', '.mkv', '.webm']
//Image Types charmi(16-02-2023)

class _IISMethods {

    setCookiesData(key, value, path = '/') {
        const cookies = new Cookies()
        cookies.set(key, value, { path: path, maxAge: 12 * 60 * 60 })
    }

    getCookiesData(key) {
        const cookies = new Cookies()
        return cookies.get(key)
    }

    clearCookiesData(key, path = '/') {
        const cookies = new Cookies()
        cookies.remove(key, { path: path })
    }

    setLocalData(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    getLocalData(key) {
        return JSON.parse(localStorage.getItem(key))
    }

    clearLocalData(key) {
        localStorage.removeItem(key)
    }

    getpagename() {
        var urlpath = window.location.pathname
        var pagename = urlpath.split("/")
        var pagename = pagename[pagename.length - 1]
        return pagename
    }

    getPreviouspage() {
        return window.history
    }

    getPageRights(pagename = this.getpagename()) {
        return this.getobjectfromarray(this.getLocalData("userRights"), 'alias', pagename) || {}
    }

    async axiosrequest(method, url, reqBody, reqHeaders, successCallback, errorCallback) {

        if (reqBody?._id?.length !== 24) {
            delete reqBody._id
        }
        await axios({
            method: method,
            url: url,
            data: reqBody,
            headers: reqHeaders
        })
            .then(response => { return successCallback(response) })
            .catch(error => { errorCallback(error) })
    }

    getRandom(number) {
        return Math.floor((Math.random() * number) + 1)
    }


    ConvertTime(time, format = '') {
        var s = Math.floor((time / 1000) % 60)
        var m = Math.floor((time / (1000 * 60)) % 60)
        var h = Math.floor((time / (1000 * 60 * 60)) % 24)
        var flag = 0
        if (s < 0) {
            flag = 1
            s = -s
        }
        if (m < 0) {
            flag = 1
            m = -m
        }
        if (h < 0) {
            flag = 1
            h = -h
        }

        if (s <= 9)
            s = '0' + s
        if (m <= 9)
            m = '0' + m
        if (h <= 9)
            h = '0' + h

        const ConvertedTime = (format == 'hh-mm-ss' ? `${parseInt((time / (1000 * 60 * 60)) / 24) > 0 ? `${parseInt((time / (1000 * 60 * 60)) / 24)}day(s)` : ''} ${h % 24}hr ${m}min ` : flag ? `-${h}:${m}:${s}` : `+${h}:${m}:${s}`)
        return ConvertedTime
    }

    //srushti 22-2-23
    checkFiletype(fileName, allowedFiles) {
        var fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
        let fileExtension = fileName.match(fileExtensionPattern)[0]
        var regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$")
        return regex.test("iis" + fileExtension.toLowerCase())
    }

    //Heli 15/08/2023 04:00 start
    // encrypt
    encryptData(data) {
        const randomiv = this.generateRandomIv()
        const secretKey = CryptoJS.enc.Utf8.parse(Config.ENCRYPT_SECRETKEY)
        const iv = CryptoJS.enc.Utf8.parse(randomiv)
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, { iv: iv })
        return encrypted.toString() + '.' + randomiv
    }

    // decrypt
    decryptData(data) {
        let plaintext
        const secretKey = CryptoJS.enc.Utf8.parse(Config.ENCRYPT_SECRETKEY)
        let ciphertext = data.split('.')
        let message = ciphertext[0]
        let iv = CryptoJS.enc.Utf8.parse(ciphertext[1])
        const bytes = CryptoJS.AES.decrypt(message, secretKey, { iv: iv })
        plaintext = bytes.toString(CryptoJS.enc.Utf8)
        return plaintext
    }

    // get random 16-bit iv for encryption/decryption
    generateRandomIv(length = 16) {
        return this.generateRandomString(length)
    }

    /**
     * generate n-length random alpha numeric str
     * @param {Number} length 
     * @returns 
     */
    generateRandomString(length) {
        let result = ''
        const charactersLength = characters.length
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
    }

    //Heli 15/08/2023 04:00 end

    // heli_6/3/2023
    getSystemFullDate(date, t = null) {
        var propertydata = this.getLocalData('headerpropertyData')
        let newDateString
        if (date) {
            let dataString = date.toString()
            let offsettype = propertydata?.location.offsettype
            let offset = propertydata?.location.offset
            let offsetdata = propertydata?.location.offsetdata || []
            let offsetindex = offsetdata.findIndex(poffset => {
                let getdate = this.getDateFormat(date, 9, true)
                if ((new Date(poffset.fromdate) < new Date(getdate)) && (new Date(poffset.todate) > new Date(getdate))) return true
                else return false
            })
            if (offsetindex !== -1) { offset = offsetdata[offsetindex]['offsets'] }
            let setoffset = offset
            newDateString = setoffset ? dataString.split('GMT')[0] + offsettype + offset : dataString
        }
        else {
            if (!propertydata?.systemdate) {
                propertydata.systemdate = this.stringToISOFormat(new Date())
            }
            let dataString = new Date(this.getDateFormat(propertydata.systemdate, 9, true) + ' ' + this.getTimeFormat(new Date(), 3, false, true)).toString()
            let offsettype = propertydata?.location.offsettype
            let offset = propertydata?.location.offset
            let offsetdata = propertydata?.location.offsetdata || []
            let offsetindex = offsetdata.findIndex(poffset => {
                let getdate = this.getDateFormat(propertydata.systemdate, 9, true)
                if ((new Date(poffset.fromdate) < new Date(getdate)) && (new Date(poffset.todate) > new Date(getdate))) return true
                else return false
            })
            if (offsetindex !== -1) { offset = offsetdata[offsetindex]['offsets'] }
            let setoffset = offset
            newDateString = setoffset ? dataString.split('GMT')[0] + offsettype + offset : new Date()
        }
        return this.stringToISOFormat(newDateString)
    }
    getDateFormatByProfile() {
        return 'MM/dd/yyyy'

    }
    // getSystemFullDate(date) {
    //     var propertydata = this.getLocalData('headerpropertyData')
    //     let newDateString
    //     if (date) {
    //         let dataString = date.toString()
    //         let offsettype = propertydata.location.offsettype
    //         let offset = propertydata.location.offset
    //         let offsetdata = propertydata.location.offsetdata || []
    //         let offsetindex = offsetdata.findIndex(poffset => {
    //             let getdate = this.getDateFormat(date, 9, true)
    //             if ((new Date(poffset.fromdate) < new Date(getdate)) && (new Date(poffset.todate) > new Date(getdate))) return true
    //             else return false                
    //         })
    //         if (offsetindex !== -1) { offset = offsetdata[offsetindex]['offsets'] }
    //         newDateString = dataString.split('GMT')[0] + offsettype + offset
    //     }
    //     else {
    //         if (!propertydata.systemdate) { 
    //             propertydata.systemdate = this.stringToISOFormat(new Date()) 
    //         }
    //         let dataString = new Date(this.getDateFormat(propertydata.systemdate, 9, true) + ' ' + this.getTimeFormat(new Date(), 3, false, true)).toString()
    //         let offsettype = propertydata.location.offsettype
    //         let offset = propertydata.location.offset
    //         let offsetdata = propertydata.location.offsetdata || []
    //         let offsetindex = offsetdata.findIndex(poffset => {
    //             let getdate = this.getDateFormat(propertydata.systemdate, 9, true)
    //             if ((new Date(poffset.fromdate) < new Date(getdate)) && (new Date(poffset.todate) > new Date(getdate))) return true
    //             else return false         
    //         })
    //         if (offsetindex !== -1) { offset = offsetdata[offsetindex]['offsets'] }
    //         newDateString = dataString.split('GMT')[0] + offsettype + offset
    //     }
    //     return this.stringToISOFormat(newDateString)
    // }

    // hemang jani
    ConvertDateObjTOISOString(date) {
        let year = date.getFullYear()
        let month = date.getMonth()
        month = (month + 1).toString().length > 1 ? (month + 1) : `0${(month + 1)}`
        let day = date.getDate()
        day = (day).toString().length > 1 ? (day) : `0${(day)}`
        let hr = date.getHours()
        hr = (hr).toString().length > 1 ? (hr) : `0${(hr)}`
        let min = date.getMinutes()
        min = (min).toString().length > 1 ? (min) : `0${(min)}`
        let sec = date.getSeconds()
        sec = (sec).toString().length > 1 ? (sec) : `0${(sec)}`
        return `${year}-${month}-${day}T${hr}:${min}:${sec}.000Z`
    }

    // pass date object and set time to 00:00:00
    setStartTimeOfDay(date) {
        let year = new Date(date).getFullYear()
        let month = new Date(date).getMonth()
        let day = new Date(date).getDate()
        return new Date(year, month, day, 0, 0, 0, 0)

        // let year = date.getFullYear()
        // let month = date.getMonth()
        // month = (month + 1).toString().length > 1 ? (month + 1) : `0${(month + 1)}`
        // let day = date.getDate()
        // day = (day).toString().length > 1 ? (day) : `0${(day)}`
    }

    getPropertyLocation() {
        let propertydata = this.getLocalData('headerpropertyData')
        return propertydata?.location || {}
    }


    getPropertyOffset(date) {
        let propertydata = this.getPropertyLocation()
        let offset = propertydata['offset']
        let offsetdata = propertydata['offsetdata'] || []
        let offsetindex = offsetdata.findIndex(poffset => {
            let getdate = date
            if ((new Date(poffset.fromdate) <= new Date(getdate)) && (new Date(poffset.todate) >= new Date(getdate))) return true
            else return false
        })
        if (offsetindex !== -1) {
            offset = offsetdata[offsetindex]['offsets']
        }
        return offset || '+0000'
    }


    GetDateTimefromISOString(isostring) {
        let offset = this.getPropertyOffset(isostring) // only date
        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let date = new Date(isostring)
        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + date.getTimezoneOffset())
        let nd = new Date(utc - (date.getTimezoneOffset() * 60000) + (additionaldiff * 60000))
        return nd
    }

    GetDateTime(date = null, offset = null) {
        if (!date) { date = new Date() }
        offset = !offset ? this.getPropertyOffset(date) : offset// only date
        let sysdate = new Date()

        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + sysdate.getTimezoneOffset()) * 2
        let nd = new Date(utc + (3600000 * decimaloffset) - (additionaldiff * 60000))
        return nd
    }

    // for ISOString to split date
    GetISOdate(date = null) {
        let splitdate;
        if (date) {
            splitdate = date.split('T')[0]
        }
        else {
            // let propertydata = this.getLocalData('headerpropertyData')
            // splitdate = propertydata.systemdate.split('T')[0]
        }
        return splitdate.replaceAll('-', '/')
    }

    // Bhautik 16-03-2023 09:26 AM ~ For Get Date 
    GetDT(date, days = 0) {
        try {
            let dt = date ? new Date(date) : new Date()
            dt.setDate(dt.getDate() + days)
            return dt
        }
        catch {
            return 'Invalid Date'
        }
    }

    // pass date object and set time to 00:00:00
    getPropertyLocation() {
        let propertydata = this.getLocalData('headerpropertyData')
        // let propertydata = this.getobjectfromarray(this.getLocalData('headerpropertyData'), '_id', this.getCookiesData("mobilecheckin_loginInfo").propertyid)
        return propertydata?.location || {}
    }

    getPropertyOffset(date) {
        let propertydata = this.getPropertyLocation()
        let offset = propertydata['offset']
        let offsetindex = propertydata['offsetdata'].findIndex(poffset => {
            let getdate = date
            if ((new Date(poffset.fromdate) <= new Date(getdate)) && (new Date(poffset.todate) >= new Date(getdate))) return true
            else return false
        })
        if (offsetindex !== -1) {
            offset = propertydata['offsetdata'][offsetindex]['offsets']
        }
        return offset
    }

    GetDateTime(date = null, offset = null) {
        if (!date) {

            date = new Date()
        }

        offset = !offset ? this.getPropertyOffset(date) : offset// only date

        let sysdate = new Date()

        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + sysdate.getTimezoneOffset()) * 2
        let nd = new Date(utc + (3600000 * decimaloffset) - (additionaldiff * 60000))
        return nd
    }

    GetDateTimefromISOString(isostring) {
        let offset = this.getPropertyOffset(isostring) // only date
        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let date = new Date(isostring)
        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + date.getTimezoneOffset())
        let nd = new Date(utc - (date.getTimezoneOffset() * 60000) + (additionaldiff * 60000))
        return nd
    }

    // jatin 14 feb 2023
    getDateFormat(date, format = this.getCookiesData("mobilecheckin_loginInfo").dateformat || 1) {
        try {
            // let dateformat = this.getCookiesData("mobilecheckin_loginInfo").dateformat || 1
            let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            date = date.toString()
            // const dateobj = (timezone &&  propertylist.location.timezone) ? new Date(new Date(date).toLocaleString('en-US',
            //     {
            //         timeZone: propertylist.location.timezone,
            //         year: 'numeric',
            //         month: '2-digit',
            //         day: '2-digit',
            //         hour: '2-digit',
            //         minute: '2-digit',
            //         second: '2-digit',
            //     })) : new Date(date) 
            let dateobj = this.GetDateTimefromISOString(date)

            let year = dateobj.getFullYear()  // 2022
            let month = dateobj.getMonth() + 1  // 9
            let day = dateobj.getDate()      // 1
            let yy = year.toString().slice(-2) || ''; // 22
            let mm = month.toString().padStart(2, '0') || ''; // 09
            let dd = day.toString().padStart(2, '0') || '';   // 01
            let shortmonth = dateobj.toLocaleString('default', { month: 'short' }) || ''; // Sep
            let weekday = days[dateobj.getDay()]
            let dateyear = dateobj.getFullYear()

            if (year == 'NaN') {
                alert('NaN')
            }

            if (format === 1) {
                date = (year || '') + "-" + (mm || '') + "-" + (dd || '') //  2022-09-01
            }
            else if (format === 2) {
                date = month + "/" + day + "/" + year  //  9/1/2022
            }
            else if (format === 3) {
                date = month + "/" + day + "/" + yy
            }
            else if (format === 4) {
                date = dd + " " + shortmonth + ", " + year
            }
            else if (format === 5) {
                date = yy + "/" + mm + "/" + dd
            }
            else if (format === 6) {
                date = mm + "/" + dd + "/" + yy
            }
            else if (format === 7) {
                date = dd + "/" + mm + "/" + year
            }
            else if (format === 8) {
                if (dateyear === new Date().getFullYear()) {
                    date = weekday + ", " + shortmonth + " " + mm
                }
                else {
                    date = shortmonth + " " + mm + ', ' + year
                }
            }
            else if (format === 9) { // for Chrome & Firefox support
                date = (year || '') + "/" + (mm || '') + "/" + (dd || '') //  2022/09/01
            }
            else if (format === 10) { //heli_15_02_2023 5:23 p.m.
                date = mm + "-" + dd + "-" + year
            }
            else if (format === 11) { //heli_15_02_2023 5:23 p.m.
                date = shortmonth + " " + dd + ", " + year
            }
            else if (format === 12) {
                date = mm + "/" + dd + "/" + year
            }
        }
        catch {
            date = "-"
        }
        return date
    }

    // jatin 14 feb 2023
    stringToISOFormat(date, timezone = false) {
        try {
            // if (timezone) {
            //     let propertylist = this.getLocalData('headerpropertyData')
            //     let isodate = this.getDateFormat(date || propertylist.systemdate, 9, true) + ' ' + this.getTimeFormat((date || new Date()), 3, false, true)
            //     // let isodate = this.getDateFormat(date || propertylist.systemdate, 1, true) + ' ' + this.getTimeFormat(date || new Date(), 1, false , true)
            //     if (isodate.toString() === 'Invalid Date' || !date) {
            //         date = ''
            //     } else {
            //         date = new Date(isodate).toISOString()
            //     }
            // }
            // else {
            let isodate = new Date(date).toISOString()
            if (isodate.toString() === 'Invalid Date' || !date) {
                date = ''
            } else {
                date = new Date(date).toISOString()
            }
            // }
        } catch {
            return ''
        }
        return date
    }
    // jatin 16 feb 2023
    getTimeFormatByProfile() {
        let timeformattype = this.getCookiesData("mobilecheckin_loginInfo").timeformat
        switch (timeformattype) {
            case 1: return 'hh:mm aa'
            case 2: return 'HH:mm'
            default: return 'hh:mm aa'
        }
    }
    dateToSting(isodate) {
        try {
            let date = new Date(isodate)
            if (date.toString() === 'Invalid Date' || !isodate) {
                return undefined
            } else {
                return new Date(isodate)
            }
        } catch {
            return undefined
        }
        // return isodate
    }
    // Bhautik 14-03-2023 10:47AM ~ Get Time from Current timezone to property timezone
    GetTZTime(date = null, offset = null) {
        if (!date) { date = new Date('1970-01-01') }
        date.setMilliseconds('00')
        date.setSeconds('00')
        date.setDate('01')
        date.setMonth('00')
        date.setFullYear('1970')
        offset = !offset ? this.getPropertyOffset(date) : offset// only date
        let sysdate = new Date('1970-01-01')
        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + sysdate.getTimezoneOffset()) * 2
        let nd = new Date(utc + (3600000 * decimaloffset) - (additionaldiff * 60000))
        nd.setUTCDate('01')
        nd.setUTCFullYear('1970')
        return nd
    }
    GetISOFromTMobj(time) {
        try {
            return this.GetTZTime(time).toISOString()
        }
        catch {
            return ''
        }
    }
    GetPropertyDateWithCurrentTime() {
        let propertydata =this.getLocalData('headerpropertyData')
        let sysdate = propertydata?.systemdate
        let systime = this.GetDateTimefromISOString(this.GetDateTimefromISOString(this.GetDateTime().toISOString()).toISOString()).toLocaleTimeString()
        let date = (sysdate && systime) ? this.GetISOdate(sysdate) + ' ' + systime : ''
        let convertdate = date ? this.GetDateTime(this.GetDT(date)).toISOString() : ''
        return convertdate
    }
     // Bhautik 13-03-2023 10:59 AM
     GetTZdatetime(date = null) {
        try {
            let tzDate = this.GetDateTimefromISOString(this.GetDateTime(date).toISOString())
            return tzDate
        }
        catch {
            return ''
        }
    }
     // Bhautik 14-03-2023 10:47AM ~ Get Date Obj from ISO String
     GetTimefromISOString(isostring) {
        let offset = this.getPropertyOffset(isostring) // only date
        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let date = new Date(isostring)
        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + date.getTimezoneOffset())
        let nd = new Date(utc - (date.getTimezoneOffset() * 60000) + (additionaldiff * 60000))
        nd.setFullYear('1970')
        return nd
    }

     // Bhautik 14-03-2023 10:47AM ~ Get Date Obj from ISO String
     GetISOtoTZtime(date) {
        try {
            let tzDate = this.GetTimefromISOString(date)
            return tzDate
        }
        catch {
            return ''
        }
    }

    // // jatin 14 feb 2023
    // getSystemFullDate(date) {
    //     var propertydata = this.getLocalData('reservationDetails')['property']

    //     let newDateString
    //     if (date) {
    //         let dataString = date.toString()
    //         let offsettype = propertydata?.location.offsettype
    //         let offset = propertydata.location.offset
    //         let offsetdata = propertydata.location.offsetdata || []
    //         let offsetindex = offsetdata.findIndex(poffset => {
    //             let getdate = this.getDateFormat(date, 9, true)
    //             if ((new Date(poffset.fromdate) < new Date(getdate)) && (new Date(poffset.todate) > new Date(getdate))) return true
    //             else return false
    //         })
    //         if (offsetindex !== -1) { offset = offsetdata[offsetindex]['offset'] }
    //         newDateString = dataString.split('GMT')[0] + offsettype + offset
    //     }
    //     else {
    //         if (!propertydata.systemdate) {
    //             propertydata.systemdate = this.stringToISOFormat(new Date())
    //         }
    //         let dataString = new Date(this.getDateFormat(propertydata.systemdate, 9, true) + ' ' + this.getTimeFormat(new Date(), 2, false, true)).toString()
    //         let offsettype = propertydata?.location.offsettype
    //         let offset = propertydata.location.offset
    //         let offsetdata = propertydata.location.offsetdata || []
    //         let offsetindex = offsetdata.findIndex(poffset => {
    //             let getdate = this.getDateFormat(propertydata.systemdate, 9, true)
    //             if ((new Date(poffset.fromdate) < new Date(getdate)) && (new Date(poffset.todate) > new Date(getdate))) return true
    //             else return false
    //         })
    //         if (offsetindex !== -1) { offset = offsetdata[offsetindex]['offset'] }
    //         newDateString = dataString.split('GMT')[0] + offsettype + offset
    //     }
    //     return this.stringToISOFormat(newDateString)
    // }

    dateToFormat(date, format) {
        try {
            date = date.toString()
            const dateobject = new Date(date)
            if (format === "dd/mm/yyyy") {
                const dd = String(dateobject.getDate()).padStart(2, '0')
                const mm = String(dateobject.getMonth() + 1).padStart(2, '0')
                const yyyy = dateobject.getFullYear()
                if (isNaN(dd) || isNaN(mm) || isNaN(yyyy)) {
                    date = '-'
                }
                else {
                    date = dd + '/' + mm + '/' + yyyy
                }
            }


            else if (format === "yyyy/mm/dd") {
                const dd = String(dateobject.getDate()).padStart(2, '0')
                const mm = String(dateobject.getMonth() + 1).padStart(2, '0')
                const yyyy = dateobject.getFullYear()
                if (isNaN(dd) || isNaN(mm) || isNaN(yyyy)) {
                    date = '-'
                }
                else {
                    date = yyyy + '-' + mm + '-' + dd
                }
            }
            else {
                const dd = String(dateobject.getDate()).padStart(2, '0')
                const mm = dateobject.getMonth()
                const yyyy = dateobject.getFullYear()
                if (isNaN(dd) || isNaN(mm) || isNaN(yyyy)) {
                    date = '-'
                }
                else {
                    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                    date = dd + ' ' + months[mm] + ', ' + yyyy
                }
            }
        }
        catch {
            date = '-'
        }

        return date
    }

    getTimeFormat(time, format = this.getCookiesData("mobilecheckin_loginInfo").timeformat || 1, notime, timezone = false) {// notime===true ==> if time is empty string and want to display dash(-)
        try {
            let propertylist = this.getLocalData('headerpropertyData')
            // time = time.toString()

            if (time === "" || !time) {
                time = "-"
            }
            else {
                let timeobj = this.GetDateTimefromISOString(time)
                // const timeobj = (timezone && propertylist.location.timezone) ? new Date(new Date(time).toLocaleString('en-US',
                // {
                //     timeZone: propertylist.location.timezone,
                //     year: 'numeric',
                //     month: '2-digit',
                //     day: '2-digit',
                //     hour: '2-digit',
                //     minute: '2-digit',
                //     second: '2-digit',                    
                // })) : new Date(time)
                // let timeobj = timezone ? new Date(new Date(time).toLocaleString('en-US', { timeZone: propertylist.location.timezone })) : new Date(time)
                let hour = timeobj.getHours()
                let minute = timeobj.getMinutes().toString().padStart(2, '0')
                let second = timeobj.getSeconds().toString().padStart(2, '0')

                if (format === 1) {
                    let ampm = hour >= 12 ? 'PM' : 'AM'
                    hour = hour % 12
                    hour = hour ? hour : 12
                    time = hour.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0') + " " + ampm
                }
                else if (format === 2) {
                    time = hour + ":" + minute
                }
                else if (format === 3) {
                    let ampm = hour >= 12 ? 'PM' : 'AM'
                    hour = hour % 12
                    hour = hour ? hour : 12
                    time = hour.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0') + ":" + second.toString().padStart(2, '0') + " " + ampm
                }
            }
        }
        catch {
            if (notime) {
                time = "-"
            } else {
                time = "Invalid Time"
            }
        }
        return time
    }

    timeToFormat(time, format) {
        try {
            time = time.toString()
            const timeobject = new Date(time)
            var hh = timeobject.getHours()
            const am_pm = (hh > 12 ? 'PM' : 'AM')
            hh = (hh > 12 ? hh - 12 : hh)
            hh = String(hh).padStart(2, '0')
            const mm = String(timeobject.getMinutes()).padStart(2, '0')
            const ss = String(timeobject.getSeconds()).padStart(2, '0')
            if (isNaN(hh) || isNaN(mm) || isNaN(ss)) {
                time = '-'
            }
            else {
                time = hh + ":" + mm + " " + am_pm
            }
        }
        catch {
            time = '-'
        }

        return time
    }

    stringToInt(value) {
        value = parseInt(value)
        return isNaN(value) ? 0 : value
    }

    stringToDate(string) {
        var date
        try {
            date = new Date(string)
            if (date.toString() === 'Invalid Date')
                date = undefined
        }
        catch {
            date = undefined
        }
        return date
    }

    compareDates(startDate, endDate) {
        let startdate = new Date(startDate)
        let enddate = new Date(endDate)

        if (startdate.getFullYear() < enddate.getFullYear()) {
            return true
        }
        else if (startdate.getFullYear() == enddate.getFullYear()) {
            if (startdate.getMonth() < enddate.getMonth()) {
                return true
            }
            else if (startdate.getMonth() === enddate.getMonth()) {
                if (startdate.getDate() <= enddate.getDate())
                    return true
                else
                    return false
            }
            else {
                return false
            }
        }
    }

    compareTimes(startDate, endDate) {
        let startdate = new Date(startDate)
        let enddate = new Date(endDate)

        if (startdate.getHours() < enddate.getHours()) {
            return true
        }
        else if (startdate.getHours() == enddate.getHours()) {
            if (startdate.getMinutes() < enddate.getMinutes()) {
                return true
            }
            else {
                return false
            }
        }
    }



    ConvertDateTimeToDate(datetime) {
        return new Date(datetime).getFullYear() + '-' + (((new Date(datetime) + 1) < 10) ? '0' : '') + (new Date(datetime).getMonth() + 1) + '-' + ((new Date(datetime).getDate() < 10) ? '0' : '') + new Date(datetime).getDate()
    }

    getUniqueElementsFormArray(arr, n = 1) {
        arr = arr.sort(() => 0.5 - Math.random())
        // let m = arr.length
        // while (m) {
        //     const i = Math.floor(Math.random() * m--);
        //     [arr[m], arr[i]] = [arr[i], arr[m]]
        // }
        return arr.slice(0, n)
    }

    getobjectfromarray(arr, key, value) {
        try {
            return arr.find(o => o[key] == value)
        }
        catch (e) {
            return undefined
        }
    }

    getindexfromarray(arr, key, value) {
        return arr.findIndex(o => o[key] === value)
    }

    getindexfromarraydoublekey(arr, key1, key2, value1, value2) {
        return arr.findIndex(o => o[key1] === value1 && o[key2] === value2)
    }

    getmultipleobjectsfromarray(arr, key, value) {
        return arr.filter(o => o[key] === value)
    }

    getCopy(element) {
        try {
            return element.map(o => Object.assign({}, o))
        }
        catch {
            return Object.assign({}, element)
        }
    }

    removeDuplicateObjectsFromArray(arr) {
        return arr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.place === value.place && t.name === value.name
            ))
        )
    }

    notify(message, status) {
        if (!status && resBody.status === 200) {
            toast.success(message, {
                theme: "light",
                icon: "🟢"
            })
        }
        else if (status === 1) {
            toast.warn(message, {
                theme: "light",
                icon: "🟡"
            })
        }
        else {
            toast.error(message, {
                theme: "light",
                icon: "🔴"
            })

        }
    }
    // for local notification without req , res 
    localnotify(message, status) {
        if (status == 1) {
            toast.success(message, {
                theme: "light",
                icon: "🟢"
            })
        }
        else if (status == 2) {
            toast.warn(message, {
                theme: "light",
                icon: "🟡"
            })
        }
        else {
            toast.error(message, {
                theme: "light",
                icon: "🔴"
            })
        }
    }

    logout() {
        var subdomainname
        try {
            subdomainname = this.getCookiesData("mobilecheckin_loginInfo").subdomainname
        }
        catch {
            subdomainname = ''
        }
        var bookingid = this.getCookiesData("mobilecheckin_loginInfo").bookingid
        this.clearLocalData("userRights")
        this.clearLocalData("menuData")
        this.clearLocalData('propertyData')
        this.clearCookiesData("mobilecheckin_loginInfo")
        window.location.href = `/?bookingid=${subdomainname}-${bookingid}`//charmi(13-02-2023)
        // window.location.href = `/login?q=${subdomainname}&targeturl=${window.location.pathname.replace('/', '')}`
    }

    tokenExpiry() {
        if (resHeaders.key && resHeaders.unqkey) {
            var loginInfo = this.getCookiesData("mobilecheckin_loginInfo")

            loginInfo.token = resHeaders.key
            loginInfo.unqkey = resHeaders.unqkey

            this.setCookiesData("mobilecheckin_loginInfo", loginInfo)//charmi(13-02-2023)
            // this.setCookiesData("mobilecheckin_loginInfo", loginInfo)
        }
    }

    sessionExpiry() {
        if (resBody.status === 401 && resBody.message === Config.getErrmsg()['invalidtoken']) {
            this.logout()
        }
    }

    async fileToBase64(file) {
        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        }

        var base64File

        await getBase64(file)
            .then(data => base64File = data)
            .catch(error => base64File = '')

        return base64File
    }

    async listData(useraction, pagename = "", masterlisting, mainDb = {}) {
        const loginInfo = this.getCookiesData("mobilecheckin_loginInfo")
        const propertydata = this.getLocalData('headerpropertyData')
        const systemdate = propertydata?.systemdate
        const DateFromSystemDate = this.getDateFormat(systemdate, 9, true) + ' ' + this.getTimeFormat(new Date(), 3, false, true)
        const systemdateIOSFormate = IISMethods.stringToISOFormat(DateFromSystemDate)

        let reqheader = {
            token: loginInfo?.token,
            issuer: "website",// process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: "1",// process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: "/" + pagename,
            subdomainname: loginInfo.subdomainname,
            // companyid : this.getLocalData("companyid"),
            companyid: loginInfo.companyid,//charmi(13-02-2023)
            systemdate: systemdateIOSFormate, //jatin(22-02-2023)
            timezone: propertydata?.location?.timezone,
            username: 'guest'//charmi(16-03-2023)
        }
        // const loginInfo = this.getCookiesData("mobilecheckin_loginInfo")
        // reqHeaders = {
        //     token: loginInfo.token,
        //     issuer: process.env.REACT_APP_ISSUER,
        //     unqkey: loginInfo.unqkey,
        //     uid: loginInfo.uid,
        //     platform: process.env.REACT_APP_PLATFORM,
        //     useraction: useraction,
        //     // temperory condition 
        //     pagename: useraction === 'listapplicationdata' ? 'application' : this.getpagename(),
        //     subdomainname: loginInfo.subdomainname,
        //     username: loginInfo.firstname + ' ' + loginInfo.lastname,
        //     companyid: loginInfo.companyid,
        //     branchid: loginInfo.branchid,
        //     userroleid: loginInfo.userroleid,
        //     masterlisting: masterlisting ? true : false,
        //     ...mainDb
        // }
        // encrypt req header ~ Heli 15/08/2023 01:28 ~
        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1) {
            if (!reqBody.reqBody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }

        await this.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqBody, reqHeaders, listDataSuccessCallback.bind(this), listDataErrorCallback.bind(this))

        function listDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers.reqheader))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }


        function listDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
    }

    // Update Function
    async addData(useraction, pagename = "", notify = false) {
        const loginInfo = this.getCookiesData("mobilecheckin_loginInfo")
        const propertydata = this.getLocalData('headerpropertyData')
        const systemdate = propertydata?.systemdate
        const DateFromSystemDate = this.getDateFormat(systemdate, 9, true) + ' ' + this.getTimeFormat(new Date(), 3, false, true)
        const systemdateIOSFormate = IISMethods.stringToISOFormat(DateFromSystemDate)
        let reqheader = {
            token: loginInfo?.token,
            issuer: "website",// process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: "1",// process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: "/" + pagename,
            subdomainname: loginInfo.subdomainname,
            companyid: loginInfo.companyid,//charmi(13-02-2023)
            systemdate: systemdateIOSFormate, //jatin(23-02-2023)
            timezone: propertydata?.location?.timezone,
            username: 'guest'//charmi(16-03-2023)
        }

        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1) {
            if (!reqBody.reqBody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }

        await this.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqBody, reqHeaders, addDataSuccessCallback.bind(this), addDataErrorCallback.bind(this))

        function addDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.resheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers.resheader)))
                }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function addDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }


        this.sessionExpiry()
        this.tokenExpiry()
        if (notify)
            this.notify(resBody.message)
    }

    async updateData(useraction, showResponseMessage = true, decrypt) {
        const loginInfo = this.getCookiesData("mobilecheckin_loginInfo")

        const propertydata = this.getLocalData('headerpropertyData')
        const systemdate = propertydata?.systemdate
        const DateFromSystemDate = this.getDateFormat(systemdate, 9, true) + ' ' + this.getTimeFormat(new Date(), 3, false, true)
        const systemdateIOSFormate = IISMethods.stringToISOFormat(DateFromSystemDate)

        let reqheader = {
            token: loginInfo.token,
            issuer: process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            systemdate: systemdateIOSFormate, // jatin 23-02-2023
            timezone: propertydata?.location?.timezone,
            username: 'guest'//charmi(16-03-2023)
            // branchid: loginInfo.branchid,
            // userroleid: loginInfo.userroleid
        }
        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1 && !decrypt) {
            if (!reqBody.reqBody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }

        await this.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqBody, reqHeaders, upadateDataSuccessCallback.bind(this), upadateDataErrorCallback.bind(this))

        function upadateDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function upadateDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        // this.sessionExpiry()
        // this.tokenExpiry()
        if (showResponseMessage)
            this.notify(resBody.message)
    }

    async deleteData(useraction) {
        const loginInfo = this.getCookiesData("mobilecheckin_loginInfo")
        const propertydata = this.getLocalData('headerpropertyData')

        let reqheader = {
            token: loginInfo.token,
            issuer: process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            timezone: propertydata?.location?.timezone,
            username: 'guest'//charmi(16-03-2023)
        }
        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1) {
            if (!reqBody.reqBody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }
        await this.axiosrequest(process.env.REACT_APP_METHOD_DEL, url, reqBody, reqHeaders, deleteDataSuccessCallback.bind(this), deleteDataErrorCallback.bind(this))

        function deleteDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function deleteDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        this.notify(resBody.message)
    }

    async updateFieldOrder(tablename, fieldorder, useraction) {
        const url = Config.weburl + 'fieldorder/add'
        const loginInfo = this.getCookiesData("mobilecheckin_loginInfo")

        let reqheader = {
            token: loginInfo.token,
            issuer: process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            username: 'guest'//charmi(16-03-2023)

        }

        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        if (Config.encryptdata) {
            reqBody = {
                pagename: tablename,
                userid: loginInfo.uid,
                fields: fieldorder
            }
            if (!reqBody.reqbody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }
        else {
            reqBody = {
                pagename: tablename,
                userid: loginInfo.uid,
                fields: fieldorder
            }
        }


        await this.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqBody, reqHeaders, updateFieldOrderSuccessCallback.bind(this), updateFieldOrderErrorCallback.bind(this))

        function updateFieldOrderSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers.reqheader))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function updateFieldOrderErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        // this.notify(resBody.message)
    }

    resizeImageFile = async (imgFile, option = {}) => {
        let options = {
            maxSizeMB: 0.7,
            maxWidthOrHeight: option.maxHW || 800,
            useWebWorker: true,
            fileType: 'image/jpg',
            initialQuality: 1,
        }
        let compressedFile = await imageCompression(imgFile, options);
        return imageCompression.getDataUrlFromFile(compressedFile)
    }
    // resizeImageFile = (file) =>
    //     new Promise((resolve) => {
    //         Resizer.imageFileResizer(
    //             file,
    //             300,
    //             300,
    //             "JPEG",
    //             100,
    //             0,
    //             (uri) => {
    //                 resolve(uri);
    //             },
    //             "base64"
    //         );
    //     })
}



export var IISMethods = new _IISMethods()