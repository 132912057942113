import React from 'react'
import Passport from '../../assets/images/Passport.svg'
import LicenseCard from '../../assets/images/LicenseCard.svg'
import { connect, useSelector } from 'react-redux'
import { store } from '../..'
import Header from '../Header'
import { useNavigate } from 'react-router'
import { IISMethods, SVG } from '../../config/IISMethods'
import { setLoadings, setProps } from '../../redux/actions'
import Loader from 'view/Components/Loader'

const IDVerificationView = (props) => {

    const storeData = useSelector(state => state)
    const history = useNavigate()

    const urlparams = new URLSearchParams(window.location.search)
    const subdomainname = urlparams?.get('bookingid')?.split('-')[0]
    const bookingid = urlparams?.get('bookingid')?.split('-')[1]
    const search = `?bookingid=${subdomainname}-${bookingid}`

    const getCurrentState = () => store.getState()

    const rightSidebarFormData = storeData?.rightSidebarFormData ? storeData?.rightSidebarFormData : props.rightSidebarFormData

    // Bhautik 17-07-2023 11:03 AM ~ for upload image
    const handleUploadImage = async (event) => {
        
        await props.addLoaders('imagescan', 1)
        let file = event.target.files[0]
        let dataUrl = await IISMethods.resizeImageFile(file) //await imgToBase64(file)
        // await props.handleFormData('image', 'idphoto', dataUrl)
        // await props.handleVerificationNextPage("idverificationcamera", "documentverification", { 'idphoto': dataUrl });
        await props.handleVerificationNextPage("", "documentverification", { 'idphoto': dataUrl });
        await props.addLoaders('imagescan', 0)
    }

    const handleNextPage = async (pagename = '') => {
        if (!IISMethods.getLocalData('bookingid') || !IISMethods.getLocalData('subdomainname') || !subdomainname || !bookingid) {
            await history(pagename)
            return IISMethods.setLocalData("pagename", pagename)
        } else {
            const search = `?bookingid=${subdomainname}-${bookingid}`
            await props.handleVerificationNextPage("idverificationcamera", "documentverification");
            // await history({ pathname: `/${pagename}`, search }, { state: { goBack: 0 } })
            await IISMethods.setLocalData("pagename", pagename)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const renderHtmlControls = (formfield, btnname) => {
        try {
            if (formfield.type === "image") {
                return <div className='row'>
                    <div className='row'>
                        <input className='d-none' id={'idverification'} type='file' capture="environment" accept="image/*" onChange={(e) => e.target.files.length > 0 && handleUploadImage(e)} />
                        <label htmlFor={'idverification'} className={"col-6 w-100 btn submit-btn btn-icon mb-0"}>
                            <div className="mx-auto">
                                <span className={"d-inline-block mr-3"}>{btnname}</span>
                            </div>
                        </label>
                        <label htmlFor={'idverification'} className={"col-6 w-100 btn submit-btn btn-icon mb-0"}>
                            <div className="mx-auto">
                                <span className={"d-inline-block mr-3"}>{btnname}</span>
                            </div>
                        </label>
                        {
                            getCurrentState().loadings?.imagescan === 1
                                ?
                                <label className={"btn submit-btn btn-icon mb-0"}><div className="mx-auto">{SVG.loadingRing()} {btnname}</div></label>
                                :
                                // getCurrentState().reservationDetails?.idphoto
                                //     ?
                                //     <label className={"btn submit-btn btn-icon mb-0"} onClick={() => handleNextPage('idverificationcamera')}>
                                //         <div className="mx-auto">
                                //             <span className={"d-inline-block mr-3"}>{btnname}</span>
                                //         </div>
                                //     </label>
                                //     :
                                <></>
                            // <label htmlFor={'idverification'} className={"btn submit-btn btn-icon mb-0"}>
                            //     <div className="mx-auto">
                            //         <span className={"d-inline-block mr-3"}>{btnname}</span>
                            //     </div>
                            // </label>
                        }
                    </div>
                </div>
            }
        }
        catch { }
    }

    const handleOpenUpload = () => {
        if (document.getElementById('idverification')) document.getElementById('idverification').click()
    }

    return (
        <>
            <Header backTo="" title="ID Verification" backtoPreviousPage={props.backtoPreviousPage} current={"idverification"} />
            <section className="id-verification-sec section-spacing pb-4 inner-fixed-height" id="selfphoto" >
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="idverification-oneline">
                                    <p className="text-gray">We must verify your ID to continue with check-in process.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 set-padding-doc bg-blue'>
                        <div className='row m-0'>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-12 mx-auto text-center">
                                        <div className="document-sacn set-scan">
                                            {
                                                (!(getCurrentState().formData?.idphoto || getCurrentState().reservationDetails?.idphoto)) &&
                                                <h1 className="sec-title">Please scan one of  the document listed below</h1>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-11 mx-auto">
                                {
                                    (getCurrentState().formData?.idphoto || getCurrentState().reservationDetails?.idphoto)
                                        ?
                                        <div className="row set-data-id">
                                            <div className="text-center section-spacing-img">
                                                <img src={getCurrentState().formData?.idphoto || getCurrentState().reservationDetails?.idphoto} />
                                            </div>
                                        </div>
                                        :
                                        <div className="row set-data-id">
                                            <div className="col-5 col-xs-5 col-sm-4 col-md-4 mx-0 ml-lg-auto" onClick={handleOpenUpload}>
                                                <div className="select-document">
                                                    <div className="card cursor-pointer" id='ps-card'>
                                                        <div className="card-body">
                                                            <div className="idverification-imgs">
                                                                <img src={Passport} />
                                                            </div>
                                                            <span className="text-blue">Passport</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 col-xs-2 col-sm-1 col-md-2 col-lg-2 set-or-verif m-auto p-0 mx-auto my-auto mx-md-auto">
                                                <div className="form-group form-or text-center">
                                                    <p>OR</p>
                                                </div>
                                            </div>
                                            <div className="col-5 col-xs-5 col-sm-4 col-md-4 mx-0 mr-lg-auto" onClick={handleOpenUpload}>
                                                <div className="select-document">
                                                    <div className="card cursor-pointer" id='id-card'>
                                                        <div className="card-body">
                                                            <div className="idverification-imgs">
                                                                <img src={LicenseCard} />
                                                            </div>
                                                            <span className="text-blue">Driver's license</span>{/**Identity Card */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="issuedid-sec text-center">
                                            <p> <span className="text-blue fw-600">Government Issued ID (Non-Expired) </span> <br />(e.g. Driver's license, passport).</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="validunexpired-sec">
                                    <p>For best results place your document on well-lit surface with dark background. Ensure that all <span className="text-blue fw-600">4 corners are visible</span>. Your document must be <span className="text-blue fw-600">valid and unexpired</span>.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* // for scan document preview | BR ~ 2024-01-18 ~ 03:56 PM */}
                    <input className='d-none' id={'idverification'} type='file' capture="environment" accept="image/*" onChange={(e) => e.target.files.length > 0 && handleUploadImage(e)} />
                    <div className={`${(getCurrentState().formData.idphoto || getCurrentState().reservationDetails?.idphoto) ? 'col-6 px-1' : 'col-12'} mb-4`}>
                        <a className={`d-flex justify-content-center text-center btn-primary-light-paymentmethod  btn submit-btn ${getCurrentState().loadings?.imagescan === 1 && 'disabled'}`}
                            onClick={() => getCurrentState().loadings?.imagescan !== 1 && handleOpenUpload()}
                        >

                            {
                                getCurrentState().loadings?.imagescan === 1
                                    ? <span className='my-auto mr-1'>{SVG.loadingRing()}</span>
                                    : <i className="my-auto material-icons-round text-20">photo_camera</i>

                            }
                            <span className='my-auto ml-2 text-center'>{`${(getCurrentState().formData?.idphoto || getCurrentState().reservationDetails?.idphoto) ? 'Scan Again' : 'Scan My Documents'}`}</span>
                        </a>
                    </div>

                    {
                        (getCurrentState().formData?.idphoto || getCurrentState().reservationDetails?.idphoto) &&
                        <div className="col-6 px-1 mb-4">
                            <div className={`row m-auto d-flex justify-content-center`} style={{ position: 'relative' }}>
                                <div className={`text-center btn submit-btn paymentcard-submit-btn`}
                                    onClick={() => { getCurrentState().loading === 0 && props.handleIdverification() }}   >
                                    <Loader size="sm" content={'Submit'} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload)),
    setLoadings: (payload) => dispatch(setLoadings(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(IDVerificationView)


