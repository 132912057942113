import React from 'react'
// import Image from 'view/Components/Image'
// import cardImg from 'assets/images/face.jpg'

const IDCard = (props) => {
    return (
        <div className='icard-block'>
            {/* <div className='icard-header'>
                <p className='icard-main-title'>florida</p>
                <p className='icard-name'>Driver License</p>
            </div> */}
            <div className='icard-body'>
                <div className='icard-profile'>
                    {/* <img className='icard-profile-img' src={props.persondetails.image || cardImg} alt='img' /> */}
                    {/* <p className='icard-name'>Person name</p> */}
                </div>
                <div className='icard-details'>
                    {props.persondetails.data.map((pdata, pdataindex) => {
                        return pdata.value 
                            ? <div className={`d-flex gap-10 align-items-baseline mb-3 ${pdataindex === 0 ?'icard-no':''}`} key={pdataindex}>
                                <p className={`icard-title`}>{pdata.key}</p>
                                <p className={`icard-subtext`}>{pdata.value}</p>
                            </div>
                            : ''
                    })}
                    {/* <div className='d-flex gap-10 align-items-baseline mb-2'>
                        <p className='icard-title'>No</p>
                        <p className='icard-no'>T145-438-98-027-0</p>
                    </div>
                    <div className='d-flex gap-10 align-items-baseline mb-2'>
                        <p className='icard-title'>Name</p>
                        <p className='icard-subtext'>Person name</p>
                    </div>
                    <div className='d-flex gap-10 align-items-baseline mb-2'>
                        <p className='icard-title'>DOB</p>
                        <p className='icard-subtext'>08/08/1998</p>
                    </div>
                    <div className='d-flex gap-10 align-items-baseline mb-2'>
                        <p className='icard-title'>EXP</p>
                        <p className='icard-subtext'>08/08/2028</p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default IDCard;
