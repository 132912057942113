// Action Creators
export const clearData = () => {
    return {
      type: 'clearData'
    }
  }
  export const clearTimerData = () => {
    return {
      type: 'clearTimerData'
    }
  }
  
  export const setProps = (payload) => {
    return {
      type: 'setProps',
      payload
    }
  }
  
  export const updateData = (payload) => {
    return {
      type: 'updateData',
      payload
    }
  }
  
  export const clearApplicationData = () => {
    return {
      type: 'clearApplicationData'
    }
  }
  
  export const clearMasterDataList = () => {
    return {
      type: 'clearMasterDataList'
    }
  }
  
  export const clearsearchResultArray = () => {
    return {
      type: 'clearsearchResultArray'
    }
  }
  
  export const updatesearchResultArray = (payload) => {
    return {
      type: 'updatesearchResultArray',
      payload
    }
  }
  
  
  
  export const setApplicationData = (payload) => {
    return {
      type: 'setApplicationData',
      payload
    }
  }
  
  export const setListOfCandidates = (payload) => {
    return {
      type: 'setListOfCandidates',
      payload
    }
  }
  export const setLoading = (payload) => {
    return {
      type: 'setLoading',
      payload
    }
  }
  
  export const setLoadings = (payload) => {
    return {
      type: 'setLoadings',
      payload
    }
  }
  