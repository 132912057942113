import React, { useEffect, useState } from 'react'
import Nextarrow from '../../assets/images/Nextarrow.svg'
import Location from '../../assets/images/Location.svg'
import { IISMethods } from '../../config/IISMethods'
import Header from '../Header'
import { useSelector } from 'react-redux'

const StaydetailView = (props) => {

    // const confirmationid = IISMethods.getLocalData('confirmationid') ? IISMethods.getLocalData('confirmationid') : ""
    // const reservationDetails = IISMethods.getLocalData("reservationDetails") ? IISMethods.getLocalData("reservationDetails") : ""
    const storeData = useSelector(state => state)
    const [reservationDetails, setReservationDetails] = useState({})

    useEffect(() => {
        setReservationDetails(IISMethods.getLocalData("welcomedata"))// reservationDetails comment Heli 09/10/2023 10:38 all property data comes from welcome data
    }, [storeData])

    const { noofnight, roomtype, property, propertyname, firstname, lastname, pmsconfirmationnumber } = reservationDetails ? reservationDetails : "-"
    const name = (firstname && lastname) ? `${firstname} ${lastname}` : "-"
    const checkindate = reservationDetails ? reservationDetails.checkindate : ""
    const checkoutdate = reservationDetails ? reservationDetails.checkoutdate : ""
    const confirmationId = pmsconfirmationnumber ? pmsconfirmationnumber : ""

    const { logo, location } = property ? property : '-'
    const { fulladdress, address, state, zipcode, city } = location ? location : "-"
    // console.log('propertyname', propertyname)
    // var loginInfo = IISMethods.getCookiesData('mobilecheckin_loginInfo')



    return (
        <><Header backTo="checkin" title="Your Stay Details" backtoPreviousPage={props.backtoPreviousPage} />

            <section className="staydetails-sec section-spacing pb-4 inner-fixed-height">
                {/* <div className="container"> */}
                <div className="row">
                    <div className="col-12 mx-auto">
                        {/* <div className="days-suites-logo text-center">
                            <img src={Hotellogo} alt="Daniels Parkway Fort Myers" title="Daniels Parkway Fort Myers"/>
                        </div> */}
                        {
                            logo ?
                                <div className="dayssuites-logo">
                                    <img src={logo} title={propertyname ? propertyname : "-"} alt={propertyname ? propertyname : "-"} />
                                </div>
                                : <div className='mb-3'></div>
                        }
                    </div>
                    <div className="col-12 text-center">
                        {/* 10150 Daniels Parkway Fort Myers FL - 33913 */}
                        <div className="location info-data">
                            <div className='location-icon '>
                                {/* <div className='d-flex flex-column ml-2'> */}
                                <img src={Location} />

                                {/* <span className="text-gray"> {fulladdress ? fulladdress : "-"}</span> */}
                                <span className="text-gray mb-2"> {address ? address + ', ' : ""}</span><br />
                                <span className="text-gray mb-2"> {city ? city + ', ' : ""} {state ? state + ', ' : ""} {zipcode ? zipcode : "-"}</span>
                                {/* <span className="text-gray mb-1"> {zipcode ? zipcode : "-"}</span> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    {
                        confirmationId &&
                        <div className="col-12">
                            <div className="foundreservation">
                                <div className='title-foundsec'>
                                    <h1 className="sec-title text-left">We've Found Your Reservation</h1>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="foundreservation-title">
                                            {/* <h1 className="sec-title text-center">We've Found Your Reservation</h1> */}
                                            <div className="reservation-sec text-left">
                                                <ul className="reservation-list">
                                                    <li>
                                                        <span className="font-weight-400 text-16">Reservation Id : </span><span className="font-weight-600 text-16"> {confirmationId}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="col-12 text-left">
                        <div className="reservation-details-sec">
                            <div className="reservation-detail-title">
                                <p className="sec-title text-uppercase">Reservation Details</p>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    {/* <div className="reservation-detail-title">
                                            <p className="sec-title text-uppercase">Reservation Details</p>
                                        </div> */}
                                    <div className="reservation-details">
                                        <div className="col-12 p-0">

                                            <div className="row">
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className='text-16 '>Guest Name:</span>
                                                </div>
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1 ">
                                                    <span className="text-16 reservation-name text-capitalize ">{name}</span>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className='text-16 '>Check-in Date:</span>
                                                </div>
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className="text-16 reservation-name">{checkindate && IISMethods.getDateFormat(checkindate, 12, true)}</span>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className='text-16 '>Checkout Date:</span>
                                                </div>
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className="text-16 reservation-name">{checkoutdate && IISMethods.getDateFormat(checkoutdate, 12, true)}</span>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className='text-16 '>No. of Nights:</span>
                                                </div>
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className="text-16 reservation-name">{noofnight}</span>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className='text-16 '>Room Type:</span>
                                                </div>
                                                <div className="col-6 col-xs-6 col-sm-6 mb-1">
                                                    <span className="text-16 reservation-name">{roomtype}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="inn-suites-desc desc-data text-blue">
                            {/*  Days Inn & Suites Fort Myer */}
                            <p>{propertyname ? propertyname : "-"} has partnered with NexTap to provide you safe, quick and secure check-in.</p>
                        </div>
                    </div>
                    {/* <div className="col-12">
                            <div className="extra-space"></div>
                        </div> */}

                </div>
                {/* </div> */}
            </section>

            <div className="col-12">
                {/* <div className='sticky-btn text-center'>
                            <div className="submit-btn-sec"> */}
                {/* <Link to={`/idverification${queryString}`} className="btn submit-btn btn-icon"> */}
                <div className="btn submit-btn btn-icon fixed-btn mb-4" onClick={() => props.handleStaydetailClick()}>
                    <div className="mx-auto">
                        <span className="d-inline-block mr-3">Verify My Identity</span>
                        {/* <div className="left-arrow-icone d-inline-block">
                                                <img src={Nextarrow} />
                                            </div> */}
                    </div>
                    {/* </Link> */}
                    {/* </div>
                            </div> */}
                </div>
            </div>

        </>
    )
}

export default StaydetailView
