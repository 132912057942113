import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import welcomepageimg from '../../assets/images/welcomepageimg.png'
import { store } from '../../index'

function WelcomePageView(props) {

  const storedata = useSelector(state => state)
  const getCurrentState = () => store.getState()

  const welcomeData = useMemo(() => {
    return storedata?.reservationDetails
  }, [storedata?.reservationDetails])

  return (
    <>
      <section className="welcome-section ">
        <div className="row m-0">
          <div className="col-12 text-center">
            {
              welcomeData.logo &&
              <div className="welcome-img-logo">
                <img
                  src={welcomeData?.logo}
                  alt="Not Found"
                  className="marriott-_hotels-_logo-141"
                />
              </div>
            }
            <div className="welcome-title">
              <h1 className='text-capitalize' style={{ fontWeight: '700' }}>Hello, {welcomeData?.firstname}</h1>
            </div>

            <p className="welcome-text">
              <span className='welcome-bold-text'>   Let's Get You Checked-In </span>
              <br />
              <span className='text-19 '>    Mobile check-in gets you to your </span>
              <br />
              <span className='text-19 '>   room faster. </span>
            </p>
            <div className='welcome-img'>
              <img
                src={welcomepageimg}
                alt={welcomeData?.propertyname ? welcomeData?.propertyname : "Not Found"}
                title={welcomeData?.propertyname ? welcomeData?.propertyname : "Not Found"}
              />
            </div>
            {
              <button
                type="submit"
                className={`btn btn-primary-dark mb-2`}
                disabled={getCurrentState().reservationDetails?.ischeckin === 1 && getCurrentState().reservationDetails?.isupdate === 0}
                onClick={() => { props.handleNextPage("checkin", 'checkinnow') }}
              >
                Check-In Now
              </button>
            }
          </div>
        </div>
      </section>
    </>

  )
}

export default WelcomePageView