import React from 'react'
import { DatePicker } from 'rsuite'
import { IISMethods } from '../../config/IISMethods'

const DatePickerRsuite = (props) => { 
    return (
        <>
            <DatePicker
                contentEditable={false}
                suppressContentEditableWarning={false}
                className={props.className}
                placeholder={props.placeholder}
                format={props.format}
                onSelect={props.onChange}
                name={props.name}
                // value={IISMethods.stringToDate(props.value)}
                value={props.value ? props.value : null}
                ranges={props.ranges}
                cleanable={props.cleanable}
                id={props.id}
                placement="auto"
                type={props.type}
                disabled={props.disabled}         
                oneTap
                // onClean={props.onClean}
                onClean={() => props.onChange('')}
                // contentEditable={false}
                // calendarDefaultDate={(systemdate && systemdate !== 'Invalid Time') ? new Date(systemdate) : new Date()}
                // calendarDefaultDate={IISMethods.GetDateTimefromISOString(IISMethods.GetDateTime().toISOString())}
                showMeridian={props.showMeridian}
                disabledDate={props.disabledDate}
                {...props.options}
                
            />
        </>
    )
}
export default DatePickerRsuite