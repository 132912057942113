import { Component } from 'react'

export default class JsCall extends Component {
    // constructor(props) {
    //     super(props)
    // }

    //All Right Sidebar Code Start

    handleRightSidebar() {
        document.getElementById('rightSidebar').classList.toggle('active-right-sidebar')
        document.body.classList.toggle('overflow-hidden')
        document.querySelector("[class~='full-overlay']").classList.toggle('show')
    }
    
    //All Right Sidebar Code End

    //All Filter Right Sidebar Code Start

    handleFilterRightSidebar() {
        document.getElementById('filterrightSidebar').classList.toggle('active-right-sidebar')
        document.body.classList.toggle('overflow-hidden')
        document.querySelector("[class~='full-overlay']").classList.toggle('show')
    }

    //All Filter Right Sidebar Code End

    //All GridMenu Code Start

    handleGridMenu() {
        // if(flag) {
        //     document.getElementById('tblDropdownContent').classList.remove('active')
        //     document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.remove('show')
        // }
        // else {
        //     document.getElementById('tblDropdownContent').classList.add('active')
        //     document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.add('show')
        // }
        document.getElementById('tblDropdownContent').classList.toggle('active')
        document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.toggle('show')
    }

    //All GridMenu Code End

    handleSort(fieldObj) {
        const element = document.querySelector(`[class~=${fieldObj.tblsize}][name=${fieldObj.field}]`)
        element.classList.toggle('sorting_asc')
        element.classList.toggle('sorting_desc')
    }

    handleRightSidebarTab(e){
        var tabname
        if(typeof(e)==='string') {
            tabname = e
        }
        else {
            e.preventDefault()
            tabname = e.target.id
        }
        var element = document.querySelector("[class='nav-link active']")
        element.classList.remove('active')

        element = document.getElementById(tabname)
        element.classList.add('active')

        element = document.querySelector("[class='tab-pane fade active show']")
        element.classList.remove('active')
        element.classList.remove('show')

        element = document.getElementById(tabname+'-tab')
        element.classList.add('active')
        element.classList.add('show')
    }




    handleCloseDropdown(e) {
        var element = e.currentTarget

        element = element.parentNode
        element.setAttributeNode(document.createAttribute("hidden"))

        element = element.parentNode
        element.classList.remove('rs-dropdown-open')

        element = element.childNodes[0]
        element.removeAttribute('area-expanded')
    }

    handleHasVal(id, value) {
        var element = document.getElementById(id)
        try {
            while(!element.classList.contains('form-control')) {
                element = element.parentNode
            }
        }
        catch{
            if(id==='form-contact') {
                element = document.getElementById(id)
                element = element.childNodes[0]
                element = element.childNodes[0]
            }
        }

        try {
            if(value){
                element.classList.add('has-val')
            }
            else{
                element.classList.remove('has-val')
            }
        }
        catch {

        }
    }

    handleError(id, hasError) {
        var element = document.getElementById(id)
        try {
            while(!element.classList.contains('form-group')) {
                element = element.parentNode
            }
        }
        catch{
            try {
                if(id==='form-contact') {
                    element = document.getElementById(id)
                    element = element.childNodes[0]
                    element = element.childNodes[0]
                }
            }
            catch{
            }
        }
        try {
            if(hasError){
                element.classList.add('error')
            }
            else{
                element.classList.remove('error')
            }
        }
        catch{
        }
    }

    getobjectfromformdata(formData, field) {
       
        var object = {}
        formData.map((data) => !Object.keys(object).length ? object = data.formfields.find(o => o.field===field) || {} : object = object)
        return object
    }
    
    validateForm(formData, validations, key, formname)
    {
        var hasError
        if(typeof key==="string") {
            var value = formData[key]
            validations = this.getobjectfromformdata(validations, key)

            if(validations.type==='input-text' || validations.type==='number-input' || validations.type==='radio' || validations.type === 'intltelinput') {
                    try {
                        value = value.toString()
                       
                        hasError = !value.match(new RegExp(validations.regex))
                            || parseFloat(value) < validations.minvalue
                            || parseFloat(value) > validations.maxvalue
                            || value.length < validations.minlength
                            || value.length > validations.maxlength
                            || parseFloat(value) <= parseFloat(formData[validations.shouldgreaterthan])

                    }
                    catch {
                        hasError = true
                    }
            }
            else if(validations.type==='table' || validations.type==='multipleselectdropdown' || validations.type==='daterangepicker' || validations.type==='timerangepicker' || validations.type==='checkpicker') {
                try {
                    hasError = !value.length

                    hasError ||= (!value.length && validations.required)
                    hasError &&= (value.length || validations.required)
                }
                catch {
                    hasError = validations.required
                }
            }
            else if(validations.type === 'html-editor'){
                // remove new lines 
                value =  value.replaceAll('<p><br></p>','')
                // remove spaces
                value = value.replaceAll(/<p>\s*<\/p>/g, '')
                hasError = !value.length 
            }
            else {
                hasError = false
            }

            hasError ||= (!value && validations.required)
            hasError &&= (value || validations.required)

            
            if(document.getElementById((formname ? formname : 'form')+'-'+key)) {
                // if(validations.type!=='dropdown' && validations.type!=='multipleselectdropdown')
                //     this.handleHasVal((formname ? formname : 'form')+'-'+key,value)
                this.handleError((formname ? formname : 'form')+'-'+key, hasError)
            }
            else {
                hasError = false
            }

            if(hasError) {
            }

            return hasError
        }
        // if(key) {
        //     var value = formData[key]
        //     validations = this.getobjectfromformdata(validations, key)
        //     if(validations.type==='input-text' || validations.type==='number-input' || validations.type==='radio') {
        //         console.log('validations', validations)
        //             try {
        //                 value = value.toString()
        //                 hasError = !value.match(new RegExp(validations.regex))
        //                     || parseFloat(value) < validations.minvalue
        //                     || parseFloat(value) > validations.maxvalue
        //                     || value.length < validations.minlength
        //                     || value.length > validations.maxlength
        //                     || parseFloat(value) <= parseFloat(formData[validations.shouldgreaterthan])
        //             }
        //             catch {
        //                 hasError = true
        //             }
        //     }
        //     else if(validations.type==='table' || validations.type==='multipleselectdropdown' || validations.type==='daterangepicker' || validations.type==='timerangepicker' || validations.type==='checkpicker') {
        //         try {
        //             hasError = !value.length

        //             hasError ||= (!value.length && validations.required)
        //             hasError &&= (value.length || validations.required)
        //         }
        //         catch {
        //             hasError = validations.required
        //         }
        //     }
        //     else {
        //         hasError = false
        //     }

        //     hasError ||= (!value && validations.required)
        //     hasError &&= (value || validations.required)

        //     if(document.getElementById((formname ? formname : 'form')+'-'+key)) {
        //         // if(validations.type!=='dropdown' && validations.type!=='multipleselectdropdown')
        //         //     this.handleHasVal((formname ? formname : 'form')+'-'+key,value)
        //         this.handleError((formname ? formname : 'form')+'-'+key, hasError)
        //     }
        //     else {
        //         hasError = false
        //     }

        //     if(hasError) {
        //         // console.log(formname)
        //         // console.log(key, value)
        //     }

        //     return hasError
        // }
        else {
            var hasError = false

            var view

            validations.map((data) =>
                data.formfields.filter(formfield => !formfield.istablefield).map((fields) => {
                    const tempHasError = this.validateForm(formData, validations, fields.field, formname)
                    hasError ||= tempHasError
                    if(tempHasError && !view)
                        view = data.tabname
                })
            )

            if(view) {
                this.handleRightSidebarTab(view)
            }

            return hasError
        }
    }
}

// export default function JsCall() {
//     // constructor(props) {
//     //     super(props)
//     // }

//     //All Right Sidebar Code Start

//     const handleRightSidebar = () => {
//         document.getElementById('rightSidebar').classList.toggle('active-right-sidebar')
//         document.body.classList.toggle('overflow-hidden')
//         document.querySelector("[class~='full-overlay']").classList.toggle('show')
//     }
    
//     //All Right Sidebar Code End

//     //All Filter Right Sidebar Code Start

//     const handleFilterRightSidebar = () => {
//         document.getElementById('filterrightSidebar').classList.toggle('active-right-sidebar')
//         document.body.classList.toggle('overflow-hidden')
//         document.querySelector("[class~='full-overlay']").classList.toggle('show')
//     }

//     //All Filter Right Sidebar Code End

//     //All GridMenu Code Start

//     const handleGridMenu = () => {
//         // if(flag) {
//         //     document.getElementById('tblDropdownContent').classList.remove('active')
//         //     document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.remove('show')
//         // }
//         // else {
//         //     document.getElementById('tblDropdownContent').classList.add('active')
//         //     document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.add('show')
//         // }
//         document.getElementById('tblDropdownContent').classList.toggle('active')
//         document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.toggle('show')
//     }

//     //All GridMenu Code End

//     const handleSort = (fieldObj) => {
//         // console.log(fieldObj.field)
//         const element = document.querySelector(`[class~=${fieldObj.tblsize}][name=${fieldObj.field}]`)
//         element.classList.toggle('sorting_asc')
//         element.classList.toggle('sorting_desc')
//     }

//     const handleRightSidebarTab = (e) => {
//         var tabname
//         if(typeof(e)==='string') {
//             tabname = e
//         }
//         else {
//             e.preventDefault()
//             tabname = e.target.id
//         }

//         var element = document.querySelector("[class='nav-link active']")
//         element.classList.remove('active')

//         element = document.getElementById(tabname)
//         element.classList.add('active')

//         element = document.querySelector("[class='tab-pane fade active show']")
//         element.classList.remove('active')
//         element.classList.remove('show')

//         element = document.getElementById(tabname+'-tab')
//         element.classList.add('active')
//         element.classList.add('show')
//     }

//     const handleCloseDropdown = (e) => {
//         var element = e.currentTarget

//         element = element.parentNode
//         element.setAttributeNode(document.createAttribute("hidden"))

//         element = element.parentNode
//         element.classList.remove('rs-dropdown-open')

//         element = element.childNodes[0]
//         element.removeAttribute('area-expanded')
//     }

//     const handleHasVal = (id, value) => {
//         var element = document.getElementById(id)
//         try {
//             while(!element.classList.contains('form-control')) {
//                 element = element.parentNode
//             }
//         }
//         catch{
//             if(id==='form-contact') {
//                 element = document.getElementById(id)
//                 element = element.childNodes[0]
//                 element = element.childNodes[0]
//             }
//         }

//         try {
//             if(value){
//                 element.classList.add('has-val')
//             }
//             else{
//                 element.classList.remove('has-val')
//             }
//         }
//         catch {

//         }
//     }

//     const handleError = (id, hasError) => {
//         var element = document.getElementById(id)
//         try {
//             while(!element.classList.contains('form-group')) {
//                 element = element.parentNode
//             }
//         }
//         catch {
//             try {
//                 if(id==='form-contact') {
//                     element = document.getElementById(id)
//                     element = element.childNodes[0]
//                     element = element.childNodes[0]
//                 }
//             }
//             catch{
                
//             }
//         }
//         try {
//             if(hasError){
//                 element.classList.add('error')
//             }
//             else{
//                 element.classList.remove('error')
//             }
//         }
//         catch {
//         }
//     }

//     const getobjectfromformdata = (formData, field) => {
//         var object = {}
//         formData.map((data) => !Object.keys(object).length ? object = data.formfields.find(o => o.field===field) || {} : object = object)
//         return object
//     }

//      validateForm = (formData, validations, key, formname) => {
//         var hasError
//         if(key) {
//             var value = formData[key]
//             validations = this.getobjectfromformdata(validations, key)

//             if(validations.type==='input-text' || validations.type==='number-input' || validations.type==='radio') {
//                     try {
//                         value = value.toString()

//                         hasError = !value.match(new RegExp(validations.regex))
//                             || parseInt(value) < validations.minvalue
//                             || parseInt(value) > validations.maxvalue
//                             || value.length < validations.minlength
//                             || value.length > validations.maxlength
//                             || parseInt(value) <= parseInt(formData[validations.shouldgreaterthan])
//                     }
//                     catch {
//                         hasError = true
//                     }
//             }
//             else if(validations.type==='table' || validations.type==='multipleselectdropdown' || validations.type==='daterangepicker' || validations.type==='timerangepicker' || validations.type==='checkpicker') {
//                 try {
//                     hasError = !value.length

//                     hasError ||= (!value.length && validations.required)
//                     hasError &&= (value.length || validations.required)
//                 }
//                 catch {
//                     hasError = validations.required
//                 }
//             }
//             else {
//                 hasError = false
//             }

//             hasError ||= (!value && validations.required)
//             hasError &&= (value || validations.required)

//             if(document.getElementById((formname ? formname : 'form')+'-'+key)) {
//                 // if(validations.type!=='dropdown' && validations.type!=='multipleselectdropdown')
//                 //     this.handleHasVal((formname ? formname : 'form')+'-'+key,value)
//                 this.handleError((formname ? formname : 'form')+'-'+key, hasError)
//             }
//             else {
//                 hasError = false
//             }

//             if(hasError) {
//                 // console.log(formname)
//                 console.log(key, value)
//             }

//             return hasError
//         }
//         else {
//             var hasError = false

//             var view

//             validations.map((data) =>
//                 data.formfields.filter(formfield => !formfield.istablefield).map((fields) => {
//                     const tempHasError = this.validateForm(formData, validations, fields.field, formname)
//                     hasError ||= tempHasError
//                     if(tempHasError && !view)
//                         view = data.tabname
//                 })
//             )

//             if(view) {
//                 this.handleRightSidebarTab(view)
//             }

//             return hasError
//         }
//     }
// }