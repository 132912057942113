import React from 'react'
import { IISMethods } from 'config/IISMethods'
import { store } from 'index'
// import ModalRsuite from './Components/ModalRsuite'
import { setLoadings, setProps } from 'redux/actions'
import { connect } from 'react-redux'
import IDCard from './IDCard'
import CreditCardView from './CreditCardView'
// import Loader from './Components/Loader'
// import Image from './Components/Image'
// import CreditCardView from './Components/CreditCardView'
// import IDCard from './Components/IDCard'

const ReservationPDF = (props) => {

    const getCurrentState = () => store.getState()
    const loginInfo = getCurrentState().reservationDetails//IISMethods.getLoginInfo() 
    const propertydata = getCurrentState().propertyDetails || { _id: '' }

    let generatepdfobj = getCurrentState().formData?.extendstaydatedetails?.length > 0 ? getCurrentState().formData?.extendstaydatedetails[0] : {}
    let guestdetails = getCurrentState()?.formData || {}

    let style = {
        propertyobj: {
            margin: "0px",
            fontSize: '16px'
        },
        roomobj: {
            fontWeight: '600',
            marginRight: 10,
            marginBottom: 5
        }
    }

    const getmaskedcardnumber = (cardnum) => {
        if (cardnum) {
            let staticcard = cardnum?.replaceAll(' ', '')?.split('')?.fill('*', 0, 12)
            staticcard = staticcard?.map((n, i) => {
                if ([3, 7, 11].includes(i)) {
                    n += ' '
                }
                return n
            }).join('')
            return staticcard
        }
        // return cardnum
    }

    const cardStyle = {
        width: '550px',
        height: '320px',
        margin: '0 auto',
        fontSize: '30px'
    };

    return (
        <>
            <div className='d-none'>
                <div id={`pdfgenerate`} >
                    <div className={"pdf-block-reservation"}>
                        <div className="" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="" id={generatepdfobj?._id}>
                                <div className="" style={{ margin: '15px', }}>
                                    {/* Nikhil 3:28 09-11-23 */}
                                    {
                                        propertydata?.logo &&
                                        <img src={propertydata?.logo} style={{ height: 'auto', width: 200 }} alt='img' />
                                    }
                                </div>
                            </div>
                            <div className="">
                                <h6 style={style.propertyobj}>{propertydata?.propertyname}</h6>
                                <p style={style.propertyobj}>{propertydata?.location?.address}</p>
                                <p style={style.propertyobj}>{`${propertydata?.location?.city}, ${propertydata?.location?.state} - ${propertydata?.location?.zipcode}`}</p>
                                {/* <p style={style.propertyobj}>{propertydata?.location?.country}</p> */}
                                {/* <span style={style.propertyobj}>{propertydata?.location?.zipcode}</span> */}
                                <p style={style.propertyobj}>Phone: {propertydata?.contactno}</p>
                                <p style={style.propertyobj}>Email: {propertydata?.email}</p>
                            </div>
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <h4 style={{ width: "100%", display: "flex", fontSize: "24px", margin: "0px", fontWeight: '600', justifyContent: "center", marginBottom: "20px", marginTop: '20px' }}>Registration Form</h4>
                        </div>
                        <hr />
                        <div className="" style={{ display: "flex", justifyContent: "space-between", margin: '15px', fontSize: "18px", }}>
                            <div className="">
                                <div className="" style={{ display: "flex", }}>
                                    <div className="" style={style.roomobj}>Room Type :</div>
                                    <div className="">
                                        {`${generatepdfobj?.pmscode ? generatepdfobj?.pmscode + '-' : ''}${guestdetails?.roomtype}`}
                                    </div>
                                </div>
                                <div className="" style={{ display: "flex", }}>
                                    <div className="" style={style.roomobj}>Room No. :</div>
                                    <div className="">{guestdetails?.roomno || '-'}</div>
                                </div>
                            </div>
                            <div className="">
                                <div className="" style={{ display: "flex", }}>
                                    <div className="" style={style.roomobj}>Check-In Date : </div>
                                    <div className="">
                                        {`${IISMethods.getDateFormat(guestdetails?.checkindate, 12)}`}
                                    </div>
                                </div>
                                <div className="" style={{ display: "flex", }}>
                                    <div className="" style={style.roomobj}>Check-Out Date :</div>
                                    <div className="">
                                        {`${IISMethods.getDateFormat(guestdetails?.checkoutdate, 12)}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ margin: '15px', }}>
                            <table className="table-form" border='1px' style={{ width: '100%' }} >
                                <tr>
                                    <td style={{ width: '15%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}> First Name :</td>
                                    <td style={{ width: '27%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", textTransform: 'capitalize' }}>{guestdetails?.firstname}</td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px", verticalAlign: 'top' }}># Of Guest :</td>{/* rowSpan={2} */}
                                    <td style={{ width: '50%', padding: '10px 5px', verticalAlign: 'top' }}>{/* rowSpan={2} */}
                                        <div className="" style={{ display: "flex", justifyContent: "space-between", }}>
                                            <div className="" style={{ fontWeight: '400', fontSize: "16px" }}>{`Adults: ${generatepdfobj?.noofadults}`}</div>
                                            <div className="" style={{ fontWeight: '400', fontSize: "16px" }}>{`Children: ${generatepdfobj?.noofchildren} `}</div>
                                            <div className="" style={{ fontWeight: '400', fontSize: "16px" }}>{`Pets: ${generatepdfobj?.noofpets}`}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '15%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}> Last Name :</td>
                                    <td style={{ width: '27%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", textTransform: 'capitalize' }}>{guestdetails?.lastname}</td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}>Average Nightly Rate : </td>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>{`$ ${generatepdfobj?.averagenightlyrate}`}</td>
                                </tr>
                                <tr>
                                    <td rowSpan={4} style={{ width: '15%', padding: '10px 5px', fontWeight: '600', fontSize: "18px", verticalAlign: 'top', }}> Address :</td>
                                    <td style={{ width: '27%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", textOverflow: 'unset', whiteSpace: 'wrap', }}>{guestdetails?.address}</td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px", marginTop: "auto", marginBottom: "auto" }}>Total Room Rate : </td>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", marginTop: "auto", marginBottom: "auto", }}>{`$ ${generatepdfobj?.totalroomrate}`}</td>

                                </tr>
                                <tr>
                                    <td style={{ width: '27%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>
                                        {guestdetails?.city}
                                    </td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}>  Applicable Taxes :  </td>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>
                                        {`$ ${generatepdfobj?.applicabletaxes}`}
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>
                                        {guestdetails?.state}
                                    </td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}> Additional Charges. : </td>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>
                                        {`$ ${generatepdfobj?.additionalchargesrate}`}
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>
                                        {guestdetails?.country}{guestdetails?.country ? ' - ' : ''} {(guestdetails?.zipcode && guestdetails?.zipcode > 0) ? `${guestdetails?.zipcode}` : ''}
                                    </td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}> Total Payment :  </td>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>
                                        {`$ ${generatepdfobj?.estimatedgrandtotal}`}
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: '15%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}> Email :</td>
                                    <td style={{ width: '27%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>{guestdetails?.guestemail}</td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}>Security Deposit : </td>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>{`$ ${generatepdfobj?.carddeposit}`}</td>{/* securitydeposite Heli 06/07/2023 3:02 */}
                                </tr>
                                <tr>
                                    <td style={{ width: '15%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}> Phone :</td>
                                    <td style={{ width: '27%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>{guestdetails?.guestphoneno ? `+${guestdetails?.guestcountrycode} ${guestdetails?.guestphoneno}` : '-'}</td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}>Credit Card No. : </td>
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>{generatepdfobj?.paymentcardnumber ? getmaskedcardnumber(generatepdfobj?.paymentcardnumber) : '-'}</td>{/**`${generatepdfobj?.paymentcardnumber}` */}
                                </tr>

                                <tr>
                                    <td style={{ width: '15%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}> ID / Passport No. :</td> {/** // Heli 08/01/2024 12:53 PM => Vehicle Model(harsh d.) */}
                                    <td style={{ width: '27%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>{guestdetails?.licenseorpassportnumber || '-'}</td>
                                    <td style={{ width: '22%', padding: '10px 5px', fontWeight: '600', fontSize: "18px" }}>Expiry Date : </td> {/** // Heli 08/01/2024 12:54 PM => License Plate # (harsh d.) */}
                                    <td style={{ width: '35%', padding: '10px 10px', fontWeight: '400', fontSize: "16px", }}>{generatepdfobj?.cardexpirydate ? generatepdfobj?.cardexpirydate : '-'}</td>
                                </tr>
                            </table>
                        </div>
                        {
                            ((guestdetails?.chargespolicy?.length > 0 || guestdetails?.hotelpolicy?.length > 0) || (propertydata?.mobilecheckindescription && propertydata?.mobilecheckindescription !== '')) &&
                            <div className="col-12">
                                <div className='mt-3' style={{ fontSize: "20px", fontWeight: '500', marginRight: 10, marginBottom: 5 }}>Terms & Conditions</div>
                            </div>
                        }
                        <div className="col-12" >
                            <div style={{ fontSize: "20px", fontWeight: '500', padding: '10px', width: '100%', overflowWrap: 'break-word' }}>

                                {
                                    guestdetails?.chargespolicy?.map((policy, policyindex) => {
                                        return <label className="checkbox checkbox-outline-primary" key={policyindex}>
                                            <input type="checkbox" id={policy.policyid} checked={true} />
                                            <p className='text-18' dangerouslySetInnerHTML={{ __html: policy.policydescription }} />
                                            <span className="checkmark"></span>
                                        </label>
                                    })
                                }
                                {
                                    guestdetails?.hotelpolicy?.map((policy, policyindex) => {
                                        return <label className="checkbox checkbox-outline-primary" key={policyindex}>
                                            <input type="checkbox" id={policy.policyid} checked={true} />
                                            <p className='text-18' dangerouslySetInnerHTML={{ __html: policy.policydescription }} />
                                            <span className="checkmark"></span>
                                        </label>
                                    })
                                }
                            </div>
                        </div>

                        <div className={`${(generatepdfobj?.selfphoto !== '' || generatepdfobj?.idphoto !== '') ? "pdf-block-reservation" : ''} row`} style={{ display: "flex", justifyContent: "space-between", margin: '15px', }}>
                            <div className="col-7 ml-auto">
                                <div className="row mt-3">
                                    <div className="col-4" style={{ fontWeight: '500', fontSize: "18px", }}>Guest Signature :</div>
                                    <div className="col-8" style={{ width: '35%', padding: '0px 10px 0px 5px', height: '132px', borderBottom: '1px solid black' }} >
                                        {generatepdfobj?.signature && <img src={generatepdfobj?.signature} style={{ height: '80%', width: '80%' }} alt='img' /> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {props.persondetails && <div className="" style={{ margin: '55px', }}>
                                <div className="" style={{ border: '1px solid gray', borderRadius: '3px', padding: '10px 5px' }}>
                                    <h2 style={{ width: "100%", display: "flex", margin: "0px", justifyContent: "center", marginBottom: "15px", marginTop: '5px' }}>Document ID</h2>
                                    <div className='d-flex' style={{ margin: '0 auto' }}>
                                        <IDCard persondetails={props.persondetails} />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (loginInfo?.creditcardstatus === 1 && !generatepdfobj.paymentcardimageremoved && generatepdfobj.cardnumber) && // Heli 08/01/2024 12:52 PM => (harsh d.)
                            <div className="" style={{ margin: '55px', }}>
                                {
                                    <div className="" style={{ border: '1px solid gray', borderRadius: '3px', padding: '10px 5px' }}>
                                        <h2 style={{ width: "100%", display: "flex", margin: "0px", justifyContent: "center", marginBottom: "15px", marginTop: '5px' }}>Payment Card</h2>
                                        <div style={cardStyle} className='text-card'>
                                            <CreditCardView
                                                secure={true}
                                                number={generatepdfobj?.cardnumber.replaceAll(' ', '')}
                                                expdate={generatepdfobj?.cardexpirydate}
                                                holdername={generatepdfobj?.cardholdername}
                                                options={{
                                                    preview: true,
                                                    issuer: generatepdfobj?.cardtype
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div style={{ margin: '55px', }}>
                            {
                                generatepdfobj?.selfphoto &&
                                <div className="" style={{ border: '1px solid gray', borderRadius: '3px', padding: '10px 5px' }}>
                                    <h2 style={{ width: "100%", display: "flex", margin: "0px", justifyContent: "center", marginBottom: "15px", marginTop: '5px' }}>Self Photo</h2>
                                    <div className="" style={{ width: "550px", height: '350px', margin:'0 auto' }}>
                                        <img className="self-img" style={{ width: "100%", height: '100%'}} src={generatepdfobj?.selfphoto} alt='img' />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload)),
    setLoadings: (payload) => dispatch(setLoadings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationPDF)      