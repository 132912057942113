const initialState = {
    data: [],
    fieldOrder: [],
    fltPageCollection: [],
    newFieldOrder: [],
    pagelimit: 20,
    pageNo: 1,
    nextpage: 1,
    ApplicationNextPage: 1,
    applicationmodalPageNo: 1,
    pageName: '',
    sortData: {},
    formData: {},
    propertyData: {},
    masterFormData: {},
    filterMasterData: {},
    filterMasterDataList: {},
    filterData: {},
    oldFilterData: {},
    rightSidebarFormData: [],
    amenitiesFormData: [],
    masterRightSidebarFormData: [],
    fieldobjectformasterdata: {},
    rightSidebarShimmer: false,
    masterData: {},
    masterDataList: {},
    modal: {},

    timerdata: [],
    timerpageNo: 1,

    searchValue: '',
    searchResultArray: [],
    documentData: [],
    menuData: [],
    seriesOrder: [],
    applicationData: [],
    secondarySourceMasterData: '',
    candidateRightSidebarFormData: [],
    candidateFormData: {},
    currentOpening: '',
    currentCandidate: {},
    currentApplication: {},
    listOfCandidates: [],
    basicInfoFormData: {},
    followupFormData: {},
    netxStageFromData: {},
    orgChartData: {},
    editedJoiningStage: {},
    stages: [],
    currentStageIndex: 0,
    editedQuestionData: {},
    loginresp: {},
    // ApplicationBasicInfo : [],
    // FollowupModalData : [],
    momFormData: {},
    checkDomainData: {},
    loginDomainValidations: {},
    divHideShow: {},
    loginData: {},
    loginValidations: {},
    reservationDetails: {},
    loading: 0,
    loadings: {}

}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setProps':
            return {
                ...state,
                pageName: (action.payload.pageName !== undefined ? action.payload.pageName : state.pageName),
                pageNo: (action.payload.pageNo !== undefined ? action.payload.pageNo : state.pageNo),
                applicationmodalPageNo: (action.payload.applicationmodalPageNo !== undefined ? action.payload.applicationmodalPageNo : state.applicationmodalPageNo),
                pagelimit: (action.payload.pagelimit !== undefined ? action.payload.pagelimit : state.pagelimit),
                data: (action.payload.data !== undefined ? state.data.concat(action.payload.data) : state.data),
                nextpage: (action.payload.nextpage !== undefined ? action.payload.nextpage : state.nextpage),
                ApplicationNextPage: (action.payload.ApplicationNextPage !== undefined ? action.payload.ApplicationNextPage : state.ApplicationNextPage),
                sortData: (action.payload.sortData !== undefined ? action.payload.sortData : state.sortData),
                fieldOrder: (action.payload.fieldOrder !== undefined ? action.payload.fieldOrder : state.fieldOrder),
                fltPageCollection: (action.payload.fltPageCollection !== undefined ? action.payload.fltPageCollection : state.fltPageCollection),
                newFieldOrder: (action.payload.newFieldOrder !== undefined ? action.payload.newFieldOrder : state.newFieldOrder),
                formData: (action.payload.formData !== undefined ? action.payload.formData : state.formData),
                propertyData: (action.payload.propertyData !== undefined ? action.payload.propertyData : state.propertyData),
                loginresp: (action.payload.loginresp !== undefined ? action.payload.loginresp : state.loginresp),
                masterFormData: (action.payload.masterFormData !== undefined ? action.payload.masterFormData : state.masterFormData),
                filterMasterData: (action.payload.filterMasterData !== undefined ? action.payload.filterMasterData : state.filterMasterData),
                filterMasterDataList: (action.payload.filterMasterDataList !== undefined ? action.payload.filterMasterDataList : state.filterMasterDataList),
                filterData: (action.payload.filterData !== undefined ? action.payload.filterData : state.filterData),
                oldFilterData: (action.payload.oldFilterData !== undefined ? action.payload.oldFilterData : state.oldFilterData),
                rightSidebarFormData: (action.payload.rightSidebarFormData !== undefined ? action.payload.rightSidebarFormData : state.rightSidebarFormData),
                amenitiesFormData: (action.payload.amenitiesFormData !== undefined ? action.payload.amenitiesFormData : state.amenitiesFormData),
                masterRightSidebarFormData: (action.payload.masterRightSidebarFormData !== undefined ? action.payload.masterRightSidebarFormData : state.masterRightSidebarFormData),
                fieldobjectformasterdata: (action.payload.fieldobjectformasterdata !== undefined ? action.payload.fieldobjectformasterdata : state.fieldobjectformasterdata),
                rightSidebarShimmer: (action.payload.rightSidebarShimmer !== undefined ? action.payload.rightSidebarShimmer : state.rightSidebarShimmer),
                masterData: (action.payload.masterData !== undefined) ? action.payload.masterData : state.masterData,
                masterDataList: (action.payload.masterDataList !== undefined) ? action.payload.masterDataList : state.masterDataList,
                modal: (action.payload.modal !== undefined) ? action.payload.modal : state.modal,

                timerpageNo: (action.payload.timerpageNo !== undefined ? action.payload.timerpageNo : state.timerpageNo),
                timerdata: (action.payload.timerdata !== undefined ? action.payload.timerdata : state.timerdata),

                applicationData: (action.payload.applicationData !== undefined ? state.applicationData.concat(action.payload.applicationData) : state.applicationData),
                menuData: (action.payload.menuData !== undefined ? action.payload.menuData : state.menuData),
                searchValue: (action.payload.searchValue !== undefined) ? action.payload.searchValue : state.searchValue,
                searchResultArray: (action.payload.searchResultArray !== undefined) ? action.payload.searchResultArray : state.searchResultArray,
                documentData: (action.payload.documentData !== undefined) ? action.payload.documentData : state.documentData,
                seriesOrder: (action.payload.seriesOrder !== undefined ? action.payload.seriesOrder : state.seriesOrder),

                secondarySourceMasterData: (action.payload.secondarySourceMasterData !== undefined ? action.payload.secondarySourceMasterData : state.secondarySourceMasterData),
                candidateRightSidebarFormData: (action.payload.candidateRightSidebarFormData !== undefined ? action.payload.candidateRightSidebarFormData : state.candidateRightSidebarFormData),
                // ApplicationBasicInfo : (action.payload.ApplicationBasicInfo !== undefined ? action.payload.ApplicationBasicInfo : state.ApplicationBasicInfo),
                // FollowupModalData : (action.payload.FollowupModalData !== undefined ? action.payload.FollowupModalData : state.FollowupModalData),
                candidateFormData: (action.payload.candidateFormData !== undefined ? action.payload.candidateFormData : state.candidateFormData),
                currentOpening: (action.payload.currentOpening !== undefined ? action.payload.currentOpening : state.currentOpening),
                listOfCandidates: (action.payload.listOfCandidates !== undefined ? state.listOfCandidates.concat(action.payload.listOfCandidates) : state.listOfCandidates),
                currentCandidate: (action.payload.currentCandidate !== undefined ? action.payload.currentCandidate : state.currentCandidate),
                basicInfoFormData: (action.payload.basicInfoFormData !== undefined ? action.payload.basicInfoFormData : state.basicInfoFormData),
                followupFormData: (action.payload.followupFormData !== undefined ? action.payload.followupFormData : state.followupFormData),
                netxStageFromData: (action.payload.netxStageFromData !== undefined ? action.payload.netxStageFromData : state.netxStageFromData),
                currentApplication: (action.payload.currentApplication !== undefined ? action.payload.currentApplication : state.currentApplication),
                orgChartData: (action.payload.orgChartData !== undefined ? action.payload.orgChartData : state.orgChartData),
                stages: (action.payload.stages !== undefined ? action.payload.stages : state.stages),
                editedJoiningStage: (action.payload.editedJoiningStage !== undefined ? action.payload.editedJoiningStage : state.editedJoiningStage),
                editedQuestionData: (action.payload.editedQuestionData !== undefined ? action.payload.editedQuestionData : state.editedQuestionData),
                currentStageIndex: (action.payload.currentStageIndex !== undefined ? action.payload.currentStageIndex : state.currentStageIndex),
                momFormData: (action.payload.momFormData !== undefined ? action.payload.momFormData : state.momFormData),
                checkDomainData: (action.payload.checkDomainData !== undefined ? action.payload.checkDomainData : state.checkDomainData),
                loginDomainValidations: (action.payload.loginDomainValidations !== undefined ? action.payload.loginDomainValidations : state.loginDomainValidations),
                divHideShow: (action.payload.divHideShow !== undefined ? action.payload.divHideShow : state.divHideShow),
                loginData: (action.payload.loginData !== undefined ? action.payload.loginData : state.loginData),
                loginValidations: (action.payload.loginValidations !== undefined ? action.payload.loginValidations : state.loginValidations),
                reservationDetails: (action.payload.reservationDetails !== undefined ? action.payload.reservationDetails : state.reservationDetails),
                loading: (action.payload.loading !== undefined ? action.payload.loading : state.loading)

            }
        case 'clearTimerData':
            return {
                ...state,
                timerdata: []
            }
        case 'clearData':
            return {
                ...state,
                data: []
            }
        case 'clearApplicationData':
            return {
                ...state,
                applicationData: [],
                listOfCandidates: []
            }

        case 'setApplicationData':
            return {
                ...state,
                applicationData: action.payload.applicationData
            }

        case 'setListOfCandidates':
            return {
                ...state,
                listOfCandidates: action.payload.listOfCandidates
            }
        case 'clearmasterData':
            return {
                ...state,
                masterData: []
            }
        case 'clearsearchResultArray':
            return {
                ...state,
                searchResultArray: []
            }
        case 'updateData':
            return {
                ...state,
                data: (action.payload.data !== undefined) ? action.payload.data : state.data
                // data : (action.payload.data !== undefined ? state.data.concat(action.payload.data) : state.data)
            }
        case 'updatesearchResultArray':
            return {
                ...state,
                searchResultArray: (action.payload.searchResultArray !== undefined) ? action.payload.searchResultArray : state.searchResultArray
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload.loading
            }
            
        case 'setLoadings':
            return {
                ...state,
                loadings: action.payload.loadings
            }


        default:
            return state
    }
}

export default appReducer